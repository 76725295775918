import React, { useRef, useState, useEffect } from "react";
import { Box, Button, CheckBox, Grommet, Select, Text } from "grommet";
import { FormClose } from "grommet-icons";
import pluralize from "pluralize";
import { store } from "../psrs";
import _ from "lodash";

export const SearchSelect = (props) => {
  const {
    isMulti,
    isClearable,
    model,
    field,
    value,
    storeKey,
    pageRef,
    placeHolderText,
    setOverlayed,
  } = props;
  const values = !!value && Array.isArray(value) ? value : [value];
  const resourceName = props.resourceName || "Pay Types";
  const resourcesName = pluralize(resourceName);
  const resources = store.get(storeKey || "PayTypes");
  const allRecords = resources.map((r, index) => {
    const name = r.description || r.name;
    let record = { index, name, value: r[r.pk], search: [name] };
    if (model === "employee") {
      const { fullName, firstName, lastName, jobTitle, role } = r;
      record.name = `${lastName} ${firstName}`;
      record.search = [...record.search, employee.name, jobTitle, role];
      record = { ...record, fullName, firstName, lastName, jobTitle, role };
    }
    if (r.code) {
      record.search.push(r.code);
      record.code = r.code;
    }
    record.search = record.search.join(" ");
    return record;
  });
  const _selected = value ? allRecords.filter((r) => r.value === value[0]) : null;
  const [mounted, setMounted] = useState(false);
  const [selected, setSelected] = useState(_selected);
  const [records, setRecords] = useState(allRecords);
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSerchQuery] = useState("");
  const selectRef = useRef();

  const update = async (val) => {
    // console.log("SearchSelect", val);
    props.handleUpdate({ field, value: val });
  };

  const clearSelected = () => {
    setSelected([]);
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      const filteredRecords = allRecords.filter(
        (s) => s.search.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
      );
      setTimeout(() => {
        setSearching(false);
        setRecords(filteredRecords);
      }, 500);
    }
  }, [searching, searchQuery]);

  useEffect(() => {
    if (mounted) {
      console.log("selected", selected, field, model);
      props.handleUpdate({ field, value: selected.map(s => s.value) })
    }
  }, [selected]);

  // console.log('SearchSelect selected', selected)

  const Option = (args) => {
    // { selected: false, value: { code: "17", name: "Jury Duty", search: "Jury Duty 17", value: "17" } }
    const { value, index } = args;
    const isSelected = selected.find((r) => r.index === index);
    const boxProps = {
      background: isSelected ? "blue" : null,
      key: value.value,
      direction: "row",
      align: "center",
      pad: "small",
      flex: false,
    };
    return (
      <Box {...boxProps}>
        {!isMulti && <Text>{value.name}</Text>}
        {isMulti && (
          <CheckBox
            tabIndex="-1"
            checked={!!isSelected}
            label={<Text size="small">{value.name}</Text>}
            onChange={() => {}}
          />
        )}
      </Box>
    );
  };

  const placeholder =
    placeHolderText ||
    `Select ${isMulti ? resourcesName : "a " + resourceName}`;

  const renderRecords = () => (
    <Box direction="row" gap="xsmall" align="center" flex>
      <Box flex pad="medium">
        <Text size="medium" truncate>
          {selected[0].name}
          {/* {selected.map(({ name }) => name).join(", ")} */}
        </Text>
      </Box>
      {!!isClearable && (
        <Button
          href="#"
          onFocus={(event) => event.stopPropagation()}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            clearSelected();
            selectRef.current.focus();
          }}
        >
          <Box background="gray" round="full">
            <FormClose style={{ width: "12px", height: "12px" }} />
          </Box>
        </Button>
      )}
    </Box>
  );
  if (!pageRef.current) {
    return <Box></Box>;
  } else {
    return (
      <Box fill align="center" justify="center" width="large">
        <Select
          disabled={props.disabled}
          id="SearchSelect"
          focusIndicator={false}
          replace={false}
          placeholder={placeholder}
          alignSelf="stretch"
          dropTarget={pageRef.current || null}
          ref={selectRef}
          closeOnChange={isMulti ? false : true}
          searchPlaceholder={`Search ${resourcesName}`}
          emptySearchMessage={`No ${resourcesName} Found`}
          multiple={isMulti || false}
          value={selected && selected.length ? renderRecords() : undefined}
          // selected={selected.map((option) => records.indexOf(option))}
          overflow={{ horizontal: "hidden", vertical: "auto" }}
          responsive={false}
          options={records}
          onOpen={() => {
            setOverlayed(true);
          }}
          onClose={() => {
            setOverlayed(false);
            setSearching(false);
            setSerchQuery("");
          }}
          onChange={(change) => {
            setSelected([change.value]);
          }}
          // onChange={(args) => {
          //   console.log("onChange", args);
          //   setSelected(args.selected);
          // }}
          onSearch={(query) => {
            setSearching(true);
            setSerchQuery(query);
          }}
          dropProps={{
            className: "SelectOpen",
            elevation: "none",
          }}
          stretch={false}
        >
          {(option, index) => {
            return <Option value={option} index={index} />;
          }}
        </Select>
      </Box>
    );
  }
};
