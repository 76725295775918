import React, {useState} from 'react'
//import {Select, CaretIcon, ModalCloseButton} from 'react-responsive-select'
import Picker from 'react-mobile-picker'
import {Select } from 'grommet'
import _ from "lodash"
import {store, truncString} from '../psrs'

// for default styles...
import 'react-responsive-select/dist/react-responsive-select.css'

export const SelectPayTypesAlt = (props) => {

  const {timeEntry, update, selectType} = props
  const {payTypeCode, id} = timeEntry

  const category = selectType //.toUpperCase()
  const paytypeLabel = category ? category[0].toUpperCase() + category.toLowerCase().slice(1) : "Select"

  let Records = store.get('PayTypes').filter((r) => !!r.included || r.payTypeCode !== '380')
  if ( category && category !== 'REGULAR') {
    Records = store.get('PayTypes').filter((r) => !!r.included && r.category === category ) //|| r.code === '380')
  }

  //const options = _.sortBy(Records.map((r) => ({sort: parseInt(r.code), value: r.code, text: `${r.code} - ${truncString(r.rename,20)}`, markup: <small>{r.code} - {r.rename}</small>, disabled: r.code === '380' ? true : false})), ['sort'])
  const options =   _.sortBy(Records.map((r) => ({sort: parseInt(r.code), value: r.code, text: `${r.code} - ${r.rename ? truncString(r.rename, 20) : truncString(r.description, 20)}`, code: r.code, disabled: r.code === '380' ? true : false})), ['sort'])


  const disabled = props.disabled
  //options.unshift({value: 'cancel', text: 'CANCEL'})
  //options.unshift({value: 'clear', text: 'CLEAR'})

  const [selected, setSelected] = useState(timeEntry.payTypeCode || undefined)

  const currentSelection = props && props.timeEntry ? props.timeEntry.payTypeCode : selected

  const handleChange = ({value}) => {


    if (value === 'cancel') {
      setSelected(timeEntry.payTypeCode || undefined)
    } else if (value === 'clear') {
      setSelected(undefined)
      update({field: 'payTypeCode', id: timeEntry.id, value: null})

    } else if (value !== 'cancel' && value !== 'clear' ) {
      setSelected(value)
      update({field: 'payTypeCode', id: timeEntry.id, value})
    }
  }

  const handleSubmit = (value) => {
    console.log('SELECT onSubmit', value)
  }


  // Escaping regular expression special characters: [ \ ^ $ . | ? * + ( )
  const getEscapedText = text => text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

  // Create the regular expression with escaped special characters.
  const formatSearchExpression = text => new RegExp(getEscapedText(text), 'i');

  const onSearch = text => {
    const exp = formatSearchExpression(text);
    setXOptions(options.filter(option => exp.test(option.text)));
  };

  const x = options.find(o => o.value === timeEntry.payTypeCode)
  const [value, setValue] = React.useState( [{value:x?.value, text: x?.text}] );
  const [xoptions, setXOptions] = useState(options);
  
  //return <Select disabled={disabled} name='reasonId' prefix='Acting: ' noSelectionLabel='Please select' modalCloseButton={<ModalCloseButton />} options={options} caretIcon={<CaretIcon />} selectedValue={currentSelection} onChange={handleChange} onSubmit={handleSubmit} />
  return (
    <Select
      style={{background: disabled ? '#F5F5F5' : 'white', color: disabled ? '#A7A7A7' :'black'}}
      alignSelf="stretch"
      labelKey="text"
      valueKey={"value"}
      name="assignment"
      options={xoptions}
      disabled = {disabled}
      disabledKey="disabled"
      placeholder="Select Pay Type"
      emptySearchMessage="No matches found"
      searchPlaceholder="Search"
      value={value}
      dropHeight="medium"
      clear={
        {
          position: "top",
          text: "Clear Pay Type",
          label: "Clear Pay Type"
        }
      }
      onChange={({ option }) => {
        setValue(option)
        handleChange(option)
      }}
      onSearch={text => onSearch(text)}
    
    />
  );

}
