import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from '@sentry/browser'
import {
  Page,
  Col,
  Row,
  Card, 
  List,
  ListItem,
  ListHeader,
  Button
} from "react-onsenui";
import {Logout, uid, store} from "./psrs"
import RobotError from "../src/img/robot.svg"
import { unstable_shouldYield } from "scheduler";

const DEBUG = false
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
   
    const { navigator } = props.children.props

    this.state = {
      error: "",
      eventId: "",
      errorInfo: "",
      hasError: false,
    }

    this.divContainer = { 
      height: "100%",
      width: "100%",
      position: "relative",
      //padding: '50px',

      //"border": "3px solid green" 
    }

    this.divProps = {
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      padding: '50px',
      "-ms-transform": "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)"
    }

    this.divCenter = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      //height: "200px",
      //"border": "3px solid green" 
    }

    this.img = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: 200,
      height: 200,
      //width="200" height="200"
    }

  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    DEBUG &&  console.log({ error, errorInfo });
    this.setState({ errorInfo })

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    })

  }
  render() {

    if (this.state.error) {
      console.log('ErrorBoundry -> caught error -> ', this.state.error)
      
      return (
        <>
      
          
            <Card key={uid(8)}>
              <Row key={uid(8)}>
                <Col key={uid(8)} verticalAlign="center">
                    <img style={this.img} src={RobotError} alt="Error" />
                </Col>
              </Row>
            </Card>
            <Card key={uid(8)}>
              <List 
                  key={uid(8)}
                  renderHeader={() =>
                    <ListHeader 
                      style={{fontSize: 15, textAlign: "center"}} 
                    > 
                      Oops!! Looks like you have found an issue before we did
                    </ListHeader>
                  }
                >
                <ListItem key={uid(8)} style={{textAlign: "center"}}>
                  <strong>Please Logout and Log Back in</strong>
                </ListItem>         
              </List>
              
            </Card>
            <Card key={uid(8)}>
              <List key={uid(8)}>
                <ListItem
                  key={uid(8)}
                  style={{alignContent:"center"}}
                >
                  <div style={this.divContainer}>
                    <div style={this.divCenter}>
                      <Button 
                      modifier="quiet"
                      onClick={() => {
                        store.set("RouteStack", [{ view: "Login" }]);
                        //Logout()
                        window.location.reload(false)
                      }}
                    >
                      Logout
                    </Button> 

                    </div>
                    <div style={this.divCenter}>

                        <Button
                        className="bg-primary text-light"
                        modifier="quiet"
                        onClick={() =>
                          Sentry.showReportDialog({ eventId: this.state.eventId })
                        }
                      >
                        Report feedback
                      </Button>
                    </div>
                    
                  </div>
                   
                 </ListItem>
               
                <ListItem key={uid(8)} style={{textAlign: "center"}}>
                  <small>The Enterprise Technlogy Team has been notified and looking into the issue</small>
                </ListItem>
              </List>
          </Card>
        </>
      )
      
     

    }
    // next code block goes here
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
