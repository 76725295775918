import React from "react";
import { Select, CaretIcon, ModalCloseButton } from "react-responsive-select";
import { store } from "../psrs";

// for default styles...
import "react-responsive-select/dist/react-responsive-select.css";
import { updatedDiff } from "deep-object-diff";

export const SelectEmployee = (props) => {
  const Records = store.get("Employees");
  const options = Records.map((r) => ({ value: r.number, text: r.fullName }));
  const handleChange = ({ name, text, value, altered }) => {
    if (!!altered) {
      console.log("SELECT onChange", value);
      props.update({field: name, value: value})
    }
  };
  const handleSubmit = (value) => {
    console.log("SELECT onSubmit", value);
  };

  return (
    <Select
      name="employeeNumber"
      prefix={<strong>Employee: </strong>}
      noSelectionLabel="Please select"
      modalCloseButton={<ModalCloseButton />}
      options={options}
      caretIcon={<CaretIcon />}
      selectedValue={props.value || undefined}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

