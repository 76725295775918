import React from "react"
import { Select } from "react-onsenui"
import { store } from "../psrs"

export const LeaveBalanceSelect = (props) => {
  const Employee = store.get('currentUser')
  const {leave, number} = Employee

  // const selectProps = {
  //   id: "cyLeaveBalanceSelect",
  //   className: "psrs-select",
  //   classNamePrefix: "psrs",
  //   isMulti: false,
  //   options: options,
  //   defaultValue: _.find(options, { value: props.value }),
  //   inputValue: state.inputValue,
  //   onInputChange: (inputValue) => setState({ ...state, inputValue }),
  //   menuIsOpen: state.menuIsOpen,
  //   onMenuOpen: () => setState({ ...state, menuIsOpen: true }),
  //   onMenuClose: () => setState({ ...state, menuIsOpen: false }),
  //   onChange: (select, action) => {
  //     // setState({ ...state, value: select })
  //     props.handleChange({
  //       ...props,
  //       field: "payTypeCode",
  //       value: select.value,
  //     });
  //   },
  // };

  return (
    <Select
      onChange={(event) => {
        console.log("CHANGE", e.target.value);
      }}
    >
      <option value="470">{leave.compTime} compTime </option>
      <option value="440">{leave.executiveAccruals} executiveAccruals </option>
      <option value="51">
        {leave.floatingHolidayAccruals} floatingHolidayAccruals{" "}
      </option>
      <option value="484">{leave.fireLeaveBank} fireLeaveBank </option>
      <option value="20">{leave.sickAccruals} sickAccruals </option>
      <option value="31">{leave.vacationAccruals} vacationAccruals </option>
      <option value="450">{leave.holidayAccruals} holidayAccruals </option>
      <option value="21">
        {leave.famMedLeaveAccruals} famMedLeaveAccruals{" "}
      </option>
      <option value="89">{leave.otherLeave} otherLeave </option>
    </Select>
  );
};

export default LeaveBalanceSelect;
