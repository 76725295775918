import React from 'react'
import ReactDOM from 'react-dom'
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import * as Sentry from '@sentry/browser'


import {App} from './App'
// import 'unfetch/polyfill'

JavascriptTimeAgo.locale(en)

const ENV = process.env.NODE_ENV || 'Production'

Sentry.init({ 
  dsn: "https://01d9ee26a0c147beb81e57f009876b5a@o230320.ingest.sentry.io/1388791",
  attachStacktrace: true,
  debug: false,
  environment: ENV,
  beforeSend(event) {
    // console.log('Sentry.init sending event....')
    // if (ENV === 'development') console.log(event)
    return event
  },
})

Sentry.configureScope(scope => {
  scope.setTag('environment', ENV)
})

// FORCE HTTPS
const {protocol, host, hostname, pathname} = window.location
const correctHost = hostname === 'qa.mobile.opd.enterprisetechnologies.com' || hostname === 'mobile.opd.enterprisetechnologies.com'
console.log(protocol, host, hostname, pathname)

// if (protocol === 'http:') {
//   window.href = `https://${host}${pathname}`
// }

// if (window.matchMedia('(display-mode: standalone)').matches) {
//   console.log('We are in home screen')
// }

// iPhone X patch
/*
if (ons.platform.isIPhoneX()) {
  document.documentElement.setAttribute("onsflag-iphonex-portrait", "");
  document.documentElement.setAttribute("onsflag-iphonex-landscape", "");
}
*/

/*window.addEventListener('DOMContentLoaded', function() {
  var status = document.getElementById("status");

  function updateOnlineStatus(event) {
    var condition = navigator.onLine ? "online" : "offline";

    status.className = condition;
    console.log(`status.className -> ${status.className}`)
    //status.innerHTML = condition.toUpperCase();
  }
  window.addEventListener('online',  updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);
}); */


ReactDOM.render(<App />, document.getElementById('app'))
