import React, {useState, useEffect} from 'react'
//import {Select, CaretIcon, ModalCloseButton} from 'react-responsive-select'
import Picker from 'react-mobile-picker'
import {Select, Box, Button } from 'grommet'
import _ from "lodash"
import TimeFormat from 'hh-mm-ss'

import {store} from '../psrs'

// for default styles...
import 'react-responsive-select/dist/react-responsive-select.css'

export const SelectReasonV2 = (props) => {
  const {shift, update} = props
  const Records = store.get('Reasons')
  const options = _.sortBy(Records.map((r) => ({sort: r.description, value: r.id, text: r.description, payTypeCode: r.payTypeCode, category: r.category})), ['sort'])
  

  //const currentValue = props.timeEntry && props.timeEntry.duration ? props.timeEntry.duration  : 0
  const currentValue = props.shift && props.shift.reasonId ? props.shift.reasonId : null
  const [hours, setHours] = useState(currentValue)
  const [reasonId, setReasonId] = useState(currentValue)
  
  //const seconds = currentValue * 60 * 60
  //const HHMM = TimeFormat.fromS(seconds, 'hh:mm')
  //const HM = HHMM.split(':')
  //const H = HM[0]
  //const M = HM[1]
  
  const [valueGroups, setValueGroups] = useState({sort: '', value: '', text: '', payTypeCode: '', category: ''})

  const keys = Records.map(r => r.id)
  const vals = Records.map(r =>r.text )
  const options1 = {
    text: options.map(o => o.text),
  }

  const disabled = props.disabled
  //options.unshift({value: 'cancel', text: 'CANCEL'})
  options.unshift({value: 'clear', text: 'CLEAR'})

  const [selected, setSelected] = useState(shift.reasonId || undefined)

  const currentSelection = props && props.shift ? props.shift.reasonId : selected


  const handleChange = (name, value) => {
    const valGroup = {...valueGroups}
    valGroup[name] = value
    setValueGroups(valGroup)
  }

  const updateHours = () => {
    const hm = `${valueGroups.hours}:${valueGroups.minutes}`
    const secs = TimeFormat.toS(hm, 'hh:mm')
    const hours = secs / 60 / 60
    //console.log('updateDuration', hours)
    setHours(hours.toPrecision(4))
  }

  const updateReasonId = () => {
    setReasonId(valueGroups.value)
  }


  const xhandleChange = ({value}) => {


    if (value === 'cancel') {
      setSelected(shift.reasonId || undefined)
    } else if (value === 'clear') {
      setSelected(undefined)
      update({field: 'reasonId', id: shift.id, value: null})

    } else if (value !== 'cancel' && value !== 'clear' ) {
      setSelected(value)
      update({field: 'reasonId', id: shift.id, value})
    }
  }

  const handleSubmit = (value) => {
    console.log('SELECT onSubmit', value)
  }


  // Escaping regular expression special characters: [ \ ^ $ . | ? * + ( )
  const getEscapedText = text => text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

  // Create the regular expression with escaped special characters.
  const formatSearchExpression = text => new RegExp(getEscapedText(text), 'i');

  const onSearch = text => {
    const exp = formatSearchExpression(text);
    setXOptions(options.filter(option => exp.test(option.text)));
  };

  const x = options.find(o => o.value === shift.reasonId)
  const [value, setValue] = React.useState( [{value:x?.value, text: x?.text}] );
  const [xoptions, setXOptions] = useState(options);


  useEffect(() => {
    //updateHours()
    updateReasonId()
  }, [valueGroups])

  useEffect(() => {
    if (shift) {
      setValueGroups({sort: '', value: '', text: '', payTypeCode: '', category: ''})
      //setHours(timeEntry ? timeEntry.duration : 0)
      setReasonId(shift ? shift.reasonId : null)
    }
  }, [shift])

  
  return (
    <div className='HourMinutePicker'>
      <Box gap='none'>
        <Box gap='none' direction='row' pad='small' fill='horizontal' align='stretch' alignContent='stretch' flex='grow' justify='stretch'>
          <Box pad='small' alignContent='start' align='start'>
            <Button
              secondary
              size="small"
              label='Cancel'
              //fill='horizontal'
              color='#017BFF'
              onClick={() => {
                //props.handleUpdate({field: 'duration', value: timeEntry.duration, id: timeEntry.id})
                props.setReasonV2({open: false })
              }}
            />
          </Box>
          <Box fill='horizontal' pad='small'  justify='stretch' />
          <Box pad='small' align='end' alignContent='end'> 
            <Button
              primary
              size="small"
              label='Save'
              //fill='horizontal'
              color='#017BFF'
              onClick={(props) => {
                //props.update({field: 'reasonId', id: shift.id, value})
                //props.handleUpdate({field: 'duration', value: hours, id: timeEntry.id})
                props.setReasonV2({open: false})
              }}
            />
          </Box>
        </Box>
        <Box direction='row' pad='xxsmall' fill='horizontal' align='stretch' alignContent='stretch' flex='grow' justify='stretch'>
          <Box flex='grow' pad='xxsmall' size='small' style={{alignItems: 'bottom', color: 'black'}}>
            Select Reason
          </Box>

        </Box>
        <Box direction='row' pad='xxsmall' fill='horizontal' flex='grow' align='stretch' alignContent='stretch' justify='stretch'>
           <hr width="75%" color="black" />
        </Box>

        <Box direction='row' pad='xxsmall' fill='horizontal' align='stretch' alignContent='stretch' flex='grow' justify='stretch' background="white">
          <Picker optionGroups={options1} valueGroups={valueGroups} onChange={handleChange} />
        </Box>
        {/* <Box pad='small'>
          <Button
            primary
            label='Save'
            fill='horizontal'
            color='#017BFF'
            onClick={() => {
              props.handleUpdate({field: 'duration', value: hours, id: timeEntry.id})
              props.setDuration({open: false, timeEntry: null})
            }}
          />
        </Box> */}
      </Box>
    </div>
  )

  //return <Select disabled={disabled} name='reasonId' prefix='Acting: ' noSelectionLabel='Please select' modalCloseButton={<ModalCloseButton />} options={options} caretIcon={<CaretIcon />} selectedValue={currentSelection} onChange={handleChange} onSubmit={handleSubmit} />
  return (
    <Select
      //options={['small', 'medium', 'large']}
      labelKey="text"
      valueKey={"value"}
      name="assignment"
      options={xoptions}
      disabled = {disabled}
      placeholder="Select Assignment"
      searchPlaceholder="Search"
      clear={
        {
          position: "top",
          label: "Clear Assignment"
        }
      }
      value={value}
      onChange={({ option }) => {
        setValue(option)
        handleChange(option)
      }}
      onSearch={text => onSearch(text)}
    
    />
  );

}
