import React, { Children } from "react"

export const Tag = (props) => {
  const {color, size, children} = props
  const sizes = {
    small: ".75rem",
    medium: "1rem",
    large: "1.4rem"
  }

  const fontSize = size && !!sizes[size] ? sizes[size] : "1rem"

  const tagProps = {
    className: `tag ${color || 'default'} ${size || 'reg'}`,
    style: {
      fontSize,
      padding: ".25em .5em",
      borderRadius: ".25em",
      background: color || "#ccc",
      color: "white"
    }
  }
  return <span {...tagProps}>{ children }</span>
}
