import React, {useState} from 'react'
import {Select, CaretIcon, ModalCloseButton} from 'react-responsive-select'
import {store} from '../psrs'

// for default styles...
// import "react-responsive-select/dist/react-responsive-select.css";

export const SelectSubLedger = (props) => {
  const {timeEntry, update} = props
  const Records = store.get('SubLedgers')
  const options = Records.map((r) => ({
    value: r.code,
    text: `${r.description}`,
  }))

  const disabled = props.disabled
  options.unshift({value: 'cancel', text: 'CANCEL'})

  const [selected, setSelected] = useState(timeEntry.subLedgerCode || undefined)

  const handleChange = ({name, text, value, altered}) => {
    if (value === 'cancel') {
      setSelected(timeEntry.subLedgerCode)
    } else if (value !== 'cancel' && !!altered) {
      setSelected(value)
      update({field: 'subLedgerCode', id: timeEntry.id, value})
    }
  }

  const handleSubmit = (value) => {
    console.log('SELECT onSubmit', value)
  }

  return <Select disabled={disabled} name='subLedgerCode' prefix='Sub-Ledger: ' noSelectionLabel='Please select' modalCloseButton={<ModalCloseButton />} options={options} caretIcon={<CaretIcon />} selectedValue={selected} onChange={handleChange} onSubmit={handleSubmit} />
}
