import React, {useEffect, useState} from 'react'

import {Text, Box, Button, TextInput} from 'grommet'
import {ActionSheet, ActionSheetButton, BackButton, Toolbar, Page, Icon, Fab, Toast, Modal} from 'react-onsenui'
import {BottomToolbar} from 'react-onsenui'
import moment from 'moment'
import _ from 'lodash'
import PullToRefresh from 'react-simple-pull-to-refresh'
import browser from 'browser-detect'

import {SelectBusinessUnit, SelectPayType, SelectSubLedger, SelectAssignment, SelectHoursMinutes, } from '../components'
import {SelectLeaveBalance, SelectSickLeave, SelectSick, SelectPayTypes } from '../components'
import {SelectHoursMinutesAlt, SelectAssignmentAlt, SelectPayTypesAlt} from '../components'
import {SelectReasonV2} from '../components'

import {fetch, This} from '../psrs'
import {useAbilityContext} from '../useAbilityContext'

const DEBUG = false
//const useAlt = true

const TimeEntry = (props) => {
   const ability = useAbilityContext()
  // const currentUser = store.get("CurrentUser")
  const {route, navigator} = props
  const {data} = route
  const {shift} = data
  const {start, end} = shift
  const shiftDate = moment(start).format('ddd MMM Do')
  const shiftStart = moment(start).format('HH:mm')
  const shiftEnd = moment(end).format('HH:mm')
  const shiftTitle = `Shift ${shiftDate} ${shiftStart} - ${shiftEnd}`
  const [timeEntry, setTimeEntry] = useState(route.data.timeEntry)
  const [toast, setToast] = useState({open: false, text: ''})
  const [actionsOpen, setActionsOpen] = useState(false)

  const [duration, setDuration] = useState({open: false, timeEntry: null})
  const [reasonV2, setReasonV2] = useState({open: false, })

  let timeEntryDisabled = false
  if (timeEntry.submit || timeEntry.locked || timeEntry.approved ) {
    timeEntryDisabled = true
  }

  if (shift?.shiftTemplateId !== null && !shift?.shiftTemplate?.canEdit) {
    timeEntryDisabled = true
  }

  const canApprove = ability.can('approve', This('Shift', shift))
  const canSubmitShift = timeEntry?.shift?.isOT ? ability.can('submitOT', This('Shift', timeEntry?.shift)) : true
  const checkit =  moment().subtract(30, "minutes")
  const checkend = moment(shift.end)
  const canSubmit = checkit > checkend

  const cb = browser()
  const useAlt = !cb.mobile 

  const MemorizedSelectBusinessUnit = React.memo(SelectBusinessUnit)
  const MemorizedPayTypeSelect = React.memo(SelectPayType)
  //const MemorizedSelectLeaveBalance = React.memo(SelectLeaveBalance)
  //const MemorizedSelectSickLeave = React.memo(SelectSickLeave)
  const MemorizedSelectSubLedger = React.memo(SelectSubLedger)
  const MemorizedSelectAssignment = React.memo(SelectAssignment)
  const MemorizedSelectAssignmentAlt = React.memo(SelectAssignmentAlt)


  const MemorizedSelectSick = React.memo(SelectSick)
  const MemorizedSelectPayTypes = React.memo(SelectPayTypes)
  const MemorizedSelectPayTypesAlt = React.memo(SelectPayTypesAlt)


  const isRegular = !shift.isSick && !shift.requestedOff //&& !shift.available
  const isLeave = shift.requestedOff
  const isSick = shift.isSick

  const centerBox = {
    fill: true,
    align: 'center',
    alignContent: 'stretch',
    justify: 'center',
    width: 'xsmall',
    pad: 'none',
  }

  const Duration = ({timeEntry}) => {
    DEBUG && console.log('Duration', timeEntry)
    return (
      <Box {...centerBox}>
        <Box
          disabled = {timeEntryDisabled || timeEntry.payTypeCode === '380'}
          background={timeEntry.payTypeCode !== '380' && !timeEntryDisabled ? 'white' : '#F5F5F5'}
          style={{color: timeEntry.payTypeCode !== '380' && !timeEntryDisabled ? 'black' : 'grey'}}
          pad='medium'
          onClick={() => {
            if (!timeEntryDisabled && timeEntry.payTypeCode !== '380') {
              setDuration({timeEntry, open: true})
            }
          }}
        >
          {timeEntry.duration.toFixed(2)}
        </Box>
      </Box>
    )
  }

  const renderToolbar = () => {
    return (
      <Toolbar>
        <div className='left'>
          <BackButton onClick={() => navigator.popPage()}>Back</BackButton>
        </div>
        <div className='center'>Time Entry</div>
      </Toolbar>
    )
  }

  const renderActions = () => (
    <BottomToolbar>
      <Box justify='center' align='center' fill direction='row' pad='none'>
        <Button primary label='Time Entry Actions' onClick={() => setActionsOpen(true)} fill />
      </Box>
    </BottomToolbar>
  )

  //const handleChange = ({name, text, value, altered}) => {
  const update = async ({field, id, value, altered}) => {
    const body = {}
    body[field] = value
   // setTimeEntry({...timeEntry, ...body})
   // console.log('UPDATE TIME ENTRY', body, JSON.stringify(body))

    if (route.data.timeEntry.payTypeCode !== value && value !== '380') {
      DEBUG && console.log('UPDATE TIME ENTRY', body, JSON.stringify(body))
    
      const updated = await fetch(`/api/time_entries/${timeEntry.id}`, {method: 'PATCH', body: JSON.stringify(body)})

      if (updated && _.isObject(updated)) {
       // if (updated.modelName === 'TimeEntry') {
       //   setTimeEntry(updated) //<-- returned shift not time entry....
       // } else if (updated.modelName === 'Shift') {
       //   if (updated && _.isObject(updated)) store.set('Shift', updated)
       // }
      
        const _timeEntry = updated.timeEntries.find( t => t.id === route.data.timeEntry.id)
        setToast({open: true, text: 'Entry Updated'})
        setTimeEntry(_timeEntry)
        setTimeout(() => setToast({...toast, open: false}), 2000)

      }

    }
  }

  const fetchShift = async () => {
    const _shift = await fetch(`/api/shifts/${shift.id}`)
    if (_shift && _.isObject(_shift)) store.set('Shift', _shift)
  }

  const fetchTimeEntry = async () => {
    const _timeEntry = await fetch(`/api/time_entries/${route.id}`)
    if (_timeEntry && _.isObject(_timeEntry)) setTimeEntry(_timeEntry)
  }

  const submitShift = () => {
    setActionsOpen(false)
   //const canSubmitShift = ability.can('submitOT', This('Shift', timeEntry?.shift))
    const justShift = timeEntry?.shift || null
  
    if (canSubmitShift) {

      const field = 'submit'
      const value = !justShift.submit
      const body = {id: timeEntry.shiftId}
      body[field] = value
    
      fetch(`/api/shifts/${shift.id}`, {method: 'PATCH', body: JSON.stringify(body)})
    
      setToast({open: true, text: 'Shift and Time Submitted'})
      setTimeout(() => setToast({...toast, open: false}), 2000)
    }  else {
      setToast({open: true, text: 'OT Shift missing required fields'})
      setTimeout(() => setToast({...toast, open: false}), 2000)
    }

  }

  const submitEntry = () => {
    setActionsOpen(false)

    const canSubmitShift = ability.can('submitOT', This('Shift', timeEntry?.shift))
    //submit
    if (canSubmitShift && !timeEntry.submit) {
      //update({field: 'submit', id: timeEntry.id, value: !timeEntry.submit})
      update({field: 'submit', id: timeEntry.id, value: true})

    } else if (timeEntry.submit) {
      //unsubmit
      update({field: 'submit', id: timeEntry.id, value: false})
    } else {
      setToast({open: true, text: 'OT Shift missing required fields'})
      setTimeout(() => setToast({...toast, open: false}), 2000)
    }

    
  }

  const approveEntry = () => {
    setActionsOpen(false)
    update({field: 'approved', id: timeEntry.id, value: !timeEntry.approved})
  }

  const rejectEntry = () => {
    setActionsOpen(false)
    update({field: 'rejected', id: timeEntry.id, value: !timeEntry.rejected})
  }

  const deleteEntry = async () => {
    setActionsOpen(false)
    await fetch(`/api/time_entries/${timeEntry.id}`, {method: 'DELETE'})
    await fetchShift()
    navigator.popPage()
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    let isMounted = true
    if (isMounted) {
      fetchShift()
    } else {
      isMounted = true
    }
    
    return() => { 
      isMounted = false
      controller.abort();
    }

  }, [timeEntry])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    DEBUG && console.log('fetchTimeEntry')
    let isMounted = true
    if (isMounted) {
      fetchTimeEntry()
    } else {
      isMounted = true
    }

    return() => { 
      isMounted = false
      controller.abort();
    }

  }, [])



  // test start

  const startBox = {
    //align: "start",
    alignContent: 'stretch',
    justify: 'center',
    textAlign: 'start',
    hoverIndicator: true,
    round: 'small',
    pad: 'small',
    wrap: false,
    border: true,
    direction: "row",
  }

  const hrsBox = {
    align: "end",
    alignContent: 'stretch',
    justify: 'center',
    textAlign: 'center',
    hoverIndicator: true,
    round: 'small',
    pad: 'medium',
    wrap: false,
    border: true,
    style: {backgroundColor: 'lightGrey'}
  }

  // end test


  return (
    <Page id='ShiftPage' renderToolbar={renderToolbar} renderFixed={renderActions}>
       <PullToRefresh onRefresh={fetchTimeEntry}>
        <Box>
          <Box pad='small' background='#CCC'>
            <Text color='white' textAlign='center' size='large'>
              {shiftTitle}
            </Text>
          </Box>
          <Box>
            <Box pad='medium' direction='row' fill align='center'>
              <Box width='small'>Hours</Box>
                        {/*<Box fill>
                          <TextInput
                            disabled = {timeEntryDisabled}
                            defaultValue={timeEntry.duration}
                            onBlur={(e) =>
                              update({
                                field: 'duration',
                                value: parseFloat(e.target.value),
                                id: timeEntry.id,
                              })
                            }
                          />
                          </Box>*/}
              <Box fill {...startBox}>
              <Box fill {...{...startBox, background: "white" }}>
              { useAlt &&  <Duration disabled={timeEntryDisabled || timeEntry.payTypeCode === '380'} timeEntry={timeEntry} /> }
              { !useAlt &&   <SelectHoursMinutes disabled={timeEntryDisabled || timeEntry.payTypeCode === '380'} timeEntry={timeEntry} handleUpdate={update} setDuration={setDuration} /> }
              </Box>            
              {/*}            
                <TextInput
                  disabled = {timeEntryDisabled}
                  defaultValue={timeEntry.duration}
                  onBlur={(e) =>
                    update({
                      field: 'duration',
                      value: parseFloat(e.target.value),
                      id: timeEntry.id,
                    })
                  }
                />
                */}

                <Box {...hrsBox}>
                  <strong>Hrs</strong>
                </Box> 

              </Box>
            </Box>
            <Box pad='medium' direction='row' fill align='center'>
              <Box width='small'>Case Number</Box>
              <Box fill style={{background: "white"}}>
                <TextInput
                  disabled = {false}
                  defaultValue={timeEntry.caseNumber || undefined}
                  onBlur={(e) =>
                    update({
                      field: 'caseNumber',
                      value: e.target.value,
                      id: timeEntry.id,
                    })
                  }
                />
              </Box>
            </Box>
            <Box pad='medium'><strong>Status</strong></Box>
            <Box pad='medium'>Pay Type
              {/* PAY TYPE SELECT */}
              {/*isRegular && <MemorizedPayTypeSelect disabled = {timeEntryDisabled} timeEntry={timeEntry} update={update} />*/}
              {/*isRegular && <MemorizedSelectLeave selectType = "REGULAR" disabled = {timeEntryDisabled} timeEntry={timeEntry} update={update} />*/}
              {/*isLeave && <MemorizedSelectLeave selectType = "LEAVE" disabled = {timeEntryDisabled}  options={route.data.leavePayTypes} timeEntry={timeEntry} update={update} />*/}
              {/*isSick && <MemorizedSelectLeave selectType = "SICK" disabled = {timeEntryDisabled} options={route.data.sickPayTypes} timeEntry={timeEntry} update={update} />*/}

             { !useAlt &&  <MemorizedSelectPayTypes
                selectType =  {isLeave ? 'LEAVE' : isSick ? 'SICK' : 'REGULAR'}
                disabled = {timeEntryDisabled} 
                timeEntry={timeEntry} 
                update={update} 
              /> }

              { useAlt &&  <MemorizedSelectPayTypesAlt
                selectType =  {isLeave ? 'LEAVE' : isSick ? 'SICK' : 'REGULAR'}
                disabled = {timeEntryDisabled} 
                timeEntry={timeEntry} 
                update={update} 
              /> }



            </Box>
            <Box pad='medium'>Subledger
              <MemorizedSelectSubLedger disabled = {true} timeEntry={timeEntry} update={update} />
            </Box>
            <Box pad='medium'>Assignment
               { !useAlt && <MemorizedSelectAssignment disabled = {true} shift={timeEntry} update={update} /> }
               {  useAlt && <MemorizedSelectAssignmentAlt disabled = {true} shift={timeEntry} update={update} setToast ={setToast} /> }
            </Box>
          </Box>
        </Box>
      </PullToRefresh>
      <ActionSheet isOpen={actionsOpen} onPostHide={() => setActionsOpen(false)}>
        {/* <ActionSheetButton onClick={deleteEntry} modifier={'destructive'}>
          Delete
        </ActionSheetButton> */}
        
        {/* Delete */}
        <ActionSheetButton
          modifier={timeEntryDisabled ? 'disabled' : 'destructive'}
          onClick={() => {
            if (!timeEntryDisabled) {
              deleteEntry()
            }
          }}
        >
          Delete
        </ActionSheetButton>

        <ActionSheetButton 
          modifier={!canSubmit || !canSubmitShift ? 'disabled' : ''} 
          onClick={submitShift}>{timeEntry.submit ? 'Un-Submit Shift and Entries' : 'Submit Shift and Entries'}
          
        </ActionSheetButton>
        {canApprove &&<ActionSheetButton onClick={approveEntry}>{timeEntry.approved ? 'Un-Approve' : 'Approve'}</ActionSheetButton>}
        {canApprove && <ActionSheetButton onClick={rejectEntry}>{timeEntry.rejected ? 'Un-Reject' : 'Reject'}</ActionSheetButton>}
        <ActionSheetButton onClick={() => setActionsOpen(false)}>Cancel</ActionSheetButton>
      </ActionSheet>

      <Toast isOpen={toast.open} animation="fall">
        <div style={{textAlign: 'center'}}>{toast.text}</div>
      </Toast>

      <Modal animation='lift' isOpen={duration.open} style={{display: "none", position: 'fixed', top: 'auto', right: 'auto', left: 'auto', bottom: 0}}>  
        <SelectHoursMinutesAlt disabled = {timeEntryDisabled} timeEntry={duration.timeEntry} handleUpdate={update} setDuration={setDuration} />
      </Modal>
      <Modal isOpen={reasonV2.open}>
        <SelectReasonV2 disabled = {timeEntryDisabled} shift={shift} update={update} setToast ={setToast} setReasonV2={setReasonV2}/>
      </Modal>

    </Page>
  )
}

export default TimeEntry
