
import React, {useState, useEffect} from 'react'
import {
  Page,
  Toolbar,
  BackButton,
  Col,
  Row,
  Card, 
  Button
} from "react-onsenui";
import { ServoiceFeedback } from 'react-feedback-widget'
import * as Sentry from "@sentry/browser";
import {uid, session, store} from "../psrs"

//import RobotError from  "../img/robot.svg"
import RobotError from "../img/feedback.png"

export const Feedback = (props) => {

  const {navigator, route} = props
  const {view} = route

  const routes = navigator.routes
  
  let backLabel = view

  const i = routes ? routes.findIndex(o => o.view === view) : -1

  if (i > 0 ) {
    backLabel = routes[i-1]
  }

  const currentUser = session.get("CurrentUser")

  const feedBackConf = {
    servId: 'cl9irlurm000709li0oqz2tb8',
    servPID: 'cl9irlurm000809limnjew6pp',
    userEmail: currentUser.email || '',
    userFirstName: currentUser.firstName || '',
    userLastName: currentUser.lastName || '',
  }
  
  const renderBack = true 

  // inline css
  const state = {
    error: "",
    eventId: uid(8),
    errorInfo: "",
    hasError: false,
  }

  const css = {
    divContainer: { 
      height: "100%",
      width: "100%",
      position: "relative",
    },
    divCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    img: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      //width: 200,
      //height: 200,
      width: 300,
      height: 200,
    }

  }
  
  const SentryFeedback = (comments) => {
    const sentryId = Sentry.captureMessage('User feedback');

    const userFeedback = {
      event_id: sentryId,
      name: `${feedBackConf.userFirstName} ${feedBackConf.userLastName}`,
      email: feedBackConf.userEmail,
      comments: comments,
    };

    Sentry.captureUserFeedback(userFeedback);
    //clearComments();
  }

  const renderToolbar = () => (
      <Toolbar>
        <div className="left">
          <BackButton>Back</BackButton>
        </div>
        <div className='center'> {backLabel.view} </div>
      </Toolbar>
    )

    return (
    <Page id='Feedback' renderToolbar={renderToolbar} >
       <ServoiceFeedback config={feedBackConf}>
          <Button
            style={{backgroundColor: "#EFEFF4"}}
          ></Button>
       </ServoiceFeedback>
      <Card key={uid(8)}>
        <Row key={uid(8)}>
          <Col key={uid(8)} verticalAlign="center">
              <img style={css.img} src={RobotError} alt="Error" />
          </Col>
        </Row>
      </Card>
      <Card key={uid(8)}>
        <div style={css.divContainer}>
          <div style={css.divCenter}>
            {/* <ServoiceFeedback config={feedBackConf}>
              <Button
                  id="feedbackBtn"
                  className="bg-primary text-light"
                  modifier="quiet"
              >Feedback</Button>
            </ServoiceFeedback> */}
            <Button
              className="bg-primary text-light"
              modifier="quiet"
              onClick={() => {
                const eventId = Sentry.captureMessage(`User Feedback - ${uid(8)}`);
                const email = 
                Sentry.showReportDialog(
                  { 
                    eventId: eventId,
                    title: 'Enter your feedback',
                    subtitle: 'Add tag of #suggestion, #feature, #bug, #other',
                    labelComments: 'Submit Feedback',
                    labelClose: 'Close',
                    labelSubmit: 'Submit Feedback',
                    successMessage: 'Your feedback has been sent. Thank you!ur',
                    user: {
                      email: feedBackConf.userEmail,
                      name: `${feedBackConf.userFirstName} ${feedBackConf.userLastName}`
                    }
                  }
                )
              }
              }
            >
              Feedback
            </Button>
          </div>
        </div>
      </Card>
      <Card key={uid(8)}>
        <div style={css.divContainer}>
          <div style={css.divCenter}>
            <a style={{textAlign: "center" }}>Thank you for your feedback!!</a>
          </div>
        </div>
      </Card>
    </Page>
  )
}
export default Feedback