export {default as Approvals} from './Approvals'
export {default as Available} from './Available'
export {default as Employee} from './Employee'
export {default as Employees} from './Employees'
export {default as Icons} from './Icons'
export {default as Login} from './Login'
export {default as MyShifts} from './MyShifts'
export {default as Schedules} from './Schedules'
// export { default as Settings } from "./Settings"
export {default as Shift} from './Shift'
export {default as Shifts} from './Shifts'
export {default as SubSchedule} from './SubSchedule'
export {default as TimeEntry} from './TimeEntry'
export {default as MyApprovals} from './MyApprovals'
export {default as NewShift} from './NewShift'
export {default as EmpApprovals} from "./EmpApprovals"
export {default as EmpShifts} from "./EmpShifts"
export {default as Feedback} from "./Feedback"


