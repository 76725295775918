import React, { useState } from "react";
import {
  BackButton,
  ToolbarButton,
  Toolbar,
  Page,
  List,
  ListItem,
  SearchInput,
  Card,
  ListHeader,
  Icon,
} from "react-onsenui";
import _ from 'lodash'
import { set } from "store2";
import { store, uid, fetch} from "../psrs";
import { SearchSelect } from "../components";

const DEBUG = false

const Employees = (props) => {
  const { navigator, route } = props;

  DEBUG && console.log('props -> ', props)

  const me = store.get("CurrentUser")

  const [employeeIds, setEmployeeIds] = useState([])

  const pushPage = (row) => {
    if (row) {
      const employee = row;
      navigator.pushPage({
        view: "Employee",
        title: row.fullName,
        backButton: true,
        employee
      });
    }
  };


  const xrenderRow = (row) => {
    return (
      <ListItem modifier="chevron" key={uid(8)}>
        <div key={uid(8)} className="left">
          <p className="jobPill">{row.jobTitle || row.number}</p>
        </div>
        <span key={uid(8)} className="list-item__title" onClick={(e) => pushPage(row)}>
          {row.fullName}
        </span>
      </ListItem>
    );
  };

  const generateAvatar = (text, foregroundColor, backgroundColor) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = 50;
    canvas.height = 50;

    // Draw background
    context.fillStyle = backgroundColor;
    context.borderRadius = '50%';
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.font = "bold 24px  system-ui";
    context.fillStyle = foregroundColor;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width/2, canvas.height/2 );

    return canvas.toDataURL("image/png");
}


  const renderRow = (row) => {
    const userInitials = `${row.firstName.substring(0,1)}${row.lastName.substring(0,1)}`
    return (
            
        <List key={uid(8)} modifier="inset" >

          <ListItem key={uid(8)} modifier="chevron" onClick={(e) => pushPage(row)} >
            <div key={uid(8)} className='list-item__title' >
              <div key={uid(8)} style={{width: '25%', textAlign: 'center', boxSizing: 'border-box', float: 'left'}}>
                <img key={uid(8)} src={generateAvatar(userInitials, 'white','#A8A391')} alt="Avatar"  style={{borderRadius: '50%'}} />
              </div>
              <div key={uid(8)} style={{width: '75%', boxSizing: 'border-box', float: 'right'}}>
                <div key={uid(8)}>
                  <strong key={uid(8)}>{row.fullName}</strong> 
                </div>
                <div key={uid(8)}>
                  {row.jobTitle || row.number}
                </div>
                <div key={uid(8)}>
                  {row.businessUnit.name || ''}
                </div>
                <div key={uid(8)}>
                 <Icon key={uid(8)} icon="md-phone" className="list-item__icon"></Icon>
                 {row.phone || 'xxx-xxx-xxxx'}
                </div>
                <div key={uid(8)}>
                  <Icon key={uid(8)} icon="md-email" className="list-item__icon"></Icon>
                  {row.email || 'someone@somwhere.com'}
                </div>
              </div>
            </div>
          </ListItem>
        </List>
      
    )
  }

  const [empSearch, setEmpSearch] = useState([])
  const query = async (e) => {
    const model = 'employee'
    let theSearch = e.target.value.replace(/\s/g, '') 
    theSearch = encodeURIComponent(theSearch.trim())
    const ids = await fetch(`/api/search?model=${model}&search=${theSearch}`)
    setEmpSearch(ids)
  }

  
  const handleSearch = _.debounce(query, 1500, { leading: false, trailing: true })
  
  let filtered = empSearch.length > 0 ? store.get("Employees").filter( e => empSearch.includes(e.id)) : store.get("Employees").filter( e => e.supervisorNumber === me.number)
  if (me.role === 'admin') {
    filtered = empSearch.length > 0 ? store.get("Employees").filter( e => empSearch.includes(e.id)) : store.get("Employees")
  }

   // fileter out disablded
   filtered = filtered.filter( e => e.role.toLowerCase() !== 'disabled')

  filtered = _.orderBy(filtered, 'lastName', 'asc')

  const listProps = {
    // length: state.data.length,
    // calculateItemHeight: () => 44,
    dataSource: filtered,
    // dataSource: empSearch.length>0 ? 
    //  store.get("Employees").filter( e => empSearch.includes(e.id))
    //  :
    //  employeeIds.length>0 ? 
    //  store.get("Employees")
    //  : 
    //  store.get("Employees"),
    renderRow,
    //renderCard,
  };
  const pageProps = {
    onShow: (event) => { },
    onInit: (event) => {},
    renderToolbar: () => (
      <>
      <Toolbar key={uid(8)}>
        <div key={uid(8)} className="left">
          <BackButton key={uid(8)} onClick={() => navigator.popPage()}>Back</BackButton>
        </div>
        <div key={uid(8)} className="center">{props.route.title || props.route.view}</div>
      </Toolbar>
      </>
    ),

  };

  return (
    <Page key={uid(8)} id="EmployeesPage" {...pageProps}>
      <SearchInput key={uid(8)} placeholder='Employee name' style={{width: '100%'}} modifier='material' onChange={handleSearch} />
      <List key={uid(8)} {...listProps} />
    </Page>
  );
};

export default Employees;
