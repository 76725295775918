import React, { useRef, useState, useEffect } from "react";
import { Box, Button, Select, Text } from "grommet";
import { FormClose } from "grommet-icons";
import { store } from "../psrs";
import _ from "lodash";

export const ReasonSelect = (props) => {
  const {
    model,
    field,
    value,
    pageRef,
    setOverlayed,
  } = props;
  const values = !!value && Array.isArray(value) ? value : [value];
  const resourceName = "Reason";
  const resourcesName = "Reasons";
  const resources = store.get("Reasons");
  const allRecords = resources.map((r, index) => {
    const name = r.description;
    let record = { index, name, value: r.id };
    return record;
  });
  const _selected = value
    ? allRecords.filter((r) => r.value === value[0][pk])
    : null;
  const [mounted, setMounted] = useState(false);
  const [selected, setSelected] = useState(_selected);
  const [records, setRecords] = useState(allRecords);
  const selectRef = useRef();

  const clearSelected = () => { setSelected([]) };

  useEffect(() => { setMounted(true) }, []);

  useEffect(() => {
    if (mounted) {
      console.log("selected", selected, field, model);
      props.handleUpdate({ field, value: selected.map((s) => s.value) });
    }
  }, [selected]);

  const Option = (args) => {
    // { selected: false, value: { code: "17", name: "Jury Duty", search: "Jury Duty 17", value: "17" } }
    const { value, index } = args;
    const isSelected = selected.find((r) => r.index === index);
    const boxProps = {
      background: isSelected ? "blue" : null,
      key: value.value,
      direction: "row",
      align: "center",
      pad: "small",
      flex: false,
    };
    return (
      <Box {...boxProps}>
        <Text>{value.name}</Text>
      </Box>
    );
  };

  const renderRecords = () => (
    <Box direction="row" gap="xsmall" align="center" flex>
      <Box flex pad="medium">
        <Text size="medium" truncate>
          {selected[0].name}
        </Text>
      </Box>
      <Button
        href="#"
        onFocus={(event) => event.stopPropagation()}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          clearSelected();
          selectRef.current.focus();
        }}
      >
        <Box background="gray" round="full">
          <FormClose style={{ width: "12px", height: "12px" }} />
        </Box>
      </Button>
    </Box>
  );
  if (!pageRef.current) {
    return <Box></Box>;
  } else {
    return (
      <Box fill align="center" justify="center" width="large">
        <Select
          disabled={props.disabled}
          id="SearchSelect"
          focusIndicator={false}
          replace={false}
          placeholder="Reason(s) for OT"
          alignSelf="stretch"
          dropTarget={pageRef.current || null}
          ref={selectRef}
          closeOnChange={true}
          multiple={false}
          // value: shift.reasons,
          value={ selected && selected.length ? renderRecords() : undefined }
          overflow={{ horizontal: "hidden", vertical: "auto" }}
          responsive={false}
          options={records}
          onOpen={() => {
            setOverlayed(true);
          }}
          onClose={() => {
            setOverlayed(false);
            setSearching(false);
            setSerchQuery("");
          }}
          onChange={(change) => {
            setSelected([change.value]);
          }}
          dropProps={{
            className: "SelectOpen",
            elevation: "none",
          }}
          stretch={false}
        >
          {(option, index) => {
            return <Option value={option} index={index} />;
          }}
        </Select>
      </Box>
    );
  }
};
