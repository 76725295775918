import React, {useEffect, useState} from "react"
import { Shifts } from "./Shifts"

const DEBUG = false

export const MyShifts = props => {
  // const { route } = props
  // const myShiftProps = {...props, route: { view: "MyShifts"}, shifts: route.data.shifts}

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (!mounted) setMounted(true)
  
    return () => {
      DEBUG && console.log('useEffect - fetchRecords')
      setMounted(false)
      controller.abort()

    } 
  }, [props])

  return <div><Shifts { ...props } /></div>

}

export default MyShifts
