import React, {useState, useEffect} from 'react'
import {useAbilityContext} from '../useAbilityContext'
import {BackButton, Page, Toolbar, BottomToolbar} from 'react-onsenui'
import {ActionSheet, ActionSheetButton, Icon, Fab, Checkbox, Card, List,ListItem, SpeedDial, SpeedDialItem, ProgressCircular } from 'react-onsenui'
import {Button, Box, Text} from 'grommet'
import { Table, TableHeader, TableRow, TableBody, TableCell, Grommet, DataTable} from 'grommet'
import {FormNext} from 'grommet-icons'
import moment from 'moment'
import {TimePicker} from '../components'
import {store, fetch, Can, a, an, This, uid} from '../psrs'
import _ from 'lodash'
import PullToRefresh from 'react-simple-pull-to-refresh';

import { SwipeableList, SwipeableListItem, ActionAnimations } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

import { Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
//import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import '../styles/approvals.css'

const DEBUG = false

export const MyApprovals = (props) => {
  const {navigator, route} = props
  const {view} = route
  const currentView = store.get('View')
  // console.log("route.data", route.data)
  const ability = useAbilityContext()
  const Employee = route.data && route.data.employee ? route.data.employee : store.get('CurrentUser')
  const viewData = store.get('ViewData')
  const Shifts = viewData ? viewData : []
  const _selected = new Set(store.get('ShiftsSelected'))
  const [selected, setSelected] = useState(_selected)
  //const [shifts, setShifts] = useState(_.orderBy(Shifts, 'start', 'asc'))
  const [shifts, setShifts] = useState([])
  // const [overlayed, setOverlayed] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  const [actions, setActions] = useState({
    selected: new Set(),
    open: false,
  })

  const [isLoading, setIsLoading] = useState(false)

  const pushPage = (S) => {
    store.set('Shift', S)
    navigator.pushPage({
      view: 'Shift',
      data: {shift: S},
      id: S.id,
    })
  }
  

  const fetchRecords = async (mounted) => {
    setIsLoading(true)
    let records = []
    let ordered = []
     
    records = await fetch(`/api/mobile/my_approvals/shift`)
    ordered = _.orderBy(records, 'start', 'asc')
    store.set('Shifts', ordered)
    //if (mounted) {
    //  setShifts(ordered)
   // }
    setIsLoading(false)
   return ordered
    
  }

  const pullRefresh = async () => {

    fetchRecords()
    .then ((records) => {
        store.set('Shifts', records)
        setShifts(records)
      
    })

  }

  const updateShifts = async (params) => {
    const ids = Array.from(actions.selected.values())
    setActions({selected: new Set(), open: false})
    await fetch(`/api/shifts`, {method: 'PATCH', body: {params, ids}})
    await pullRefresh()
    return true
  }

  const xxsm = {size: 'xxsmall'}

  const toggleActions = () => {
    setActions({...actions, open: !actions.open})
  }

  const TimeBox = ({value, field, shift, disabled, checked}) => {
    /* const P = {
      textAlign: 'center',
      hoverIndicator: true,
      round: 'small',
      pad: 'small',
      wrap: false,
    }
    const TB = {
      start: {...P, background: 'green'},
      end: {...P, background: 'yellow'},
      hrs: {...P, background: 'grey'},
      ot: {...P, background: 'red'},
    } */
    
    const P = {
      align: "start",
      alignContent: 'start',
      textAlign: 'start',
      hoverIndicator: true,
      round: 'small',
      pad: 'small',
      wrap: false,
    }

    const TB = {
      start: {...P, background: '#EFEFF4'},
      end: {...P, background: '#EFEFF4'},
      hrs: {...P, background: '#EFEFF4'},
      ot: {...P, background: '#EFEFF4'},
    }

    let TBP = TB[field]

  
    if (checked) {
      TBP = {...TBP, background: 'LightSteelBlue' }
    }
    if (disabled) {
      TBP = {...TBP, background: 'lightgrey' }
    }

    if (disabled !== true && field === 'start') {
      TBP.onClick = () => {
        openPicker(shift, field)
      }
    }
    if (disabled !== true && field === 'end') {
      TBP.onClick = () => {
        openPicker(shift, field)
      }
    }

    //const TBP = TB[field]
    return (
      <Box {...TBP}>
        <Text size='xsmall' textAlign='center'>
          {value}
        </Text>
      </Box>
    )
  }

  const TOP = {alignSelf: 'start', margin: 'none'}
  const BT = {border: 'top'}
  const TD = {...BT, size: 'xsmall', pad: 'xsmall'}

  const xrenderRow = (S) => {
    const SS = moment(S.start)
    const SE = moment(S.end)
    const DDD = SS.format('ddd')
    const MO = SS.format('MMM')
    const DO = SS.format('Do')
    const ST = SS.format('HH:mm')
    const ET = SE.format('HH:mm')
    const DUR = `${S.duration}`
    const OT = `${S.durationOt}`
    const Date = () => (
      <div className='DateColumn'>
        <div className='WeekDay'>{DDD}</div>
        <div className='Month'>{MO}</div>
        <div className='Date'>{DO}</div>
      </div>
    )
    const cantEdit = ability.cannot('edit:shifts', This('Employee', Employee)) || !!S.submit || !!S.process
    const uncheckable = !!S.isSick || !!S.requestedOff || !!S.submit || !!S.process
    const statusProps = {
      textAlign: 'center',
      // round: "small",
      // pad: "small",
      wrap: false,
      fill: true,
      direction: 'row',
      align: 'center',
    }
    const iconProps = {margin: {right: '6px'}}

    const sickStatus = S.isSick && !S.process
    const ptoStatus = S.requestedOff && !S.process
    const otStatus = S.isOT
    const processedStatus = !!S.process
    const showNotes = false
    const disableCheckbox = view === 'Approvals' ? false : uncheckable ? true : cantEdit ? true : false
    return (
      <React.Fragment key={`${S.id}Row`}>
         
       {/*  <TableRow key={`shiftRow${S.id}`} width='100vw' className='ShiftTrData'>
          <TableCell {...BT}>
            <Box direction='row' fill align='center'>
              <Box pad='small'>
                <Checkbox
                  id={S.id}
                  disabled={disableCheckbox}
                  checked={actions.selected.has(S.id)}
                  onChange={(event) => {
                    const {checked} = event.target
                    if (!!checked) actions.selected.add(S.id)
                    if (!checked) actions.selected.delete(S.id)
                    console.log('CheckBox', actions.selected.has(S.id))
                    setActions({...actions})
                  }}
                  modifier='material'
                />
              </Box>
              <Box>
                <Date />
              </Box>
            </Box>
          </TableCell>
          <TableCell {...TD}>
            <TimeBox value={ST} field='start' shift={S} />
            <TimeBox value={ET} field='end' shift={S} />
          </TableCell>
          <TableCell {...TD}>{S && S.employee && S.employee.fullName ? S.employee.fullName : 'NONE'}</TableCell>
          <td style={{width: '68px'}} className='otStatus'>
            {!!sickStatus && (
              <Box {...statusProps}>
                <Box {...iconProps}>
                  <Icon icon='md-plaster' size={20} />
                </Box>
                <Box>
                  <span className='status'>SICK</span>
                </Box>
              </Box>
            )}
            {!!ptoStatus && (
              <Box {...statusProps}>
                <Box {...iconProps}>
                  <Icon icon='md-calendar' size={20} />
                </Box>
                <Box>
                  <span className='status'>PTO</span>
                </Box>
              </Box>
            )}
            {!!otStatus && (
              <Box {...statusProps}>
                <Box {...iconProps}>
                  <Icon icon='md-time' size={20} />
                </Box>
                <Box>
                  <span className='status'>OT {S.durationOt}</span>
                </Box>
              </Box>
            )}
            {!!processedStatus && (
              <Box {...statusProps}>
                <Box {...iconProps}>
                  <Icon icon='md-money-box' size={20} />
                </Box>
                <Box>
                  <span className='status'>PROCESS'D</span>
                </Box>
              </Box>
            )}
          </td>
          <TableCell {...TD}>
            <Button size='small' onClick={() => pushPage(S)} icon={<FormNext />} />
          </TableCell>
        </TableRow> */}
        {showNotes && (
          <TableRow key={`shiftRow${S.id}Notes`} width='100vw' className='ShiftTrNotes'>
            <td colSpan={6}>
              <Box pad='medium' background='white' margin='medium'>
                <Text {...xxsm}> {S.notes} </Text>
              </Box>
            </td>
          </TableRow>
        )}
      </React.Fragment>
    )
  }


  const renderRow = (S) => {
    const SS = moment(S.start)
    const SE = moment(S.end)
    const DDD = SS.format('ddd')
    const MO = SS.format('MMM')
    const DO = SS.format('Do')
    const ST = SS.format('HH:mm')
    const ET = SE.format('HH:mm')
    const DUR = S.duration ? `${S.duration.toFixed(2)}` : '0.00'
    const OT = S.durationOt ? `${S.durationOt.toFixed(2)}` : '0.00'
    const shiftDate = SS.format('ddd MM/DD/YY')
    const altShiftDate = SS.format('MM/DD/YY')
   
    const Date = () => (
      <div className='DateColumn'>
       
        <div className='Month'>
            <small><strong>{DDD}</strong></small>&nbsp;
            <small>{altShiftDate}</small>
        </div>
        {/* <div className='WeekDay'>{DDD}</div>
        <div className='Month'>{MO}</div>
        <div className='Date'>{DO}</div> */}
      </div>
    )
    const Shift = S
    const cantEdit = ability.cannot('edit:shifts', This('Employee', Employee)) || !!S.submit || !!S.process
    const uncheckable = false //!!S.isSick || !!S.requestedOff || !!S.submit || !!S.process
    const statusProps = {
      textAlign: 'center',
      // round: "small",
      // pad: "small",
      wrap: false,
      fill: true,
      direction: 'row',
      align: 'center',
    }
    const iconProps = {margin: {right: '6px'}}

    const otStatus = S.isOT && !S.process
    const sickStatus = S.isSick && !S.process
    const ptoStatus = S.requestedOff && !S.process
    const submittedStatus = S.submit && !S.process
    const processedStatus = !!S.process
    const showNotes = false
    const disableCheckbox = view === 'Approvals' ? false : uncheckable ? true : cantEdit ? true : false
    return (
      <React.Fragment key={`${S.id}Row`}>
        {/* <SwipeableListItem
        swipeLeft={{
          content: <div style={{textAlign: 'left'}}>Un-Submit</div>,
          //actionAnimation: 'REMOVE',
          action: () => {
            updateShifts({submit: false})
            console.info('swipe action triggered')
          }
        }}
        swipeRight={{
          content: <div>Submit</div>,
          action: () => {
            updateShifts({submit: true})
            console.info('swipe action triggered')
          }
        }}
        onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
      > */}
        <TableRow key={`shiftRow${S.id}`} width='100vw' className='ShiftTrData'>
          <TableCell {...TD}>
            {/* <Box direction='row' fill align='center'> */}
             {/*  <Box pad='small'>
                <Checkbox
                  id={S.id}
                  disabled={disableCheckbox}
                  checked={actions.selected.has(S.id)}
                  onChange={(event) => {
                    const {checked} = event.target
                    if (!!checked) actions.selected.add(S.id)
                    if (!checked) actions.selected.delete(S.id)
                    console.log('CheckBox', actions.selected.has(S.id))
                    setActions({...actions})
                  }}
                  modifier='material'
                />
              </Box> */}
               
              <Box
                fill
                align="stretch"
                direction="row"
                pad="small"
                round='small'
                background={actions.selected.has(S.id) ? "LightSteelBlue" : disableCheckbox? "lightgrey" : "#EFEFF4"} 
                hoverIndicator = { {color: disableCheckbox ? "lightgrey" : "LightSteelBlue" } }
                onClick = {() =>{
                    if (disableCheckbox) return 

                    const checked = actions.selected.has(S.id)
                    if (!checked) actions.selected.add(S.id)
                    if (checked) actions.selected.delete(S.id)
                    setActions({...actions})
                  }}
              >
                <Date />
               
              </Box>
            {/* </Box> */}
           
          </TableCell>

          <TableCell {...TD}>
            <small><TimeBox disabled={cantEdit} value={ST} field='start' shift={S} checked={actions.selected.has(S.id)} /></small>
          </TableCell>
          <TableCell {...TD}>
            <small><TimeBox disabled={cantEdit} value={ET} field='end' shift={S} checked={actions.selected.has(S.id)} /></small>
          </TableCell> 
          <TableCell {...TD}>
            <small><TimeBox disabled={cantEdit} value={DUR} field='hrs' shift={S}  checked={actions.selected.has(S.id)} /></small>
          </TableCell>
          <TableCell scope="col" {...TD} checked={actions.selected.has(S.id)}>
            {S && S.employee && S.employee.fullName ? `${S.employee.fullName.split(',')[1].charAt(1)}. ${S.employee.fullName.split(',')[0]}` : 'NONE'}
          </TableCell>
          <TableCell {...TD}>
            <Button size='small' onClick={() => pushPage(S)} icon={<FormNext />} />
          </TableCell>
        </TableRow>
       
{/*         <TableRow key={`shiftRow${S.id}Status`} widith='100vw' className='ShiftNotes'>
          <TableCell scop="row" colSpan={6}>
              <StatusIcons shift={S} />
          </TableCell>

        </TableRow> */}
        {showNotes && (
          <TableRow key={`shiftRow${S.id}Notes`} width='100vw' className='ShiftTrNotes'>
            <TableCell scope="row" colSpan={6}>
              <Box
                align="stretch"
                alignContent="stretch"
                fill="horizontal"
                flex={true}
                direction="row"
                pad='medium' 
                background='white' 
                margin='medium'
              >
                <Text {...xxsm}> {S.notes} </Text>
              </Box>
            </TableCell>
          </TableRow>
        )}
        {/* </SwipeableListItem> */}
      </React.Fragment>
    )
  }


  const zzrenderRow = (S) => {
    const SS = moment(S.start)
    const SE = moment(S.end)
    const DDD = SS.format('ddd')
    const MO = SS.format('MMM')
    const DO = SS.format('Do')
    const ST = SS.format('HH:mm')
    const ET = SE.format('HH:mm')
    const DUR = S.duration ? `${S.duration.toFixed(2)}` : '0.00'
    const OT = S.durationOt ? `${S.durationOt.toFixed(2)}` : '0.00'
    const shiftDate = SS.format('ddd MM/DD/YY')
    const altShiftDate = SS.format('MM/DD/YY')
   
    const Date = () => (
      <div className='DateColumn'>
       
        <div className='Month'>
            <small><strong>{DDD}</strong></small>&nbsp;
            <small>{altShiftDate}</small>
        </div>
        {/* <div className='WeekDay'>{DDD}</div>
        <div className='Month'>{MO}</div>
        <div className='Date'>{DO}</div> */}
      </div>
    )
    const Shift = S
    const cantEdit = ability.cannot('edit:shifts', This('Employee', Employee)) || !!S.submit || !!S.process
    const uncheckable = false //!!S.isSick || !!S.requestedOff || !!S.submit || !!S.process
    const statusProps = {
      textAlign: 'center',
      // round: "small",
      // pad: "small",
      wrap: false,
      fill: true,
      direction: 'row',
      align: 'center',
    }
    const iconProps = {margin: {right: '6px'}}

    const otStatus = S.isOT && !S.process
    const sickStatus = S.isSick && !S.process
    const ptoStatus = S.requestedOff && !S.process
    const submittedStatus = S.submit && !S.process
    const processedStatus = !!S.process
    const showNotes = false
    const disableCheckbox = view === 'Approvals' ? false : uncheckable ? true : cantEdit ? true : false
    return (
      <React.Fragment key={`${S.id}Row`}>
        {/* <SwipeableListItem
        swipeLeft={{
          content: <div style={{textAlign: 'left'}}>Un-Submit</div>,
          //actionAnimation: 'REMOVE',
          action: () => {
            updateShifts({submit: false})
            console.info('swipe action triggered')
          }
        }}
        swipeRight={{
          content: <div>Submit</div>,
          action: () => {
            updateShifts({submit: true})
            console.info('swipe action triggered')
          }
        }}
        onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
      > */}
        <Tr key={`shiftRow${S.id}`} >
          <Td>
            {/* <Box direction='row' fill align='center'> */}
             {/*  <Box pad='small'>
                <Checkbox
                  id={S.id}
                  disabled={disableCheckbox}
                  checked={actions.selected.has(S.id)}
                  onChange={(event) => {
                    const {checked} = event.target
                    if (!!checked) actions.selected.add(S.id)
                    if (!checked) actions.selected.delete(S.id)
                    console.log('CheckBox', actions.selected.has(S.id))
                    setActions({...actions})
                  }}
                  modifier='material'
                />
              </Box> */}

               
              <Box
                fill
                align="stretch"
                direction="row"
                pad="small"
                round='small'
                background={actions.selected.has(S.id) ? "LightSteelBlue" : disableCheckbox? "lightgrey" : "#EFEFF4"} 
                hoverIndicator = { {color: disableCheckbox ? "lightgrey" : "LightSteelBlue" } }
                onClick = {() =>{
                    if (disableCheckbox) return 

                    const checked = actions.selected.has(S.id)
                    if (!checked) actions.selected.add(S.id)
                    if (checked) actions.selected.delete(S.id)
                    setActions({...actions})
                  }}
              >
                <Date />
               
              </Box>
            {/* </Box> */}
           
          </Td>

{/*           <TableCell {...TD}>
            <small><TimeBox disabled={cantEdit} value={ST} field='start' shift={S} checked={actions.selected.has(S.id)} /></small>
          </TableCell>
          <TableCell {...TD}>
            <small><TimeBox disabled={cantEdit} value={ET} field='end' shift={S} checked={actions.selected.has(S.id)} /></small>
          </TableCell> */}
          <Td>
            <small><TimeBox disabled={cantEdit} value={DUR} field='hrs' shift={S}  checked={actions.selected.has(S.id)} /></small>
          </Td>
          <Td checked={actions.selected.has(S.id)}>
            {S && S.employee && S.employee.fullName ? `${S.employee.fullName.split(',')[1].charAt(1)}. ${S.employee.fullName.split(',')[0]}` : 'NONE'}
          </Td>
          <Td >
            <Button size='small' onClick={() => pushPage(S)} icon={<FormNext />} />
          </Td>
        </Tr>
       
{/*         <TableRow key={`shiftRow${S.id}Status`} widith='100vw' className='ShiftNotes'>
          <TableCell scop="row" colSpan={6}>
              <StatusIcons shift={S} />
          </TableCell>

        </TableRow> */}
        {showNotes && (
          <TableRow key={`shiftRow${S.id}Notes`} width='100vw' className='ShiftTrNotes'>
            <TableCell scope="row" colSpan={6}>
              <Box
                align="stretch"
                alignContent="stretch"
                fill="horizontal"
                flex={true}
                direction="row"
                pad='medium' 
                background='white' 
                margin='medium'
              >
                <Text {...xxsm}> {S.notes} </Text>
              </Box>
            </TableCell>
          </TableRow>
        )}
        {/* </SwipeableListItem> */}
      </React.Fragment>
    )
  }


  const renderCard = (S) => {

    const SS = moment(S.start)
    const SE = moment(S.end)
    const DDD = SS.format('ddd')
    const MO = SS.format('MMM')
    const DO = SS.format('Do')
    const ST = SS.format('HH:mm')
    const ET = SE.format('HH:mm')
    const DUR = S.duration ? `${S.duration.toFixed(2)}` : '0.00'
    const OT = S.durationOt ? `${S.durationOt.toFixed(2)}` : '0.00'
    const shiftDate = SS.format('ddd MM/DD/YY')
    const altShiftDate = SS.format('MM/DD/YY')

    const statusProps = {
      textAlign: 'center',
      // round: "small",
      // pad: "small",
      wrap: false,
      fill: true,
      direction: 'row',
      align: 'center',
    }
    const iconProps = {margin: {right: '6px'}}

    const otStatus = S.isOT && !S.process
    const sickStatus = S.isSick && !S.process
    const ptoStatus = S.requestedOff && !S.process
    const submittedStatus = S.submit && !S.process
    const processedStatus = !!S.process
    const approveStatus = !!S.approved
    const rejectStatus = !!S.rejected
    const approvedBy = S.approvedByNumber
    const onDemandShift = S.shiftTemplateId !== null
    const locked = S.locked
    const approved = S.approved
    const rejected = S.rejected
    const acting = S.assignmentId
   
    const Date = () => (
      <div className='DateColumn'>
       
        <div className='Month'>
            <small><strong>{DDD}</strong></small>&nbsp;
            <small>{altShiftDate}</small>
        </div>
        {/* <div className='WeekDay'>{DDD}</div>
        <div className='Month'>{MO}</div>
        <div className='Date'>{DO}</div> */}
      </div>
    )

    const BasicSwipeContent = ({ label, position }) => (
      <div className={`basic-swipeable-list__item-content-${position}`}>
        <span><Icon icon={position==='right' ? 'md-thumb-down':'md-thumb-up'} size={18}>{' '}{label}</Icon></span>
      </div>
    );

    const swipeLeftOptions = () => ({
      content: <BasicSwipeContent label="Reject" position="right" />,
      action: console.info('swipe action triggered - Reject')
    });

    const swipeRightOptions = () => ({
      content: <BasicSwipeContent label="Approve" position="left" />,
      action: console.info('swipe action triggered - Approve')
    });

    return (
      <>
       {/*  <SwipeableListItem
          key={S.id}
          swipeLeft={{
            content: <div style={{ textAlign: 'left'}}>Un-Approve</div>,
            //actionAnimation: 'REMOVE',
            action: () => {
              //updateShifts({submit: false})
              console.info('swipe action triggered')
            }
          }} 
           swipeRight={{
            content: <div className={`basic-swipeable-list__item-content-right`}>Approve</div>,
            action: () => {
              //updateShifts({submit: true})
              console.info('swipe right action triggered')
            }
          }} 
          //swipeLeft={swipeLeftOptions(S.id)}
          //swipeRight={swipeRightOptions(S.id)}
          //onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
        > */}
       
          <List key={uid(8)} modifier="inset" style={{width: '100%'}}>
            <ListItem key={uid(8)} style={{width: '100%'}}>
              <div>
                <strong>{S.employee.fullName}</strong>
                <strong> | Approved: </strong>{S.approve ? ' True' : ' False'}
              </div>
            </ListItem>
            <ListItem key={uid(8)} style={{width: '100%'}}>
              
              {/* <div style={{width: '100%'}}> */}
                {/* <div className='columns'> */}
                <Table key={uid(8)}>
                  <TableBody key={uid(8)}>
                    <TableRow key={uid(8)}>
                      <TableCell key={uid(8)} scope='col' align='center'>
                        <Checkbox
                          id={S.id}
                          modifier='noborder'
                          disabled={true} //{disableCheckbox}
                          checked={actions.selected.has(S.id)}
                          onChange={(event) => {
                            const {checked} = event.target
                            if (!!checked) actions.selected.add(S.id)
                            if (!checked) actions.selected.delete(S.id)
                            DEBUG && console.log('CheckBox', actions.selected.has(S.id))
                            setActions({...actions})
                          }}
                        />
                      </TableCell>
                      <TableCell key={uid(8)} scope="col" align='start'>
                      <div className='column'>
                        <div className='Date'>
                          <small><strong>Date: </strong>{altShiftDate}</small>
                        </div>
                        <div className='Start'>
                          <small><strong>Start: </strong> {ST}</small>
                        </div>
                        <div className='End'>
                        <small><strong>End : </strong>{ET}</small>
                        </div>
                        <div className='Duraton'>
                        <small><strong>Duration: </strong>{DUR}</small>
                        </div>
                      </div>
                      </TableCell>
                      <TableCell key={uid(8)} scope="col" align='start'  className='otStatus'>
                        <div style={{align: 'left'}}>
                        {!!approveStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-thumb-up' size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small> APPROVED</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!rejectStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-thumb-down' size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small> REJECTED</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!sickStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-plaster' style={{color: '#98312C'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>SICK</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!ptoStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-calendar' style={{color: '#E47641'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>LEAVE</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!submittedStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-assignment-check' style={{color: '#3482A9'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>SUBMIT'D</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!processedStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-money-box' style={{color: 'green'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small> PROCESS'D</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!otStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-time' style={{color: 'black'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>OT</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!onDemandShift && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-alarm-plus' style={{color: '#CD853F'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>New</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!acting && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-shield-security' style={{color: '#2E8B57'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>ACTING</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!approved && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-thumb-up' style={{color: '#70BCFE'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>APPRV'D</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!rejected && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-thumb-down' style={{color: 'red'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>REJECT'D</small></span>
                            </Box>
                          </Box>
                        )}
                      {!!locked && (
                        <Box {...statusProps}>
                          <Box {...iconProps}>
                            <Icon icon='md-lock' size={18} />
                          </Box>
                          <Box>
                            <span className='status'><small>LOCK'D</small></span>
                          </Box>
                        </Box>
                      )}
                      {/* {!uncheckable && <TimeBox disabled={cantEdit} value={OT} field='ot' shift={S} />} */}
                      </div>
                    </TableCell>
                    <TableCell key={uid(8)} scope="col" align='end' size="xxsmall" verticalAlign="middle" >
                      <div style={{ align: 'right' }}>
                          <Button style={{size: '100%'}} size='small' onClick={() => pushPage(S)} icon={<FormNext />} />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
                {/* </div>
              </div> */}
            
            </ListItem>
          </List>
        {/* </SwipeableListItem> */}
        </>
      
    )
  }

  const renderBack = true //navigator.routes.length > 2

  const count = actions.selected.size
  const viewTitle = view === 'Approvals' ? 'Approvals' : `${Employee.fullName} Shifts`
  const buttonTitle = view === 'Approvals' ? `Approve / Reject ${count} Shifts` : `Edit ${count} Selected Shifts`
  const buttonPlaceholder = view === 'Approvals' ? 'Select Shifts to Approve / Reject' : 'Select Shifts to Edit'

  const renderToolbar = () => (
    <Toolbar>
      {!!renderBack && (
        <div className='left'>
          <BackButton>Back</BackButton>
        </div>
      )}
      <div className='center'> {viewTitle} </div>
    </Toolbar>
  )

  const canEdit = ability.can('edit', an('Employee', Employee))
  //const canApprove = ability.can('approve', an('Employee', Employee))
  const canApprove = true //ability.can('approve', an('Shift', Shifts))

  const canProcess = ability.can('process')

  const renderApprovalActionButton = () => {
    const disabled = count === 0
    return (
      <Can I='edit:shifts' an={Employee}>
        <BottomToolbar>
          <Box justify='center' align='center' fill direction='row' pad='none'>
            <Button disabled={disabled} onClick={toggleActions} label={disabled ? buttonPlaceholder : buttonTitle} primary fill />
          </Box>
        </BottomToolbar>
      </Can>
    )
  }

  useEffect(() => {

    let isMounted
    if (isMounted) {
      store.set('Shifts', shifts)
    } else {
      setIsMounted(true)
      isMounted = true
    }

    return () => {
      setIsMounted(false)
      isMounted = false
    }
   
  }, [shifts])

  useEffect(() => {
    let mounted = true
    //fetchRecords(mounted)
    fetchRecords()
    .then ((records) => {
        store.set('Shifts', records)
        setShifts(records)   
    })

    return () => {
      mounted = false
      setIsMounted(false)
    }
  }, [])

  return (
    <Page id='ApprovalsPage' renderToolbar={renderToolbar} >
     {isLoading && (
       <div className=' approveDivLoading'><ProgressCircular indeterminate className=' approveProgressLoading' /></div>
      )}
      <PullToRefresh onRefresh={pullRefresh}>
        <Box full >
          <List key={uid(8)}>{shifts.map((shift) => renderCard(shift))}</List> 
        </Box>
      </PullToRefresh>
    </Page>
  )
}

export default MyApprovals
