import React from "react"
import { Icon, Popover } from "react-onsenui"
import {Box} from "grommet"

import "../styles/statusincons.sass"

export const StatusIcons = (props) => {
    const {
        shift, 
        updateShift,
    } = props

    const { 
        submit, 
        //isOT, 
        hasBid, 
        available,
        requestedOff,
        isSick, 
        approved, 
        rejected,
        imbalanced,
        assignmentId,
        locked,
        processed,
    } = shift


    const isOT = !!shift.durationOt
    const shiftId = shift.id


    /* const submitted = { className: `stat ${submit ? "on submitted" : ""}`, icon: "md-assignment-check" }
    const ot = { className: `stat ${isOT ? "on has overtime" : ""}`, icon: "md-time" }
    //const bid = { className: `stat ${hasBid ? "on bidded" : available ? "on available" : ""}`, type: "user-add" }
    const pto = { className: `stat ${requestedOff ? "on pto" : ""}`, icon: "md-calendar" }
    const sick = { className: `stat ${isSick ? "on sick" : ""}`, icon: "md-plaster" }
    const good = { className: `stat ${approved ? "on approved" : ""}`, icon: "md-like" }
    const bad = { className: `stat ${rejected ? "on rejected" : ""}`, icon: "md-dislike" }
    const wrongTime = { className: `stat ${imbalanced ? "on imbalanced" : ""}`, icon: "md-stop" }
    const acting = { className: `stat ${assignmentId ? "on acting" : ""}`, icon: "safety-certificate" }
    const isLocked = { className: `stat ${locked ? "on locked": "on unlocked" }`, icon: locked ? "lock" :"unlock" }
    cons t isProcessed = { className: `stat ${locked ? "on locked": "on unlocked" }`, icon: "md-money-box" }*/

    const iconSize =15;

    const submitted = { className: `stat ${submit ? "on submitted" : ""}`, size: iconSize, icon: "md-assignment-check" }
    const ot = {  className: `stat ${isOT ? "on has overtime" : ""}`, size: iconSize, icon: "md-time" }
    const bid = { className: `stat ${hasBid ? "on bidded" : available ? "on available" : ""}`, size: iconSize, icon: "md-account-calendar" }
    const pto = { className: `stat ${requestedOff ? "on pto" : ""}`, size: iconSize, icon: "md-calendar" }
    const sick = { className: `stat ${isSick ? "on sick" : ""}`, size: iconSize, icon: "md-plaster" }
    const good = { className: `stat ${approved ? "on approved" : ""}`, size: iconSize, icon: "md-thumb-up"  }
    const bad = { className: `stat ${rejected ? "on rejected" : ""}`, size: iconSize, icon: "md-thumb-down" }
    const wrongTime = { className: `stat ${imbalanced ? "on imbalanced" : ""}`, size: iconSize, icon: "md-eye-off" }
    const acting = { className: `stat ${assignmentId ? "on acting" : ""}`, size: iconSize, icon: "md-assignment-account" }
    const isLocked = { className: `stat ${locked ? "on locked": "on unlocked" }`, size: iconSize, icon: locked ? "lock" :"unlock" }
    const isProcessed = {className: `stat ${submit ? "on locked": "on unlocked"} "justify-content-center" `, size: iconSize, icon: "md-mood" }

    
    return (
        <div className="StatusIcons">

            {/* BID */}
            <span className={`statusIcon`}  >
                <div {...bid} style={{alignContent: "center"}}>
                    <Icon style={{alignContent: "center"}} size={15} icon="md-assignment-check"/>
                </div>
          
            </span>

            {/* SICK */}
            <span className="statusIcon">
                <Icon {...sick} />
            </span>

            {/* VACATION */}
            <span className="statusIcon" >
                <Icon {...pto} />
            </span>

            {/* SUBMIT */}
            <span className={`statusIcon`} >
                <Icon {...submitted} />
            </span>

            {/* OVERTIME */}
            <span className="statusIcon" >
                <Icon {...ot} />
            </span>

            {/* IMBALANCED */}
            <span className="statusIcon">
                <Icon {...wrongTime} />
            </span>

            {/* ACTING ROLE */}
            <span className="statusIcon" >
                <Icon {...acting} />
            </span>

            {/* REJECTED */}
            <span className={`statusIcon`} >
                <Icon {...bad} />
            </span>

            {/* APPROVED */}
            <span className={"statusIcon"} >
                <Icon {...good} />
            </span>{   }

             {/* PROCESSED */}
             <span className={"statusIcon"} >
                <Icon {...isProcessed} />
            </span>

            {/* LOCKED */}
            <span className="statusIcon" >
                <Icon {...isLocked} />
            </span>

        </div>
    )
}