import React from "react"
import { Select } from "react-onsenui"
// import { Tag } from "./Tag"
import { store } from "../psrs"

export const SupervisorSelect = (props) => {
  const {shift, setShift} = props

  const Employees = store.get('supervisors')

  const renderOptions = () => {
    return Employees.map(item => (
      <option key={`supervisor${item.id}`} value={item.number}>{item.jobTitle} {item.fullName}</option>
    ))
  }

  const selectOptions = {
    modifier: null,
    disabled: false,
    value: shift.superNumber1,
    onChange: superNumber1 => setShift({...shift, superNumber1}),
    multiple: false,
    autofocus: false,
    required: false,
  }

  return <Select {...selectOptions}>{renderOptions()}</Select>
}

