import React, {useRef, useState, useEffect} from 'react'

import {Table, TableHeader, TableRow, TableCell} from 'grommet'
import {DataTable, TableBody, Text, Box, Button} from 'grommet'
import {TextArea, TextInput, MaskedInput, Stack, Layer} from 'grommet'
import {BackButton, Toolbar, BottomToolbar, Page, Icon, Fab, PullHook} from 'react-onsenui'
import {ListItem, Toast, Modal} from 'react-onsenui'
import {ActionSheet, ActionSheetButton} from 'react-onsenui'
import browser from 'browser-detect'

import {useAbilityContext} from '../useAbilityContext'
import {TimePicker, SearchSelect, Reasons, LeaveBalanceSelect} from '../components'
import {SelectPayType, SelectSick, SelectReason, SelectHoursMinutes, SelectAssignment} from '../components'
import {SelectLeaveBalance, SelectSickLeave, SelectPayTypes} from '../components'
import {SelectPayTypesAlt, SelectHoursMinutesAlt, SelectReasonAlt, SelectAssignmentAlt } from '../components'
//import { SelectReasonV2 } from '../components'

import {uid, store, fetch, This} from '../psrs'


import PullToRefresh from 'react-simple-pull-to-refresh';


import moment from 'moment'
import _ from 'lodash'

const DEBUG = false
const DDEBUG = true

//const useAlt = true

const Shift = (props) => {
  const {route, navigator} = props
  const currentUser = store.get('CurrentUser')
  if (!currentUser) {
    store.clearAll()

    //alert('not current user logout')
    console.log('not currentUser logout')
    navigator.resetPage({view: 'Login', title: 'Logout'})
  }
  const ability = useAbilityContext()
  const {data} = route
  const employeeNumber = currentUser.number
  const [toast, setToast] = useState({open: false, text: ''})
  const [shift, setShift] = useState(store.get('Shift') || props.route.data.shift)
  const [overlayed, setOverlayed] = useState(false)
  const [duration, setDuration] = useState({open: false, timeEntry: null})
  const [reasonV2, setReasonV2] = useState({open: false, })
  const ref = useRef(null)
  const allClosed = {
    actions: false,
    end: false,
    start: false,
    reasons: false,
    payType: false,
  }
  const [open, setOpen] = useState(allClosed)
  const [isMounted, setIsMounted] = useState(false)


  const truncString = (str, n) => {
    return str.substr(0,n-1)+(str.length>n?'...':'');
  }

  //const Records = store.get('PayTypes').filter((r) => !!r.included && r.category === 'SICK' || r.code === '380')
  //const options = _.sortBy(Records.map((r) => ({sort: parseInt(r.code), value: r.code, text: `${r.code} - ${truncString(r.rename,20)}`, markup: <small>{r.code} - {r.rename}</small>, disabled: r.code === '380' ? true : false})), ['sort'])
  /*
  const _sickPayTypes = store.get("PayTypes").filter(r => !!r.included  && r.category === "SICK" || r.code === '380')
  //const sickOptions = _sickPayTypes.map((p) => (
  //  { value: p.code, 
  //    text: `${p.code} - ${p.rename ? p.rename : p.description}`, 
  //    markup: <small>{p.rename ? `${p.code} - ${p.rename}` : `${p.code} - ${p.description}`}</small> 
  //  }
  //))
  
  const sickOptions = _.sortBy(_sickPayTypes.map((r) => ({
    sort: parseInt(r.code), 
    value: r.code, text: `${r.code} - ${truncString(r.rename,20)}`, 
    markup: <small>{r.code} - {r.rename}</small>, 
    disabled: r.code === '380' ? true : false
  })), ['sort'])

  
  const _leavePayTypes = store.get("PayTypes").filter(r => !!r.included  && r.category === "LEAVE" || r.code === '380')
  const leaveOptions = _.sortBy(_leavePayTypes.map((r) => ({
    sort: parseInt(r.code), 
    value: r.code, text: `${r.code} - ${truncString(r.rename,20)}`, 
    markup: <small>{r.code} - {r.rename}</small>, 
    disabled: r.code === '380' ? true : false
  })), ['sort'])

  const [sickPayTypes, setSickPayTypes] = useState(sickOptions)
  const [leavePayTypes, leaveSickPayTypes] = useState(leaveOptions)
  */

  let shiftDisabled = false
  if (shift.submit || shift.locked || shift.approved ) {
    shiftDisabled = true
  } 

  if (shift.shiftTemplateId !== null && !shift?.shiftTemplate?.canEdit) {
    shiftDisabled = true
  }


  const MemorizedPayTypeSelect = React.memo(SelectPayType)
  const MemorizedSelectReason =  React.memo(SelectReason)
  const MemorizedSearchSelect = React.memo(SearchSelect)
  //const MemorizedSelectLeaveBalance = React.memo(SelectLeaveBalance)
  //const MemorizedSelectSickLeave = React.memo(SelectSickLeave)
  const MemorizedSelectAssignment = React.memo(SelectAssignment)
  const MemorizedSelectAssignmentAlt = React.memo(SelectAssignmentAlt)
  const MemorizedSelectReasonAlt = React.memo(SelectReasonAlt)
  //const MemorizedSelectReasonV2 = React.memo(SelectReasonV2)

  const MemorizedSelectSick = React.memo(SelectSick)
  const MemorizedSelectPayTypes = React.memo(SelectPayTypes)
  const MemorizedSelectPayTypesAlt = React.memo(SelectPayTypesAlt)

  const cb = browser()
  const useAlt = !cb.mobile 

  const toggleOpen = (key) => {
    const O = {
      actions: false,
      endTime: false,
      startTime: false,
      reasons: false,
      payType: false,
    }
    if (key) O[key] = !open[key]
    setOpen(O)
  }

  const [pullHookState, setPullHookState] = useState('initial')

  const cantEditshift = !!shift.submit || !!shift.process || ability.cannot('edit', This('Shift', shift))
  const canEditshift = !shift.process && ability.can('edit', This('Shift', shift)) || (shift.shiftTemplateId !== null && !shift?.shiftTemplate?.canEdit)
  
  const canApprove = ability.can('approve', This('Shift', shift))
  //const canSubmit = ability.can('submit', This('Shift', shift))
  //const checkit =  moment().subtract(30, "minutes")
  //const checkend = moment(shift.end)
  //const canSubmit = checkit > checkend

  const canSubmitOT = shift.isOT ? ability.can('submitOT', This('Shift', shift)) : true
  const canSubmit = moment(shift.end).subtract(1,"hour").toISOString() <= moment().toISOString() ? true:false


  const renderActionButton = !!shift.available || !!canApprove || !!canEditshift
  const canCallInSick = ability.can('sick', This('Shift', shift))
  const canRequestOff = ability.can('pto', This('Shift', shift)) 
  const canDeleteShift = shift.shiftTemplateId !== null && (!shift.locked && !Shift.process && !shift.submit && !shift.approved )
  const canSeeBids = ability.can('see:shift:bids', This('Shift', shift))
  const bids = new Set(shift && shift.bids ? shift.bids : [])
  const Employees = store.get('Employees')
  const Bids = _.find(Employees, (e) => bids.has(e.number))
  const TH = {
    scope: 'col',
    border: 'bottom',
    alignContent: 'center',
    align: 'center',
  }
  const TD = {scope: 'col'}
  const SS = moment(shift.start)
  const SE = moment(shift.end)
  const SD = SS.format('ddd MMM Do')
  const ST = SS.format('HH:mm')
  const ET = SE.format('HH:mm')
  const DUR = shift.duration ? `${shift.duration.toFixed(2)}` : '0.00'
  const OT = shift.durationOt ? `${shift.durationOt.toFixed(2)}` : '0.00'
  const TOP = {alignSelf: 'stretch', margin: 'xsmall', width: 100}
  const LABEL = {}
  const INPUT = {}
  const isRegular = !shift.isSick && !shift.requestedOff && !shift.available
  const isAvailable = shift.available
  const isLeave = shift.requestedOff
  const isSick = shift.isSick
  const isScheduledOT = shift.scheduledOT
  const isOT = shift.isOT
  const centerBox = {
    fill: true,
    align: 'center',
    alignContent: 'stretch',
    justify: 'center',
    width: 'xsmall',
    pad: 'none',
  }
  const spacing = {
    gap: 'small',
    pad: 'small',
  }
  const sectionTitleProps = {
    textAlign: 'center',
    color: 'white',
    alignSelf: 'center',
    pad: {},
  }


  const bidOnshift = () => {
    const employeeNumber = currentUser.number
    if (bids.has(employeeNumber)) bids.delete(employeeNumber)
    else bids.add(employeeNumber)
    // fetch(`/api/resources/shift/${shift.id}`, { method: "PATCH", body: { bids: Array.from(bids) }})
    updateshift({field: 'bids', value: Array.from(bids)})
  }

  const closePicker = async () => {
    setOpen(allClosed)
    setOverlayed(false)
  }

  const closePickerAndSave = async (value, field, shiftId) => {
    updateshift({field, value: value.format(), shiftId})
  }

  const timeProps = {closePicker, closePickerAndSave, open: true}

  const openPicker = (field) => {
    if (canEditshift) {
      const open = {...allClosed}
      open[field] = true
      setOverlayed(true)
      setOpen(open)
    }
  }

  const addEntry = async () => {
    DEBUG && console.log(`ADDING TIME ENTRY TO ${shift.timeEntries.length} EXISTING`)

    if (shiftDisabled) return
    
    const params = {payTypeCode: '1'}
    if (isScheduledOT || isOT) params.payTypeCode = '200'
    if (isSick) params.payTypeCode = '420'
    if (isLeave) params.payTypeCode = '31'
    const updated = await fetch(`/api/shift/create-time-entry/${shift.id}`, {method: 'POST', body: JSON.stringify(params)})
    DEBUG && console.log(`ADDED TIME ENTRY NOW ${updated.timeEntries.length} ENTRIES`)
    setShift({...updated})
  }

  const updateshift = async (args) => {
    DEBUG && console.log('Shift > updateshift > args', args)
    const field = args.field || args.target.getAttribute('name')
    const value = args.value
    const body = {id: shift.id}
    body[field] = args.target ? args.target.value : value
    setOpen(allClosed)
    setOverlayed(false)
    DEBUG && console.log('UPDATE SHIFT BODY', body)
    setShift({...shift, ...body})
    const updated = await fetch(`/api/shifts/${shift.id}`, {method: 'PATCH', body: JSON.stringify(body)})
    DEBUG && console.log('UPDATED TimeEntries', updated.timeEntries.length)
    setToast({open: true, text: 'Shift Updated'})
    setShift(updated)
    setTimeout(() => setToast({...toast, open: false}), 2000)
  }

  const deleteShift = async (args) => {
    const field = args.field || args.target.getAttribute('name')
    const value = args.value
    const body = {id: shift.id}
    setOpen(allClosed)
    setOverlayed(false)
    const deletedShift = await fetch(`/api/shifts/${shift.id}`, {method: 'DELETE' })

    const toastMsg = deletedShift === -1 ? 'shift can not be deleted' : 'shift Deleted'

    setToast({open: true, text: toastMsg})
    setTimeout(() => setToast({...toast, open: false}), 4000)
    //await fetchRecords()
    //navigator.popPage()
    navigator.pushPage({
      view: 'Shifts',
      //data: {shift: shift},
      //id: shift.id,
    })
  }

  const update = async ({field, id, value}) => {
    const body = {}
    body[field] = value
    setShift({...shift, ...body})
    const updated = await fetch(`/api/shifts/${id}`, {method: 'PATCH', body: JSON.stringify(body)})
    setShift(updated)
  }

  const updateEntry = async ({ field, id, value }) => {

  
      let _entry = _.orderBy(shift.timeEntries, 'i', 'asc')
       _entry = _entry.find(e => e.id === id)

      if (field === 'payTypeCode' && _entry.payTypeCode === value && value !== '380') {
        return true
      } else if (field === 'duration' && _entry.duration === parseFloat(value)) {
        return true
      }
    
    //let _entry = _.orderBy(shift.timeEntries, 'i', 'asc')
    //_entry = _entry.find(e => e.id === id)

    //if (_entry.payTypeCode !== value && value !== '380') {
      const body = {}
      body[field] = value
      await fetch(`/api/time_entries/${id}`, {method: 'PATCH', body: JSON.stringify(body)})
  
      //if (field !== 'payTypeCode') 
      fetchShift()
      setToast({open: true, text: 'Shift Updated'})
      setTimeout(() => setToast({...toast, open: false}), 2000)
    //}

  }

  const fetchShift = async () => {
    const _shift = await fetch(`/api/shifts/${shift.id}`)
    DEBUG && console.log('Fetched Shift', _shift)
    if (_shift && _.isObject(_shift)) setShift(_shift)
  }

  const renderToolbar = () => {
    return (
      <Toolbar>
        <div className='left'>
          <BackButton onClick={() => navigator.popPage()}>Back</BackButton>
        </div>
        <div className='center'>{shift.employee ? shift.employee.fullName : 'Available shift'}</div>
      </Toolbar>
    )
  }

  const TimeBox = ({value, field, disabled}) => {
    const P = {
      textAlign: 'center',
      hoverIndicator: true,
      round: 'small',
      pad: 'medium',
    }
    const TB = {
      start: {...P, background: 'white'},
      end: {...P, background: 'white'},
      hrs: {...P, background: 'white'},
      ot: {...P, background: 'white'},
    }
    const TBP = TB[field]
    if (canEditshift) {
      if (field === 'start' || field === 'end') {
        TBP.onClick = () => {
          DDEBUG && console.log('TBP.onClick', value, field, disabled, cantEditshift)
          if (!shiftDisabled) {
            openPicker(field)
          }
        }
      }
    }
    return (
      <Box {...TBP}>
        <Text disabled={cantEditshift || shiftDisabled} textAlign='center'>
          {value}
        </Text>
      </Box>
    )
  }

  const Duration = ({timeEntry}) => {
    DEBUG && console.log('Duration', timeEntry)
    return (
      <Box {...centerBox}>
        <Box 
          disabled = {shiftDisabled || timeEntry.payTypeCode === '380'}
          background={timeEntry.payTypeCode !== '380' && !shiftDisabled ? 'white' : '#F5F5F5'}
          style={{color: timeEntry.payTypeCode !== '380' && !shiftDisabled ? 'black' : 'grey'}}
          pad='small'
          onClick={() => {
            if (!shiftDisabled && timeEntry.payTypeCode !== '380') {
              setDuration({timeEntry, open: true})
            }
          }}
        >
          {timeEntry.duration.toFixed(2)}
        </Box>
      </Box>
    )
  }

  const SectionTitle = (props) => (
    <Box className='shiftSectionHeader' pad='small' gap='none' margin='none' fill>
      <Stack anchor='right'>
        <Box background='#A8A391' pad='none' margin='none'>
          <Text {...sectionTitleProps}>{props.text}</Text>
        </Box>
        <Box pad='none' margin='none'>
          <span className='addEntryButton' onClick={addEntry}>
            add entry
          </span>
        </Box>
      </Stack>
    </Box>
  )

  const rendershiftActionButton = () => (
    <>
      {!!renderActionButton && (
        <BottomToolbar>
          {!!shift.available && (
            <Box justify='center' align='center' fill direction='row' pad='none'>
              <Button primary label={bids.has(employeeNumber) ? 'Remove Bid' : 'Bid On shift'} onClick={() => bidOnshift()} fill />
            </Box>
          )}
          {!shift.available && (
            <Box justify='center' align='center' fill direction='row' pad='none'>
              {!!overlayed && <Button onClick={() => setOverlayed(false)} label='Close' secondary fill />}
              {!overlayed && <Button primary label='Shift Actions' onClick={() => toggleOpen('actions')} fill />}
            </Box>
          )}
        </BottomToolbar>
      )}
    </>
  )

  const renderTotal = () => {
    const shiftDuration = shift ? shift.duration : 0
    const durations = shift && shift.timeEntries ? shift.timeEntries.map((e) => e.duration) : [0]
    const subTotal = durations.reduce((total, val) => total + val)
    const balanced = subTotal === shiftDuration
    return (
      <Box direction='row' {...spacing}>
        <Box width='medium' pad='none' border>
          {!balanced && (
            <Box pad='medium' background='red' round='small' margin='small'>
              <Text size='small'>Entries do not equal the Shift Duration!</Text>
            </Box>
          )}
        </Box>
        <Box width='xsmall' pad='medium' border>
          <Text textAlign='end'>Total:</Text>
        </Box>
        <Box width='small' pad='medium' border>
          {subTotal.toFixed(2)}
        </Box>
      </Box>
    )
  }

  const renderTimeEntriesTable = () => {
    return (
      <>
        <SectionTitle text='Time Entries' />
        <Box {...spacing}>
          <DataTable fill="horizontal"
            disabled = {shiftDisabled}
            data={_.orderBy(shift.timeEntries, 'i', 'asc')}
            primaryKey='id'
            resizeable={false}
            sortable={false}
            pad='none'
            columns={[
              {
                size: 'xlarge',
                property: 'payTypeCode',
                header: 'Pay Type',
                render: (entry) => {

                  if (entry.payTypeCode !== '380') {
                    //return <MemorizedPayTypeSelect disabled={shiftDisabled} timeEntry={entry} update={updateEntry} />
                   
                    return (
                      <Box pad='none' fill={true} direction='row'  background = {{color: shiftDisabled ? "#F5F5F5":"white"}}   >
                      { useAlt ?  <MemorizedSelectPayTypesAlt selectType = "REGULAR" disabled={shiftDisabled} timeEntry={entry} update={updateEntry} />
                      : <MemorizedSelectPayTypes selectType = "REGULAR" disabled={shiftDisabled} timeEntry={entry} update={updateEntry} /> }

                      </Box>
                    )
                  }

                  const lunchBoxStyle = {
                    color: '#A7A7A7',
                    background: '#F5F5F5',
                    pointerEvents: "none",
                  }
                  const _lunchPayType = store.get("PayTypes").filter(r => r.code === '380')[0]

                  return (
                    <Box pad='small'>
                      <Box 
                        //border={{size: ''}}
                        style={ lunchBoxStyle } 
                        //ref={ tirggerRef }
                        direction='row' 
                        fill={true} 
                        pad="small" 
                        background = {{color: "white"}}   
                      >  
                        
                          { `${_lunchPayType.code} - ${_lunchPayType.rename ? _lunchPayType.rename : _lunchPayType.description }` }
                      </Box>
                    </Box>
                  )

                },
              },
              {
                size: 'small',
                property: 'duration',
                header: 'Hours',
                //render: (entry) => <Duration disabled={shiftDisabled || entry.payTypeCode === '380'} timeEntry={entry} />,
                //render: (entry) => <SelectHoursMinutes disabled={shiftDisabled || entry.payTypeCode === '380'} timeEntry={entry} handleUpdate={updateEntry} setDuration={setDuration} />
                render: (entry) => useAlt ? <Duration disabled={shiftDisabled || entry.payTypeCode === '380'}timeEntry={entry} /> 
                :
                   <SelectHoursMinutes disabled={shiftDisabled || entry.payTypeCode === '380'} timeEntry={entry} handleUpdate={updateEntry} setDuration={setDuration} />,
                  
              },
              {
                size: 'xsmall',
                property: 'i',
                header: '',
                render: (entry) => (
                  <Button
                    icon={<Icon size={22} icon='md-edit' />}
                    onClick={() => {
                      navigator.pushPage({
                        view: 'TimeEntry',
                        data: {timeEntry: entry, shift},
                        id: entry.id,
                      })
                    }}
                  />
                ),
              },
            ]}
          />
        </Box>
      </>
    )
  }

  const renderSickEntriesTable = () => {
    return (
      <>
        <SectionTitle text='Sick Time' />
        <Box {...spacing}>
          <DataTable
            disabled = {shiftDisabled}
            data={_.orderBy(shift.timeEntries, 'i', 'asc')}
            primaryKey='id'
            resizeable={false}
            sortable={false}
            pad='none'
            columns={[
              {
                size: 'xlarge',
                property: 'payTypeCode',
                header: 'Pay Type',
                render: (entry) => {
                  
                  //return <MemorizedSelectSick selectType = "SICK" options = {sickPayTypes} disabled={shiftDisabled} timeEntry={entry} update={updateEntry} />
                  
                  if (entry.payTypeCode !== '380') {
                    //-good return <MemorizedSelectPayTypes selectType = "SICK" disabled={shiftDisabled} timeEntry={entry} update={updateEntry} />
                    //return <MemorizedSelectPayTypesAlt selectType = "SICK" disabled={shiftDisabled} timeEntry={entry} update={updateEntry} />

                    return (
                      <Box pad='none' fill={true} direction='row'  background = {{color: shiftDisabled ? "#F5F5F5":"white"}}   >
                      { useAlt ?  <MemorizedSelectPayTypesAlt selectType = "SICK" disabled={shiftDisabled} timeEntry={entry} update={updateEntry} />
                      : <MemorizedSelectPayTypes selectType = "SICK" disabled={shiftDisabled} timeEntry={entry} update={updateEntry} /> }
                      </Box>
                    )

                  } 
                 

                  const lunchBoxStyle = {
                    color: '#A7A7A7',
                    background: '#F5F5F5',
                    pointerEvents: "none",
                  }
                  const _lunchPayType = store.get("PayTypes").filter(r => r.code === '380')[0]

                  return (
                    <Box 
                      style={ lunchBoxStyle } 
                      //ref={ tirggerRef }
                      direction='row' 
                      fill={true} 
                      pad="small" 
                      background = {{color: "white"}}   
                    >  
                     <div>
                       { `${_lunchPayType.code} - ${_lunchPayType.rename ? _lunchPayType.rename : _lunchPayType.description }` }
                     </div>
                 </Box>
                  )

                  
                },
              },
              {
                size: 'small',
                property: 'duration',
                header: 'Hours',
                //render: (entry) => <Duration disabled={shiftDisabled || entry.payTypeCode === '380'} timeEntry={entry} />,
                //render: (entry) => <SelectHoursMinutes disabled={shiftDisabled || entry.payTypeCode === '380'} timeEntry={entry} handleUpdate={updateEntry} setDuration={setDuration} />,
                render: (entry) => useAlt ? <Duration disabled={shiftDisabled || entry.payTypeCode === '380'}timeEntry={entry} /> 
                :
                   <SelectHoursMinutes disabled={shiftDisabled || entry.payTypeCode === '380'} timeEntry={entry} handleUpdate={updateEntry} setDuration={setDuration} />,

              },
              {
                size: 'xsmall',
                property: 'i',
                header: '',
                render: (entry) => (
                  <Button
                    icon={<Icon size={22} icon='md-edit' />}
                    onClick={() => {
                      navigator.pushPage({
                        // view: 'LeaveEntry',
                        view: 'TimeEntry',
                        data: {timeEntry: entry, shift},
                        id: entry.id,
                      })
                    }}
                  />
                ),
              },
            ]}
          />
        </Box>
      </>
    )
  }

  const renderLeaveEntriesTable = () => {
    return (
      <>
        <SectionTitle text='Leave Distribution' />
        <Box {...spacing}>
          <DataTable
            disabled = {shiftDisabled}
            data={_.orderBy(shift.timeEntries, 'i', 'asc')}
            primaryKey='id'
            resizeable={false}
            sortable={false}
            pad='none'
            columns={[
              {
                size: 'xlarge',
                property: 'payTypeCode',
                header: 'Pay Type',
                render: (entry) => {

                  if (entry.payTypeCode !== '380') {
                    //-good return <MemorizedSelectPayTypes selectType = "LEAVE"  disabled={shiftDisabled} timeEntry={entry} update={updateEntry} />
                    //-good return <MemorizedSelectPayTypesAlt selectType = "LEAVE" disabled={shiftDisabled} timeEntry={entry} update={updateEntry} />

                    //return <MemorizedSelectSick selectType = "LEAVE" options = {leavePayTypes} disabled={shiftDisabled} timeEntry={entry} update={updateEntry} />

                    return (
                      <Box pad='none' fill={true} direction='row'  background = {{color: shiftDisabled ? "#F5F5F5":"white"}}   >
                      { useAlt ?  <MemorizedSelectPayTypesAlt selectType = "LEAVE" disabled={shiftDisabled} timeEntry={entry} update={updateEntry} />
                      : <MemorizedSelectPayTypes selectType = "LEAVE" disabled={shiftDisabled} timeEntry={entry} update={updateEntry} /> }
                      </Box>
                    )

                  }
                  
                  const lunchBoxStyle = {
                    color: '#A7A7A7',
                    background: '#F5F5F5',
                    pointerEvents: "none",
                  }
                  const _lunchPayType = store.get("PayTypes").filter(r => r.code === '380')[0]

                  return (
                    <Box 
                      style={ lunchBoxStyle } 
                      //ref={ tirggerRef }
                      direction='row' 
                      fill={true} 
                      pad="small" 
                      background = {{color: "white"}}   
                    >  
                      <div>
                        { `${_lunchPayType.code} - ${_lunchPayType.rename ? _lunchPayType.rename : _lunchPayType.description }` }
                      </div>
                    </Box>
                  )

                },
              },
              {
                size: 'small',
                property: 'duration',
                header: 'Hours',
                render: (entry) => useAlt ? <Duration disabled={shiftDisabled || entry.payTypeCode === '380'}timeEntry={entry} /> 
                :
                   <SelectHoursMinutes disabled={shiftDisabled || entry.payTypeCode === '380'} timeEntry={entry} handleUpdate={updateEntry} setDuration={setDuration} />,
                //render: (entry) => <SelectHoursMinutes disabled={shiftDisabled || entry.payTypeCode === '380'} timeEntry={entry} handleUpdate={updateEntry} setDuration={setDuration} />,
              },
              {
                size: 'xsmall',
                property: 'i',
                header: '',
                render: (entry) => (
                  <Button
                    icon={<Icon size={22} icon='md-edit' />}
                    onClick={() => {
                      navigator.pushPage({
                        // view: 'LeaveEntry',
                        view: 'TimeEntry',
                        data: {timeEntry: entry, shift }, //, leavePayTypes},
                        id: entry.id,
                      })
                    }}
                  />
                ),
              },
            ]}
          />
        </Box>
      </>
    )
  }

  const renderBidEntriesTable = () => {
    return (
      <>
        <Box {...spacing}>
          <Box {...centerBox}>
            <Box background='#A8A391' pad='small' fill>
              <Text {...sectionTitleProps}>Bids for shift {shift.bids.length}</Text>
            </Box>
            <DataTable disabled = {shiftDisabled} data={Bids} columns={[{property: 'number'}, {property: 'fullName'}]} />
            {/* <RadioButtonGroup
          name="bidList"
          options={bids.map((e) => e.number)}
          value={shift.employeeNumber}
          onChange={(e) => {
            DEBUG && console.log("BID", e.target)
            updateshift("employeeNumber", e.target.value)
          }}
        /> */}
          </Box>
        </Box>
      </>
    )
  }


  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    let isMounted = true
    if (isMounted) {
      DEBUG && console.log(`fetchShift ${shift.id}`)
      fetchShift()
     // setPullHookState(null)
    } else {
      setIsMounted(true)
    }
   
   return() => { 
    //store.remove("Shifts")
    setIsMounted(false)
    controller.abort();
    DEBUG && console.log('useEffect shifts')

  }
    

  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (isMounted) {
      store.set('Shift', shift)
      setPullHookState(null)
    } else {
      setIsMounted(true)
    }

    return() => { 
      //store.remove("Shifts")
      setIsMounted(false)
      controller.abort();
      DEBUG && console.log('useEffect shifts')
  
    }
   
  }, [shift])


  const pullProps = {
    key: shift.id, 
    id: 'pullHook',
    data: shift,
    pullHookState: pullHookState,
    setPullHookState: setPullHookState,
    onRefresh: fetchShift

  }


  return (
    <Page id='shiftPage' renderToolbar={renderToolbar} renderFixed={rendershiftActionButton}>
      <div>
        <PullToRefresh onRefresh={fetchShift} >
          {/* VIEW TITLE */}
          <Box ref={ref} pad='small' background='#A8A391'>
            <Text color='white' textAlign='center' size='large'>
              {SD} {!!shift.isSick && <b>{`(SICK)`}</b>}
              {!!shift.requestedOff && <b>{`(PTO)`}</b>}
              {!!shift.submit && <b>{`(SUBMITTED)`}</b>}
            </Text>
          </Box>
          {/* shift DETAILS SECTION */}
          <Box full>
            <Table {...TOP}>
              <TableHeader>
                <TableRow>
                  <TableCell key='sth1' {...TH}>
                    Start
                  </TableCell>
                  <TableCell key='sth2' {...TH}>
                    End
                  </TableCell>
                  <TableCell key='sth3' {...TH}>
                    Time
                  </TableCell>
                  {!isAvailable && (
                    <TableCell key='sth4' {...TH}>
                      OT
                    </TableCell>
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell {...TD}>
                    <TimeBox disabled={cantEditshift || shiftDisabled} value={ST} field='start' />
                  </TableCell>
                  <TableCell {...TD}>
                    <TimeBox disabled={cantEditshift || shiftDisabled} value={ET} field='end' />
                  </TableCell>
                  <TableCell {...TD}>
                    <TimeBox disabled={cantEditshift || shiftDisabled } value={DUR} field='hrs' />
                  </TableCell>
                  {!isAvailable && (
                    <TableCell {...TD}>
                      <TimeBox disabled={cantEditshift || shiftDisabled } value={OT} field='ot' />
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
            <Box gap='medium'>
              {isAvailable && (
                <Table>
                  <TableRow>
                    <TableCell {...LABEL}>Bids</TableCell>
                    <TableCell {...INPUT}>{bids.size}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell {...LABEL}>Supervisor</TableCell>
                    <TableCell {...INPUT}>{shift.supervisor1.fullName}</TableCell>
                  </TableRow>
                </Table>
              )}
              {/* TIME ENTRIES SECTION */}
              {isRegular && renderTimeEntriesTable()}
              {isLeave && renderLeaveEntriesTable()}
              {isSick && renderSickEntriesTable()}
              {isAvailable && canSeeBids && renderBidEntriesTable()}
              {!isAvailable && renderTotal()}
            </Box>
            <Box pad='medium' gap='small'>
              {!!shift.isOT && (
                <Box>Overtime Reason
                  {/* <MemorizedSelectReason disabled = {shiftDisabled} shift={shift} update={update} setToast ={setToast}/> */}
                  { !useAlt && <MemorizedSelectReason disabled = {shiftDisabled} shift={shift} update={update} setToast ={setToast} /> }
                  {  useAlt && <MemorizedSelectReasonAlt disabled = {shiftDisabled} shift={shift} update={update} setToast ={setToast} />}
 
                </Box>
              )}
              <Box> Assignment
               { !useAlt && <SelectAssignment disabled = {shiftDisabled} shift={shift} update={update} setToast ={setToast} /> }
               { useAlt &&  <MemorizedSelectAssignmentAlt disabled = {shiftDisabled} shift={shift} update={update} setToast ={setToast} />}

                {/* <MemorizedSelectAssignmentAlt disabled = {shiftDisabled} shift={shift} update={update} setToast ={setToast} />
                <SelectAssignment disabled = {shiftDisabled} shift={shift} update={update} setToast ={setToast} />  */}
              </Box>
              <Box>
                <TextArea style={{background: '#fff'}} disabled={ cantEditshift } defaultValue={shift.notes} name='notes' placeholder={shift.isOT? 'Required Notes' :'Optional Notes'} onBlur={updateshift} onChange={(e) => setShift({...shift, notes: e.target.value})} />
              </Box>
            </Box>
          </Box>

      </PullToRefresh>
      </div>
      {/* shift ACTIONS */}
      <ActionSheet isOpen={open.actions}>
        <ActionSheetButton className='ActionSheetTitle'>
          <b>Shift Actions</b>
        </ActionSheetButton>
        {/* Leave */}
        <ActionSheetButton
          modifier={!canRequestOff || shift.submit ? 'disabled' : ''}
          onClick={() => {
            if (canRequestOff && !shift.submit) {
              updateshift({
                field: 'requestedOff',
                value: !shift.requestedOff,
              })
            }
          }}
        >
          {shift.requestedOff ? 'Un Request Off' : 'Request Off'}
        </ActionSheetButton>
        {/* Sick */}
        <ActionSheetButton
          modifier={!canCallInSick || shift.submit ? 'disabled' : ''}
          onClick={() => {
            if (canCallInSick && !shift.submit) {
              updateshift({field: 'isSick', value: !shift.isSick})
            }
          }}
        >
          {shift.isSick ? 'Not Sick' : 'Sick'}
        </ActionSheetButton>
        {/* Delete */}
        <ActionSheetButton
          modifier={!canDeleteShift ? 'disabled' : 'destructive'}
          onClick={() => {
            if (canDeleteShift) {
              deleteShift({field: 'id', value: shift.id})
            }
          }}
        >
          Delete
        </ActionSheetButton>

        {canApprove && <ActionSheetButton onClick={() => updateshift({field: 'approved', value: !shift.approved})}>{shift.approved ? 'Un Approve' : 'Approve'}</ActionSheetButton>}
        {canEditshift && <ActionSheetButton 
          modifier={!canSubmit || !canSubmitOT ? 'disabled' : ''} 
          onClick={() => {
            if (canSubmit && canSubmitOT) {
              updateshift({field: 'submit', value: !shift.submit})
            }
          }}
          >{shift.submit ? 'Un Submit' : 'Submit'}</ActionSheetButton>}
        {canApprove && (
          <ActionSheetButton
            modifier={'destructive'}
            onClick={() => {
              DEBUG && console.log(!shift.rejected)
              updateshift({
                field: 'rejected',
                value: !shift.rejected,
              })
            }}
          >
            {shift.rejected ? 'Un Reject' : 'Reject'}
          </ActionSheetButton>
        )}
       
        {/* shift ACTIONS */}
        <ActionSheetButton className='ActionSheetTitle'>
          <b>Time Entry Actions</b>
        </ActionSheetButton>
        <ActionSheetButton
            modifier={'destructive'}
            onClick={addEntry}
          >
            Add a Time Entry
          </ActionSheetButton>

         {/* Cancel */}  
        <ActionSheetButton icon={'md-close'} onClick={() => toggleOpen()}>
          Cancel
        </ActionSheetButton>
      </ActionSheet>

      {/* SAVE INDICATOR */}
      <Toast isOpen={toast.open} animation="fall">
        <div style={{textAlign: 'center'}}>{toast.text}</div>
      </Toast>
      {/* START TIME PICKER */}
      {open.start && <TimePicker shift={shift} field='start' {...timeProps} />}
      {open.end && <TimePicker shift={shift} field='end' {...timeProps} />}
      {/* END TIME PICKER */}

      {open.payType && <PayType timeEntry={timeEntry} handleUpdate={updateEntry} close={() => toggleOpen('payType')} />}

      <Modal animation='lift' isOpen={duration.open} style={{display: "none", position: 'fixed', top: 'auto', right: 'auto', left: 'auto', bottom: 0}}>
        <SelectHoursMinutesAlt disabled = {shiftDisabled} timeEntry={duration.timeEntry} handleUpdate={updateEntry} setDuration={setDuration} />
      </Modal>
     {/*  <Modal isOpen={reasonV2.open}>
        <SelectReasonV2 disabled = {shiftDisabled} shift={shift} update={update} setToast ={setToast} setReasonV2={setReasonV2}/>
      </Modal> */}
    </Page>
  )
}

export default Shift

