import React, {useState} from 'react'
import {useAbilityContext} from '../useAbilityContext'
import {Toolbar, BackButton} from 'react-onsenui'
import {Page, List, ListItem} from 'react-onsenui'
import {Box, Text, CheckBox} from 'grommet'
import {currentUser, store, fetch, a, an} from '../psrs'
import {MaskedInput, TextInput} from 'grommet'
import {MailOption, Phone, DocumentTime, DocumentUser} from 'grommet-icons'
import {FormSchedule, StatusGood, Schedules, Edit} from 'grommet-icons'
import {Tag} from '../components'
import _ from 'lodash'

import PullToRefresh from 'react-simple-pull-to-refresh';

const DEBUG = false

export const Employee = (props) => {
  DEBUG && console.log('EMPLOYEE.JS VIEW')
  const {route} = props
  const currUser = store.get("CurrentUser")
  const Employee = route.employee || currUser //|| currentUser
  const [employee, setEmployee] = useState(Employee)

  const x = _.get(Employee, 'settings', {})

  const [settings, setSettings] = useState({
    ..._.get(employee, 'settings', {}), //employee.settings,
    phone1: _.get(employee, 'phone', '') ?
        _.get(employee, 'phone', '').replace("+1", "").replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      : '', //employee.phone,
    phone2: _.get(employee, 'phone2', '') ? 
        _.get(employee, 'phone2', '').replace("+1", "").replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      : '',
    //_.get(employee, 'phone2', ''), //employee.phone,
    email1: _.get(employee, 'email', ''), //employee.email,
    email2: _.get(employee, 'email2', ''), //employee.email,
  })
  const ability = useAbilityContext()
  const cantEdit = ability.cannot('edit', an('Employee', employee))
  const notMySettings = _.get(employee, 'number', '') !== currUser.number //employee.number !== currentUser.number

  const pageTitle = () => (
    <Toolbar>
      <div className='left'>
        <BackButton>Back</BackButton>
      </div>
      {/* <div className='center'>{notMySettings ? <Tag>{employee.jobTitle}</Tag> : 'Settings'}</div> */}
      <div className='center'>{notMySettings ? <Tag>{_.get(employee, 'jobTitle','')}</Tag> : 'Settings'}</div>
    </Toolbar>
  )

  const updateEmployee = async ({field, value}) => {
    const update = {}
    update[field] = value
    DEBUG && console.log('updateEmployee > updated', update)
    setEmployee({...employee, ...update})
    const updated = await fetch(`/api/employees/${employee.number}`, {method: 'PATCH', body: JSON.stringify(update)})
    setEmployee(updated)
 

    const updatedEmployeeCache = store.get("Employees").find(e => e.number === updated.number)
    const emps = store.get("Employees")
    const empIndex = emps.findIndex((e => e.number == updated.number))

    updatedEmployeeCache.email2 = updated.email2
    updatedEmployeeCache.phone2 = updated.phone2

    //const updatedCache = 
    emps[empIndex]=updatedEmployeeCache

    store.set("Employees", emps)
    if (updated.number === store.get("CurrentUser").number) {
      store.set("CurrentUser", updated)
      store.set("CurrentUser", updated)
    }

    // mutate(key, updated)
  }

  const updateSettings = async (event) => {
    const field = event.target.name
    const checked = event.target.checked
    const val = event.target.value
    const value = field.includes('send') ? checked : val
    const _settings = employee.settings
    _settings[field] = value
    DEBUG && console.log('updateSettings', _settings)
    const updated = await fetch(`/api/employee/settings/${employee.number}`, {method: 'PATCH', body: JSON.stringify({settings: _settings})})

    const updatedEmployeeCache = store.get("Employees").find(e => e.number === updated.number)
    const emps = store.get("Employees")
    const empIndex = emps.findIndex((e => e.number == updated.number))

    
    updatedEmployeeCache.email2 = updated.email2
    updatedEmployeeCache.phone2 = updated.phone2
    updatedEmployeeCache.settings.sendToEmail1 = updated.settings.sendToEmail1
    updatedEmployeeCache.settings.sendToEmail2 = updated.settings.sendToEmail2
    updatedEmployeeCache.settings.sendToPhone1 = updated.settings.sendToPhone1
    updatedEmployeeCache.settings.sendToPhone2 = updated.settings.sendToPhone2
    updatedEmployeeCache.settings.mobileEnabled = updated.settings.mobileEnabled

  
    emps[empIndex]=updatedEmployeeCache
    store.set("Employees", emps)

    setEmployee(updated)
    setSettings({...settings, ...updated.settings})
    if (store.get("CurrentUser").number === updated.number) {
      store.set('CurrentUser', updated)
      store.set('CurrentUser', updated)
    }

  }

  const viewShifts = async () => {
    const shifts = await fetch(`/api/employee/${employee.number}/shifts/${store.get('PayPeriod').id}`)
    DEBUG && console.log(shifts)
    store.set('Shifts', shifts)
    props.navigator.pushPage({
      backButton: true,
      view: 'EmpShifts',
      title: 'Shifts',
      data: {shifts, employee},
    })
  }

  const viewApprovals = async () => {
    const shifts = await fetch(`/api/mobile/my_approvals/shift/${employee.number}`)
    const ordered = _.orderBy(shifts, ['start', 'employeeNumber'], 'asc')
    DEBUG && console.log(shifts)
    store.set('Shifts', shifts)
    props.navigator.pushPage({
      backButton: true,
      view: 'EmpApprovals',
      title: 'Approvals',
      data: {shifts, employee},
    })
  }

  const phoneMask = [
    {fixed: '('}, 
    {
      length: 3, 
      regexp: /^[0-9]{1,3}$/, 
      placeholder: 'xxx'
    }, 
    {fixed: ')'}, 
    {fixed: ' '}, 
    {
      length: 3, 
      regexp: /^[0-9]{1,3}$/, 
      placeholder: 'xxx'
    }, 
    {fixed: '-'}, 
    {
      length: 4, 
      regexp: /^[0-9]{1,4}$/, 
      placeholder: 'xxxx'
    }
  ]

  return (
    <Page id='EmployeePage' renderToolbar={pageTitle}>
      <PullToRefresh onRefresh={()=>window.location.reload(false)}>
      
      {!!notMySettings && (
        <Box fill='horizontal' pad='medium'>
          <Text textAlign='center' size='large'>
            {employee.fullName}
          </Text>
        </Box>
      )}
      <List>
        {notMySettings && (
          <ListItem modifier='chevron' tappable onClick={viewShifts}>
            <Box fill='horizontal' direction='row' align='center'>
              <Box>
                <Schedules color='plain' size='large' />
              </Box>
              <Box margin={{left: 'large'}}>
                <Text size='large'>View Shifts</Text>
              </Box>
            </Box>
          </ListItem>
        )}
        {notMySettings && (
          <ListItem modifier='chevron' tappable onClick={viewApprovals}>
            <Box fill='horizontal' direction='row' align='center'>
              <Box>
                <Edit color='plain' size='large' />
              </Box>
              <Box margin={{left: 'large'}}>
                <Text size='large'> View Approvals</Text>
              </Box>
            </Box>
          </ListItem>
        )}

        {/* PHONE 2 */}
        <ListItem modifier='longdivider'>
          <Box fill='horizontal'>
            <h4>Secondary Phone</h4>
            <MaskedInput
              //mask={phoneMask}
              mask={[
                { fixed: "(" },
                {
                  length: 3,
                  regexp: /^[0-9]{1,3}$/,
                  placeholder: 123
                },
                { fixed: ")" },
                { fixed: " " },
                {
                  length: 3,
                  regexp: /^[0-9]{1,3}$/,
                  placeholder: 456
                },
                { fixed: "-" },
                {
                  length: 4,
                  regexp: /^[0-9]{1,4}$/,
                  placeholder: 7890
                }
              ]}
              placeholder='secondary phone'
              name='phone2'
              value={ settings.phone2 || ''}
              icon={<Phone />}
              onChange =  {event => setSettings({...settings, phone2: event.target.value})}
              onBlur={(e) => {
                updateEmployee({field: 'phone2', value: e.target.value})
              }}
            />
          </Box>
          <Box pad={{vertical: 'medium'}} fill='horizontal'>
            <CheckBox 
              disabled={!employee.phone2 } 
              label='Receive SMS Notifications?' 
              name='sendToPhone2' 
              checked={settings.sendToPhone2} 
              onChange={updateSettings} 
              toggle={true}
            />
          </Box>
        </ListItem>

        {/* EMAIL 2 */}
        <ListItem modifier='longdivider'>
          <Box fill='horizontal'>
            <h4>Secondary Email</h4>
            <TextInput
              placeholder='secondary email'
              name='email2'
              defaultValue={employee.email2 || ''}
              icon={<MailOption />}
              onBlur={(e) => {
                updateEmployee({field: 'email2', value: e.target.value})
              }}
            />
          </Box>
          <Box pad={{vertical: 'medium'}} fill='horizontal'>
            <CheckBox 
                disabled={!employee.email2} 
                label='Receive email Notifications?' 
                name='sendToEmail2' 
                checked={settings.sendToEmail2} 
                toggle= {true}
                onChange={updateSettings} 
              />
          </Box>
        </ListItem>

        {/* PHONE 1 */}
        <ListItem modifier='longdivider'>
          <Box fill='horizontal'>
            <h4>Oracle Phone</h4>
            <MaskedInput 
              disabled={true} 
              mask={phoneMask} 
              placeholder='No Primary Phone' 
              name='phone' 
              icon={<Phone />} 
              value={settings.phone1 || ''} />
          </Box>
          <Box pad={{vertical: 'medium'}} fill='horizontal'>
            <CheckBox
              disabled={!employee.phone}
              label='Receive SMS Notifications?' 
              name='sendToPhone1' 
              checked={settings.sendToPhone1} 
              onChange={updateSettings}
              toggle={true}
            />
          </Box>
        </ListItem>

        {/* EMAIL 1 (PRIMARY) */}
        <ListItem modifier='longdivider'>
          <Box fill='horizontal'>
            <h4>Oracle Email</h4>
            <TextInput disabled={true} name='email' placeholder='primary email' icon={<MailOption />} value={employee.email || ''} />
          </Box>
          <Box pad={{vertical: 'medium'}} fill='horizontal'>
            <CheckBox 
              label='Receive email Notifications?' 
              name='sendToEmail1' 
              checked={settings.sendToEmail1} 
              onChange={updateSettings}
              toggle={true}
            />
          </Box>
        </ListItem>
      </List>
      </PullToRefresh>
    </Page>
  )
}

export default Employee
