import React, {useState, useEffect, useRef} from 'react'
import {Select, CaretIcon, ModalCloseButton} from 'react-responsive-select'
import {store, truncString} from '../psrs'
import {Box} from 'grommet'
import _ from 'lodash'
import MobileSelect from "mobile-select"

// for default styles...
import "react-responsive-select/dist/react-responsive-select.css"

const DEBUG = false 

export const SelectPayTypes = (props) => {
  const {timeEntry, update, selectType} = props
  const {payTypeCode, id} = timeEntry

  const category = selectType //.toUpperCase()
  const paytypeLabel = category ? category[0].toUpperCase() + category.toLowerCase().slice(1) : "Select"

  


  let Records = store.get('PayTypes').filter((r) => !!r.included || r.payTypeCode !== '380')
  if ( category && category !== 'REGULAR') {
    Records = store.get('PayTypes').filter((r) => !!r.included && r.category === category ) //|| r.code === '380')
  }
  
  //const Records = store.get('PayTypes').filter((r) => !!r.included && r.category === 'LEAVE' || r.code === '380')
  
  const options = _.sortBy(Records.map((r) => ({sort: parseInt(r.code), value: r.code, text: `${r.code} - ${truncString(r.rename,20)}`, markup: <small>{r.code} - {r.rename}</small>, disabled: r.code === '380' ? true : false})), ['sort'])

  //const xwheelData =_.sortBy(Records.map((r) => ({sort: parseInt(r.code), id: parseInt(r.code), value: `${r.code} - ${truncString(r.rename,20)}`, title: `${r.code} - ${r.rename}`, disabled: r.code === '380' ? true : false})), ['sort'])

  const wheelData =_.sortBy(Records.map((r) => ({sort: parseInt(r.code), id: parseInt(r.code), value: `${r.code} - ${r.rename ? r.rename : r.description}`, code: r.code })), ['sort'])


  //const options = _.sortBy(Records.map((r) => ({sort: parseInt(r.code), value: r.code, text: `${r.code} - ${truncString(r.rename ? r.rename : r.description,20)}`, disabled: r.code === '380' ? true : false})), ['sort'])

  //- original const Records = store.get('PayTypes').filter((r) => !!r.included)
  //- original const options = _.sortBy(Records.map((r) => ({sort: parseInt(r.code), value: r.code, text: `${r.code} - ${r.description}`, disabled: r.code === '380' ? true : false })), ['sort'])
 
  const disabled = props.disabled
  //const disabled = props.disabled || (shift.shiftTemplateId !== null && !shift.shiftTemplate.canEdit)

  options.unshift({value: 'cancel', text: 'CANCEL'})

  const [selected, setSelected] = useState(payTypeCode || undefined)
 

  const handleChange = (props) => {

    //{name, text, value, altered}
    const name = "payTypeCode"
    const text = ""
    const value = props[0].code
    const altered = true
    

    if (value === 'cancel' || value === '380') {
      setSelected(payTypeCode || undefined)
    } else if (value !== 'cancel' && !!altered && value !== '380') {
      setSelected(value)
      update({field: 'payTypeCode', id: timeEntry.id, value})
    } 
  }

  const handleSubmit = (value) => {
    console.log('SELECT onSubmit', value)
  }


  /** test */
  const tirggerRef = useRef(null);

  //const initval = []
  const tePT = wheelData.filter( o =>  o.sort === parseInt(timeEntry.payTypeCode))
  
  //initval.push(tePT[0].value)

  DEBUG && console.log('tePT => ', tePT[0])
  DEBUG && console.log('timeEntry.payTypeCode => ', timeEntry.payTypeCode)
  DEBUG && console.log('options => ', options)

  const [selectedVal, setSelectedVal] = useState(tePT[0]?.value || 'Please select ' )
  
  let msInstance = null;
  DEBUG && console.log('selectedVal => ', selectedVal)
  useEffect(() => {
    if(!msInstance){
      msInstance = new MobileSelect({
          wheels: [
            { 
              data: wheelData
            }, 
            
        ],
          title:  `${paytypeLabel} Pay Types`,
          //position: [10],
          textColor: "#000000",
          bgColor: "#FFFFFF",
          ensureBtnText: "Select",
          cancelBtnText: "Cancel",
          trigger: tirggerRef.current,
          triggerDisplayValue: true, // If you don't want to overwrite the HTML inside the trigger, you need to set this to false
          onChange: (data) => {
            DEBUG && console.log(data);
            setSelectedVal(JSON.stringify(data))
            handleChange(data)

          },
        });

        const ii = wheelData.findIndex( i => i.id === parseInt(timeEntry.payTypeCode))
        msInstance?.locatePosition(0,wheelData.findIndex( i => i.id === parseInt(timeEntry.payTypeCode)))
    }
    
    return () => {
      msInstance?.destroy();  // Destroying instance
    };
  }, []);


  const isLunch = timeEntry.payTypeCode === '380' ? true : false
  const boxStyle = {
    color: (isLunch || disabled) ? '#A7A7A7' : 'black',
    background: (isLunch || disabled) ? '#F5F5F5' : 'white',
    pointerEvents: (isLunch || disabled) ? "none" : "",
  }

  return (
    <Box pad='small'>
      {/*<div className="ms-default-trigger" ref={ tirggerRef }>*/}
      <Box  
         //border={{size: ''}}
         style={ boxStyle } 
         ref={ tirggerRef }
         direction='row' 
         fill={true} 
         pad="none" 
         background = {{color: "white"}}   
      >  
          <div>
            {  truncString(selectedVal, 25) || 'Please select' }
          </div>
      </Box>
    </Box>
  );
 
   /* end */

  return (
    <Select   
      disabled={disabled || timeEntry.payTypeCode === '380'} 
      name='payTypeCode' 
      //prefix='Pay Type: ' 
      noSelectionLabel='Please select' 
      modalCloseButton={<ModalCloseButton />} 
      options={options} 
      caretIcon={<CaretIcon />} 
      selectedValue={selected} 
      onChange={handleChange} 
      //onBlur={handleChange}
      onSubmit={handleSubmit} 
    />
  )
}
