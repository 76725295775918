import React, {useState, useEffect, useRef} from 'react'
//import Picker from 'react-mobile-picker'
import MobileSelect from "mobile-select";
//import {Box, Button} from 'grommet'
import {Box, } from 'grommet'
import TimeFormat from 'hh-mm-ss'
//import {truncString, convertDuration, ConvertDurationToHM} from "../psrs"
import { convertDuration, ConvertDurationToHM } from "../psrs"


const DEBUG = false

export const SelectHoursMinutes = (props) => {
  //const timeEntry = props.timeEntry
  const { timeEntry, disabled } = props

  if (timeEntry === null) return false
  //const {id, duration, payTypeCode} = timeEntry

  const handleUpdate = props.handleUpdate
  const currentValue = props.timeEntry && props.timeEntry.duration ? props.timeEntry.duration  : 0
  const [hours, setHours] = useState(currentValue)
  const seconds = currentValue * 60 * 60
  const HHMM = TimeFormat.fromS(seconds, 'hh:mm')
  const HM = HHMM.split(':')
  const H = HM[0]
  const M = HM[1]
  const [valueGroups, setValueGroups] = useState({hours: H, minutes: M})

  const optionGroups = {
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16'],
    minutes: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
  }

  const wheelData = [
    {
      data: [
        { id: "00", value: "00" },
        { id: "01", value: "01" },
        { id: "02", value: "02" },
        { id: "03", value: "03" },
        { id: "04", value: "04" },
        { id: "05", value: "05" },
        { id: "06", value: "06" },
        { id: "07", value: "07" },
        { id: "08", value: "08" },
        { id: "09", value: "09" },
        { id: "10", value: "10" },
        { id: "11", value: "11" },
        { id: "12", value: "12" },
        { id: "13", value: "13" },
        { id: "14", value: "14" },
        { id: "15", value: "15" },
        { id: "16", value: "16" }
      ]
    },
    {
      data: [
        { id: "00", value: "00" },
        { id: "06", value: "06" },
        { id: "12", value: "12" },
        { id: "18", value: "18" },
        { id: "24", value: "24" },
        { id: "30", value: "30" },
        { id: "36", value: "36" },
        { id: "42", value: "42" },
        { id: "48", value: "48" },
        { id: "54", value: "54" },
        
      ] 
    }, 
  ]

  const handleChange = (data) => {

    const realHrs = convertDuration(data[0].value, data[1].value)

    DEBUG && console.log('handleCahnge')
    setSelectedVal(parseFloat(realHrs).toFixed(2))
    handleUpdate({field: 'duration', id: timeEntry.id, value: parseFloat(realHrs).toFixed(2) })
    timeEntry.duration = parseFloat(realHrs)
  }

  const updateHours = (data) => {

    let hm = '00:00'
    if (data) {
      hm = `${data[0]?.value}:${data[1]?.value}`
    } 

    const secs = TimeFormat.toS(hm, 'hh:mm')
    const hours = secs / 60 / 60
    DEBUG && console.log('updateDuration', hours)
    //setHours(hours.toPrecision(4))
    setHours(hours.toFixed(2))
  }

  // start new 
  const tirggerRef = useRef(null)
  const fixDuration = parseFloat(timeEntry?.duration || '00.00')

  // convert here?
  const durationHM = ConvertDurationToHM(fixDuration)

  const wheelInit1 = wheelData[0].data.filter( i => i.id === durationHM?.toString()?.split(':')[0]?.padStart(2,'0'))
  const wheelInit2 = wheelData[1].data.filter( i => i.id === durationHM?.toString()?.split(':')[1]?.padStart(2,'0'))


  if (wheelInit2.length === 0) {
    const realHours = {
      id: fixDuration.toFixed(2)?.toString()?.split('.')[1]?.padStart(2,'0'),
      value: fixDuration.toFixed(2)?.toString()?.split('.')[1]?.padStart(2,'0'),
     }
     
     const durationToHM = ConvertDurationToHM(fixDuration.toFixed(2))

  }

  let wheelInit = `${wheelInit1[0]?.value}.${wheelInit2[0]?.value}`
  
  if (wheelInit === 'undefined.undefined') wheelInit = '00.00'
  
  const [selectedVal, setSelectedVal] = useState(wheelInit) 
  
  let msInstance = null;
  DEBUG && console.log('selectedVal => ', selectedVal)

  useEffect(() => {
    if(!msInstance){
      msInstance = new MobileSelect({
          wheels: wheelData,
          title:  "Hours | Minutes",
          //position: [10],
          connector: '.',
          textColor: "#000000",
          bgColor: "#FFFFFF",
          ensureBtnText: "Save",
          cancelBtnText: "Cancel",
          trigger: tirggerRef.current,
          triggerDisplayValue: true, // If you don't want to overwrite the HTML inside the trigger, you need to set this to false
          onChange: (data) => {
            //console.log(data);
            //setSelectedVal(JSON.stringify(data))
            handleChange(data)
            //updateHours(data)

          },
        });

        const fixDuration = parseFloat(timeEntry?.duration || '00.00')
           
        const durationToHM2 = ConvertDurationToHM(fixDuration.toFixed(2))
        const wheelIndex1 = wheelData[0]?.data?.findIndex( i => i.id === durationToHM2?.toString()?.split(':')[0]?.padStart(2,'0'))
        const wheelIndex2 = wheelData[1]?.data?.findIndex( i => i.id === durationToHM2?.toString()?.split(':')[1]?.padStart(2,'0'))


        msInstance?.locatePosition(0,wheelIndex1 !== -1 ? wheelIndex1 : 0) 
        msInstance?.locatePosition(1,wheelIndex2 !== -1 ? wheelIndex2 : 0)

    }
    
    return () => {
      msInstance?.destroy();  // Destroying instance
    };
  }, []);

  const isLunch = timeEntry.payTypeCode === '380' ? true : false
  const boxStyle = {
    color:  (isLunch || disabled) ? '#A7A7A7' : 'black',
    background:  (isLunch || disabled) ? '#F5F5F5' : 'white',
    pointerEvents:  (isLunch || disabled) ? "none" : "",
    fill: true,
    width: 'xsmall',
    display: 'flex', 
    justifyContent: 'space-around',
    pad: 'medium',

  }
  
  const centerBox = {
    fill: true,
    align: 'center',
    alignContent: 'stretch',
    justify: 'center',
    width: 'xsmall',
    pad: 'none',
  }

  const startBox = {
    align: "start",
    alignContent: 'stretch',
    justify: 'center',
    textAlign: 'start',
    hoverIndicator: true,
    round: 'small',
    pad: 'small',
    wrap: false,
    border: true,
  }

  // do we need this
  useEffect(() => {
    updateHours()
  }, [valueGroups])

  useEffect(() => {
    if (timeEntry) {
      setValueGroups({hours: H, minutes: M})
      setHours(timeEntry ? timeEntry.duration : 0)
    }
  }, [timeEntry])
  // stop here

   
  return (  
  <Box  {...centerBox}>
    <Box 
        style={ boxStyle }
        disabled = {disabled || timeEntry.payTypeCode === '380'}
        ref={ tirggerRef }
        direction='row' 
        fill={true} pad="small" 
        background = {{color: "white"}}   
    >
        {/* { selectedVal || ' 0 00' } */}
        {timeEntry.duration || " 0.00" } 
    </Box>

  </Box>  
  )
  // end new
}

export default SelectHoursMinutes
