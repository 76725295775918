const color = {
  dark: "#001427",
  light: "#0094C6",
  red: "#FB3640",
  yellow: "#FAA916",
  green: "#4D8B31",
  blue: "#0094C6",
};

export const theme = {
  name: "psrs",
  rounding: 2,
  spacing: 24,
  defaultMode: "light",
  select: {
    container: {
      extend: {
        // position: "absolute",
        height: "100vh",
        width: "100vw",
        top: "0px",
        left: "0px",
      },
    },
    options: {
      container: {
        basis: "full",
      },
    },
  },
  global: {
    colors: {
      activeBackground: "background-contrast",
      activeText: "text-strong",
      background: { dark: "#111111", light: "#FFFFFF" },
      backgroundBack: { dark: "#111111", light: "#EEEEEE" },
      backgroundContrast: { dark: "#FFFFFF11", light: "#11111111" },
      backgroundFront: { dark: "#222222", light: "#FFFFFF" },
      blue: "#0094C6",
      border: { dark: "#444444", light: "#CCCCCC" },
      brand: { dark: color.dark, light: color.light },
      control: "brand",
      dark: "#001427",
      graph0: { light: "brand", dark: "#001427" },
      graph1: color.yellow,
      green: "#4D8B31",
      light: "#0094C6",
      magenta: "magenta",
      red: "#FB3640",
      selectedBackground: "brand",
      selectedText: "text-strong",
      statusCritical: "#FB3640",
      statusDisabled: "#CCCCCC",
      statusOk: "#4D8B31",
      statusUnknown: "#CCCCCC",
      statusWarning: "#FAA916",
      text: { dark: "#EEEEEE", light: "#333333" },
      textStrong: { dark: "#FFFFFF", light: "#000000" },
      textWeak: { dark: "#CCCCCC", light: "#444444" },
      textXweak: { dark: "#999999", light: "#666666" },
      yellow: "#FAA916",
    },
    font: {
      family: "Roboto, Helvetica Neue, Arial, sans-serif",
      size: "16px",
      height: "24px",
      maxWidth: "432px",
    },
    active: { background: "active-background", color: "active-text" },
    hover: { background: "active-background", color: "active-text" },
    selected: {
      background: "selected-background",
      color: "selected-text",
    },
    borderSize: {
      xsmall: "1px",
      small: "2px",
      medium: "4px",
      large: "12px",
      xlarge: "24px",
    },
    input: { padding: "12px", weight: 600 },
    spacing: "24px",
    size: {
      xxsmall: "48px",
      xsmall: "96px",
      small: "192px",
      medium: "384px",
      large: "768px",
      xlarge: "1152px",
      xxlarge: "1536px",
      full: "100%",
    },
    control: { border: { radius: "2px" } },
    drop: { border: { radius: "2px" } },
  },
  button: {
    border: { width: "2px", radius: "2px" },
    padding: { vertical: "4px", horizontal: "22px" },
  },
  calendar: {
    small: { fontSize: "17.2px", lineHeight: 1.375, daySize: "27.43px" },
    medium: { fontSize: "18px", lineHeight: 1.45, daySize: "54.86px" },
    large: { fontSize: "20.4px", lineHeight: 1.11, daySize: "109.71px" },
  },
  checkBox: {
    size: "24px",
    toggle: { radius: "2px", size: "48px" },
    check: { radius: "2px" },
  },
  radioButton: { size: "24px", check: { radius: "2px" } },
  text: {
    xxsmall: { size: "12px", height: "14px", maxWidth: "400px" },
    xsmall: { size: "14px", height: "18px", maxWidth: "413px" },
    small: { size: "16px", height: "20px", maxWidth: "422px" },
    medium: { size: "18px", height: "24px", maxWidth: "432px" },
    large: { size: "19px", height: "25px", maxWidth: "451px" },
    xlarge: { size: "20px", height: "26px", maxWidth: "470px" },
    xxlarge: { size: "21px", height: "27px", maxWidth: "509px" },
  },
  formField: {
    border: {
      color: "border",
      error: { color: { dark: "white", light: color.red } },
      position: "inner",
      side: "bottom",
    },
    content: { pad: "small" },
    disabled: {
      background: { color: "status-disabled", opacity: "medium" },
    },
    error: {
      color: color.red,
      margin: { vertical: "xsmall", horizontal: "small" },
    },
    help: { color: "dark-3", margin: { start: "small" } },
    info: {
      color: "text-xweak",
      margin: { vertical: "xsmall", horizontal: "small" },
    },
    label: { margin: { vertical: "xsmall", horizontal: "small" } },
    margin: { bottom: "small" },
    round: "2px",
  },
};
