// This file should match in both CLIENT & MOBILE
import {store} from './psrs'
import {AbilityBuilder, Ability} from '@casl/ability'
// import { subject as a } from "@casl/ability"
import moment from "moment-timezone"


export const ability = (user) => {
  const User = store.get('CurrentUser')
  const {can, cannot, build} = new AbilityBuilder(Ability)
  if (User) {
    const employeeNumber = User.number.toString()

    // VIEWS
    can('visit:settings')
    can('visit:employees')
    can('visit:icons')
    can('visit:logout')
    can('visit:shifts')


    // submit
    can("submit", "Shift", { end: { $lte: moment().toISOString() } })
    cannot("submit", "Shift", { end: { $gte: moment().subtract(30, "minutes").toISOString() } })
    
    // Evidence disabled
    can("edit:evidence:shift", "Shifts.timeEntries", { payTypeCode: { $ne: ["250","251"]}})
    

    // SHIFT DRAWER
    can('see:shift:timeEntries', 'Shift', {
      employeeNumber: employeeNumber,
      isSick: false,
      requestedOff: false,
    })
    can('see:shift:leaveEntries', 'Shift', {
      employeeNumber: employeeNumber,
      isSick: true,
    })
    can('see:shift:leaveEntries', 'Shift', {
      employeeNumber: employeeNumber,
      requestedOff: true,
    })
    can('see:shift:reasons', 'Shift', {durationOt: {$gt: 0}})
    // can("see:shift:action:submit", "Shift")
    // can("see:shift:action:sick", "Shift")
    // can("see:shift:action:pto", "Shift")
    // can("see:shift:action:approve", "Shift")
    // can("see:shift:action:reject", "Shift")
    // can("see:shift:action:process", "Shift")
    // can("see:shift:action:delete", "Shift")
    // can("see:shift:action:otherShift", "Shift")
    // can("see:shift:action:sickShift", "Shift")

    can('see:row:icon:available', 'Shift', {available: true})
    // can("see:row:icon:approve", "Record", { approved: true })
    // can("see:row:icon:reject", "Record", { rejected: true })
    // can("see:row:icon:sick", "Record", { isSick: true })
    // can("see:row:icon:pto", "Record", { requestedOff: true })
    // can("see:row:icon:submit", "Record", { submit: true })
    // can("see:row:icon:ot", "Record", { isOT: true })
    // can("see:row:icon:process", "Record", { process: true })

    can('edit', 'Employee', {number: employeeNumber})
    can('edit:shifts', 'Employee', {number: employeeNumber})
    can('submit', 'Shift', {employeeNumber: employeeNumber })
    can('edit', 'TimeEntry', {employeeNumber: employeeNumber})
    can('edit', 'Shift', {employeeNumber: employeeNumber})
    can('pto', 'Shift', {isOT: {$ne: true}, isSick: {$ne: true}})
    can('sick', 'Shift', {isOT: {$ne: true}, requestedOff: {$ne: true}})

    can('submitOT', 'Shift', {employeeNumber: employeeNumber, isOT: {$eq: true}, notes: {$ne: ''},reasonId: {$ne: null} })

    if (User.role === 'employee') {
      can('bidOn', 'Shift', {available: true})
    }

    if (User.isDive) {
      can('add:DiveTime')
    }

    if (User.isFieldTraining) {
      can('add:FTOtime')
    }

    if (User.role === 'supervisor') {
      can('approve', 'Employee', {number: {$ne: employeeNumber}})
    }

    if (User.role === 'admin') {
      can('edit:TimeEntry:process')
      //can('process')
      can('process', 'Employee', {number: {$ne: employeeNumber}})
      can('visit:Feedback')
    } else {
      cannot('visit:Feedback')
    }

    // allow dispatch to change lunch flag.
    if ( User.businessUnitCode === '500506101') {
      can("edit:shift:paidLunch")
    }
   
    if (User.role === 'supervisor' || User.role === 'admin') {
      can('edit', 'Employee')
      // SHIFTS
      can('approve', 'Shift', {employeeNumber: {$ne: employeeNumber}})
      can('approve', 'TimeEntry', {employeeNumber: {$ne: employeeNumber}})
      can('see:Shift:bids')
      can('edit:Shift:Groups')
      can('edit:Shift:Employee')
      can('edit:Shift:Supervisor')
      can('edit:Shift:Assignment')
      can('edit:Shift:BusinessUnit')
      can('edit:Shift:SubLedger')
      // VIEWS
      can('viewAll:Teams')
      can('visit:AdminNav')
      can('visit:Assignments')
      can('visit:BusinessUnits')
      can('visit:Equipment')
      can('visit:PayTypes')
      can('visit:RecordsViewer')
      can('visit:Specialties')
      can('visit:SubLedgers')
      can('visit:Teams')
      can('visit:Template')
      // CREATE
      can('create:Assignments')
      can('create:Equipment')
      can('create:Groups')
      can('create:Reasons')
      can('create:Shifts')
      can('create:Specialties')
      can('create:SubLedgers')
      can('create:Teams')
      can('create:Templates')
      can('create', 'TimeEntry')
      can('schedule:Shifts')
      // DELETE
      can('delete', 'Team', [{superNumber1: employeeNumber}, {superNumber2: employeeNumber}])
      can('delete', 'Shift')
      can('delete', 'TimeEntry')
    }
  }
  return build()
}

export default ability
