import React, {useState} from 'react'
//import {Select, CaretIcon, ModalCloseButton} from 'react-responsive-select'
import Picker from 'react-mobile-picker'
import {Select } from 'grommet'
import _ from "lodash"
import {store} from '../psrs'

// for default styles...
import 'react-responsive-select/dist/react-responsive-select.css'

export const SelectReasonAlt = (props) => {
  const {shift, update} = props
  const Records = store.get('Reasons')
  const options = _.sortBy(Records.map((r) => ({sort: r.description, value: r.id, text: r.description, payTypeCode: r.payTypeCode, category: r.category})), ['sort'])

  const disabled = props.disabled
  //options.unshift({value: 'cancel', text: 'CANCEL'})
  options.unshift({value: 'clear', text: 'CLEAR'})

  const [selected, setSelected] = useState(shift.reasonId || undefined)

  const currentSelection = props && props.shift ? props.shift.reasonId : selected

  const handleChange = ({value}) => {


    if (value === 'cancel') {
      setSelected(shift.reasonId || undefined)
    } else if (value === 'clear') {
      setSelected(undefined)
      update({field: 'reasonId', id: shift.id, value: null})

    } else if (value !== 'cancel' && value !== 'clear' ) {
      setSelected(value)
      update({field: 'reasonId', id: shift.id, value})
    }
  }

  const handleSubmit = (value) => {
    console.log('SELECT onSubmit', value)
  }


  // Escaping regular expression special characters: [ \ ^ $ . | ? * + ( )
  const getEscapedText = text => text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

  // Create the regular expression with escaped special characters.
  const formatSearchExpression = text => new RegExp(getEscapedText(text), 'i');

  const onSearch = text => {
    const exp = formatSearchExpression(text);
    setXOptions(options.filter(option => exp.test(option.text)));
  };

  const x = options.find(o => o.value === shift.reasonId)
  const [value, setValue] = React.useState( [{value:x?.value, text: x?.text}] );
  const [xoptions, setXOptions] = useState(options);
  
  //return <Select disabled={disabled} name='reasonId' prefix='Acting: ' noSelectionLabel='Please select' modalCloseButton={<ModalCloseButton />} options={options} caretIcon={<CaretIcon />} selectedValue={currentSelection} onChange={handleChange} onSubmit={handleSubmit} />
  return (
    <Select
      //options={['small', 'medium', 'large']}
      style={{background: 'white'}}
      labelKey="text"
      valueKey={"value"}
      name="assignment"
      options={xoptions}
      disabled = {disabled}
      placeholder="Select Assignment"
      searchPlaceholder="Search"
      value={value}
      clear={
        {
          position: "top",
          text: "Clear Assignment",
          label: "Clear Assignment"
        }
      }
      onChange={({ option }) => {
        setValue(option)
        handleChange(option)
      }}
      onSearch={text => onSearch(text)}
    
    />
  );

}
