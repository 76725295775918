import React, {useState} from 'react'
import {Page, List, ListItem, Icon, Toolbar} from "react-onsenui"
import {uid} from "../psrs"
import {store} from "../psrs"

const Schedules = (props) => {
  const { title, active, tabbar } = props

  const [state, setState] = useState({
    data: []
  })

  const renderRow = (row) => {
    return (
      <ListItem modifier="chevron" key={uid(8)}>
        <div className="left"> <Icon icon="md-3d-rotation" /> </div>
        <span className="list-item__title">{row.fullName}</span>
      </ListItem>
    )
  }

  const listProps = {
    dataSource: state.data,
    renderRow,
  }

  return (
    <Page id="SchedulesPage">
      <Toolbar> <div className="center">{props.title}</div></Toolbar>
      <List {...listProps} />
    </Page>

  )

}

export default Schedules
