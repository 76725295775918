import React, {useState, useEffect, useRef} from 'react'
import {useAbilityContext} from '../useAbilityContext'
import {BackButton, Page, Toolbar, BottomToolbar, ProgressCircular} from 'react-onsenui'
import {ActionSheet, ActionSheetButton, Icon, Checkbox, Tab, Tabbar, TabPage, Card, Input, SearchInput, List, ListItem} from 'react-onsenui'
import {Button, Box, Text} from 'grommet'
import { Table, TableHeader, TableRow, TableBody, TableCell, Grommet, DataTable} from 'grommet'
import {FormNext} from 'grommet-icons'
import moment from 'moment'
import {TimePicker, StartTimeSelect} from '../components'
import {store, fetch, Can, a, an, This, uid, currentUser} from '../psrs'
import _ from 'lodash'
import PullToRefresh from 'react-simple-pull-to-refresh';


import { SwipeableList, SwipeableListItem, ActionAnimations } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

import { Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
//import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'


import '../styles/approvals.css'

export const Approvals = (props) => {
  const {navigator, route} = props
  const {view} = route
  const currentView = store.get('View')
  // console.log("route.data", route.data)
  const ability = useAbilityContext()
  const Employee = route.data && route.data.employee ? route.data.employee : store.get('CurrentUser')

  const viewData = route.data && route.data.shifts ? route.data.shifts : store.get('ViewData')
  //const viewData = store.get('ViewData')
  const Shifts = viewData ? viewData : []
  const _selected = new Set(store.get('ShiftsSelected'))
  const [selected, setSelected] = useState(_selected)
  //const [shifts, setShifts] = useState(_.orderBy(Shifts, 'start', 'asc'))
  const [shifts, setShifts] = useState([])
  // const [overlayed, setOverlayed] = useState(false)
  const [searchIds, setSearchIds] = useState([])
  const [searchDate, setSearchDate] = useState(moment().format('YYYY-MM-DD'))
  const [actions, setActions] = useState({
    selected: new Set(),
    open: false,
  })

  const [isMounted, setIsMounted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const pushPage = (S) => {
    store.set('Shift', S)
    navigator.pushPage({
      view: 'Shift',
      data: {shift: S},
      id: S.id,
    })
  }
  

  const fetchRecords = async (signal) => {

    setIsLoading(true)
    let records = []
    let ordered = []

    if (currentView === 'Approvals') {

      records = await fetch(`/api/mobile/approvals/shift`,{ signal: signal })
      if (searchIds.length > 0) {

        //records = records.filter(s => s.employee.fullName.toLowerCase().includes(searchIds[0].toLowerCase()) || s.start.includes(searchIds[0]))

        if (searchIds[0] === 'regular') {
          records = records.filter(s => !s.isSick && !s.requestedOff && !s.isOT) 
        } else if (searchIds[0] === 'overtime') {
          records = records.filter(s => s.isOT) 
        } else if (searchIds[0] === 'leave') {
          records = records.filter(s => s.requestedOff ) 
        } else if (searchIds[0] === 'sick') {
          records = records.filter(s => s.isSick ) 
        } else {
           records = records.filter(s => s.employee.fullName.toLowerCase().includes(searchIds[0].toLowerCase()) || s.start.includes(searchIds[0]))
        }
      }
      ordered = _.orderBy(records, 'start', 'asc')
      //store.set('Shifts', ordered)
      //setShifts(ordered)
    }

    if (currentView === 'MyApprovals') {
      records = await fetch(`/api/mobile/my_approvals/shift`,{ signal: signal })
      ordered = _.orderBy(records, 'start', 'asc')
      //store.set('Shifts', ordered)
      //setShifts(ordered)
    }

    if (currentView === "EmpApprovals") {
      records = await fetch(`/api/mobile/my_approvals/shift/${Employee.number}`,{ signal: signal })
      ordered = _.orderBy(records, 'start', 'asc')
      //store.set('Shifts', ordered)
      //setShifts(ordered)
    }

    setIsLoading(false)
    return ordered

  }

    const pullRefresh = async () => {

      fetchRecords()
      .then ((records) => {
          store.set('Shifts', records)
          setShifts(records)
        
      })

    }

    const query = async (e) => {
      let model = 'shift'
      let theSearch = e.target.value.replace(/\s/g, '') 

      
        if (['reg','regular'].some(term => theSearch.includes(term))) {
          theSearch = 'regular'
        } 
        if (['sick'].some(term => theSearch.includes(term))) {
          theSearch = 'sick'
        } 
        if (['leave'].some(term => theSearch.includes(term))) {
          theSearch = 'leave'
        } 
        if (['ot', 'overtime'].some(term => theSearch.includes(term))) {
          theSearch = 'overtime'
        }


      let dtFormat = 'Invalid date'

      if (theSearch.split(/[\/ .:]/).length === 3) {
        dtFormat = 'MM/DD/YYYY'
      } else if (theSearch.split(/[\/ .:]/).length === 2) {
        dtFormat = 'MM/DD/YYYY'
        theSearch = theSearch + '/' + moment().format('YYYY')
      }

      if (theSearch.split(/[\- .:]/).length === 3) {
        dtFormat = 'YYYY-MM-DD'
      } else if (theSearch.split(/[\- .:]/).length === 2) {
        dtFormat = 'YYYY-MM-DD'
        theSearch = theSearch + '-' + moment().format('YYYY')
      }

      if (dtFormat !== 'Invalid date') {
        theSearch = moment(theSearch, dtFormat).format('YYYY-MM-DD')
      }

      if (theSearch === 'Invalid date') {
        theSearch = e.target.value.replace(/\s/g, '') 
      }
      theSearch = encodeURIComponent(theSearch.trim())
      setSearchIds([theSearch])
      //setSearchIds([e.target.value.replace(/\s/g, '')])
    }
  
    
    const handleSearch = _.debounce(query, 1500, { leading: false, trailing: true })
    
    const updateShifts = async (params) => {
    let ids = Array.from(actions.selected.values()) 
   
    setActions({selected: new Set(), open: false})
    const body = JSON.stringify({params, ids})
    await fetch(`/api/shifts`, {method: 'PATCH', body: body})
    await pullRefresh()
    return true

  }


  const updateShift = async (params) => {
    const {update, shiftId} = params
    
    const body = update
    await fetch(`/api/shifts/${shiftId}`, {method: 'PATCH', body: JSON.stringify(body)})    
    
    
    await pullRefresh()
    return true
  }


  const xxsm = {size: 'xxsmall'}

  const toggleActions = () => {
    setActions({...actions, open: !actions.open})
  }

  const TimeBox = ({value, field, shift, disabled, checked}) => {
    /* const P = {
      textAlign: 'center',
      hoverIndicator: true,
      round: 'small',
      pad: 'small',
      wrap: false,
    }
    const TB = {
      start: {...P, background: 'green'},
      end: {...P, background: 'yellow'},
      hrs: {...P, background: 'grey'},
      ot: {...P, background: 'red'},
    } */
    
    const P = {
      align: "start",
      alignContent: 'start',
      textAlign: 'start',
      hoverIndicator: true,
      round: 'small',
      pad: 'small',
      wrap: false,
    }

    const TB = {
      start: {...P, background: '#EFEFF4'},
      end: {...P, background: '#EFEFF4'},
      hrs: {...P, background: '#EFEFF4'},
      ot: {...P, background: '#EFEFF4'},
    }

    let TBP = TB[field]

  
    if (checked) {
      TBP = {...TBP, background: 'LightSteelBlue' }
    }
    if (disabled) {
      TBP = {...TBP, background: 'lightgrey' }
    }

    if (disabled !== true && field === 'start') {
      TBP.onClick = () => {
        openPicker(shift, field)
      }
    }
    if (disabled !== true && field === 'end') {
      TBP.onClick = () => {
        openPicker(shift, field)
      }
    }

    //const TBP = TB[field]
    return (
      <Box {...TBP}>
        <Text size='xsmall' textAlign='center'>
          {value}
        </Text>
      </Box>
    )
  }

  const TOP = {alignSelf: 'start', margin: 'none'}
  const BT = {border: 'top'}
  const TD = {...BT, size: 'xsmall', pad: 'xsmall'}

  const xrenderRow = (S) => {
    const SS = moment(S.start)
    const SE = moment(S.end)
    const DDD = SS.format('ddd')
    const MO = SS.format('MMM')
    const DO = SS.format('Do')
    const ST = SS.format('HH:mm')
    const ET = SE.format('HH:mm')
    const DUR = `${S.duration}`
    const OT = `${S.durationOt}`
    const Date = () => (
      <div className='DateColumn'>
        <div className='WeekDay'>{DDD}</div>
        <div className='Month'>{MO}</div>
        <div className='Date'>{DO}</div>
      </div>
    )
    const cantEdit = ability.cannot('edit:shifts', This('Employee', Employee)) || !!S.submit || !!S.process
    const uncheckable = !!S.isSick || !!S.requestedOff || !!S.submit || !!S.process
    const statusProps = {
      textAlign: 'center',
      // round: "small",
      // pad: "small",
      wrap: false,
      fill: true,
      direction: 'row',
      align: 'center',
    }
    const iconProps = {margin: {right: '6px'}}

    const sickStatus = S.isSick && !S.process
    const ptoStatus = S.requestedOff && !S.process
    const otStatus = S.isOT
    const processedStatus = !!S.process
    
    const showNotes = false
    const disableCheckbox = view === 'Approvals' ? false : uncheckable ? true : cantEdit ? true : false
    return (
      <React.Fragment key={`${S.id}Row`}>
         
       {/*  <TableRow key={`shiftRow${S.id}`} width='100vw' className='ShiftTrData'>
          <TableCell {...BT}>
            <Box direction='row' fill align='center'>
              <Box pad='small'>
                <Checkbox
                  id={S.id}
                  disabled={disableCheckbox}
                  checked={actions.selected.has(S.id)}
                  onChange={(event) => {
                    const {checked} = event.target
                    if (!!checked) actions.selected.add(S.id)
                    if (!checked) actions.selected.delete(S.id)
                    console.log('CheckBox', actions.selected.has(S.id))
                    setActions({...actions})
                  }}
                  modifier='material'
                />
              </Box>
              <Box>
                <Date />
              </Box>
            </Box>
          </TableCell>
          <TableCell {...TD}>
            <TimeBox value={ST} field='start' shift={S} />
            <TimeBox value={ET} field='end' shift={S} />
          </TableCell>
          <TableCell {...TD}>{S && S.employee && S.employee.fullName ? S.employee.fullName : 'NONE'}</TableCell>
          <td style={{width: '68px'}} className='otStatus'>
            {!!sickStatus && (
              <Box {...statusProps}>
                <Box {...iconProps}>
                  <Icon icon='md-plaster' size={20} />
                </Box>
                <Box>
                  <span className='status'>SICK</span>
                </Box>
              </Box>
            )}
            {!!ptoStatus && (
              <Box {...statusProps}>
                <Box {...iconProps}>
                  <Icon icon='md-calendar' size={20} />
                </Box>
                <Box>
                  <span className='status'>PTO</span>
                </Box>
              </Box>
            )}
            {!!otStatus && (
              <Box {...statusProps}>
                <Box {...iconProps}>
                  <Icon icon='md-time' size={20} />
                </Box>
                <Box>
                  <span className='status'>OT {S.durationOt}</span>
                </Box>
              </Box>
            )}
            {!!processedStatus && (
              <Box {...statusProps}>
                <Box {...iconProps}>
                  <Icon icon='md-money-box' size={20} />
                </Box>
                <Box>
                  <span className='status'>PROCESS'D</span>
                </Box>
              </Box>
            )}
          </td>
          <TableCell {...TD}>
            <Button size='small' onClick={() => pushPage(S)} icon={<FormNext />} />
          </TableCell>
        </TableRow> */}
        {showNotes && (
          <TableRow key={`shiftRow${S.id}Notes`} width='100vw' className='ShiftTrNotes'>
            <td colSpan={6}>
              <Box pad='medium' background='white' margin='medium'>
                <Text {...xxsm}> {S.notes} </Text>
              </Box>
            </td>
          </TableRow>
        )}
      </React.Fragment>
    )
  }

  const renderCard = (S) => {

    const SS = moment(S.start)
    const SE = moment(S.end)
    const DDD = SS.format('ddd')
    const MO = SS.format('MMM')
    const DO = SS.format('Do')
    const ST = SS.format('HH:mm')
    const ET = SE.format('HH:mm')
    const DUR = S.duration ? `${S.duration.toFixed(2)}` : '0.00'
    const OT = S.durationOt ? `${S.durationOt.toFixed(2)}` : '0.00'
    const shiftDate = SS.format('ddd MM/DD/YY')
    const altShiftDate = SS.format('MM/DD/YY')

    const statusProps = {
      textAlign: 'center',
      // round: "small",
      // pad: "small",
      wrap: false,
      fill: true,
      direction: 'row',
      align: 'center',
    }
    const iconProps = {margin: {right: '6px'}}

    const otStatus = S.isOT && !S.process
    const sickStatus = S.isSick && !S.process
    const ptoStatus = S.requestedOff && !S.process
    const submittedStatus = S.submit && !S.process
    const processedStatus = !!S.process
    const approveStatus = !!S.approved
    const rejectStatus = !!S.rejected
    const approvedBy = S.approvedByNumber
    //const payTypeCat = S && S.payType && S.payType.category ? S.payType.category : 'REVIEW'

    const payTypeCat = S.isOT ? 'OVERTIME' : S.isSick ? 'SICK' : S.requestedOff ? 'LEAVE' : 'REGULAR'

    const payTypeCatClass = {
      REGULAR: 'reg',
      OVERTIME: 'ot',
      LEAVE: 'pto',
      SICK: 'sick',
    }
    const payTypeCatColor = payTypeCatClass[payTypeCat]


    const onDemandShift = S.shiftTemplateId !== null
    const locked = S.locked
    const acting = S.assignmentId
   
    const Date = () => (
      <div className='DateColumn'>
       
        <div className='Month'>
            <small><strong>{DDD}</strong></small>&nbsp;
            <small>{altShiftDate}</small>
        </div>
        {/* <div className='WeekDay'>{DDD}</div>
        <div className='Month'>{MO}</div>
        <div className='Date'>{DO}</div> */}
      </div>
    )

    const BasicSwipeContent = ({ label, position }) => (
      <div className={`basic-swipeable-list__item-content-${position}`}>
        <span><Icon icon={position==='right' ? 'md-thumb-down':'md-thumb-up'} size={18}>{' '}{label}</Icon></span>
      </div>
    );

    const swipeLeftOptions = () => ({
      content: <BasicSwipeContent label="Reject" position="right" />,
      action: console.info('swipe action triggered - Reject')
    });

    const swipeRightOptions = () => ({
      content: <BasicSwipeContent label="Approve" position="left" />,
      action: console.info('swipe action triggered - Approve')
    });

    return (
        <SwipeableListItem
          key={S.id}
          swipeLeft={{
            content: <div className={`basic-swipeable-list__item-content-right`}>Reject</div>,
            //actionAnimation: 'REMOVE',
            action: () => {
              updateShift({update: {approved: false, rejected: true, submit: true}, shiftId: S.id})
              console.info('swipe action triggered')
            }
          }} 
           swipeRight={{
            content: <div className={`basic-swipeable-list__item-content-left`}>Approve</div>,
            action: () => {
              updateShift({update: {approved: true, rejected: false, submit: true}, shiftId: S.id})
              console.info('swipe right action triggered')
            }
          }} 
          //swipeLeft={swipeLeftOptions(S.id)}
          //swipeRight={swipeRightOptions(S.id)}
          //onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
        >
          <List key={uid(8)} modifier="inset" style={{width: '100%'}}>
            <ListItem key={uid(8)} style={{width: '100%'}}>
              <div>
                <strong>{S.employee.fullName}</strong>
                <strong> | Approved: </strong>{S.approve ? ' True' : ' False'}
              </div>
            </ListItem>
            <ListItem key={uid(8)} style={{width: '100%'}}>
              
              {/* <div style={{width: '100%'}}> */}
                {/* <div className='columns'> */}
                <Table key={uid(8)}>
                  <TableBody key={uid(8)}>
                    <TableRow key={uid(8)} className={payTypeCatColor}>
                      <TableCell key={uid(8)} scope='col' align='center' >
                        <Checkbox
                          id={S.id}
                          //modifier='material'
                          disabled={false} //{disableCheckbox}
                          checked={actions.selected.has(S.id)}
                          onChange={(event) => {
                            const {checked} = event.target
                            if (!!checked) actions.selected.add(S.id)
                            if (!checked) actions.selected.delete(S.id)
                            console.log('CheckBox', actions.selected.has(S.id))
                            setActions({...actions})
                          }}
                        />
                      </TableCell>
                      <TableCell key={uid(8)} scope="col" align='start'>
                      <div className='column'>
                        <div className='Date'>
                          <small><strong>Date: </strong>{altShiftDate}</small>
                        </div>
                        <div className='Start'>
                          <small><strong>Start: </strong> {ST}</small>
                        </div>
                        <div className='End'>
                        <small><strong>End : </strong>{ET}</small>
                        </div>
                        <div className='Duraton'>
                        <small><strong>Duration: </strong>{DUR}</small>
                        </div>
                        <div className='Duraton'>
                        <small><strong>Pay Type: </strong><label className={payTypeCatColor}>{payTypeCat}</label></small>
                        </div>
                      </div>
                      </TableCell>
                      <TableCell key={uid(8)} scope="col" align='start'  className='otStatus'>
                        <div style={{align: 'left'}}>
                        {!!approveStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-thumb-up' style={{color: '#70BCFE'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small> APPROVED</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!rejectStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-thumb-down'  style={{color: 'red'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small> REJECTED</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!sickStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-plaster' style={{color: '#98312C'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>SICK</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!ptoStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-calendar' style={{color: '#E47641'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>LEAVE</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!submittedStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-assignment-check' style={{color: '#3482A9'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>SUBMIT'D</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!processedStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-money-box' style={{color: 'green'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small> PROCESS'D</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!otStatus && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-time' style={{color: 'black'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>OT</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!onDemandShift && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-alarm-plus' style={{color: '#CD853F'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>New</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!acting && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-shield-security' style={{color: '#2E8B57'}} size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>ACTING</small></span>
                            </Box>
                          </Box>
                        )}
                        {!!locked && (
                          <Box {...statusProps}>
                            <Box {...iconProps}>
                              <Icon icon='md-lock' size={18} />
                            </Box>
                            <Box>
                              <span className='status'><small>LOCK'D</small></span>
                            </Box>
                          </Box>
                        )}
                        {/* {!uncheckable && <TimeBox disabled={cantEdit} value={OT} field='ot' shift={S} />} */}
                      </div>
                    </TableCell>
                    <TableCell key={uid(8)} scope="col" align='end' size="xxsmall" verticalAlign="middle" >
                      <div style={{ align: 'right' }}>
                          <Button style={{size: '100%'}} size='small' onClick={() => pushPage(S)} icon={<FormNext />} />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
                {/* </div>
              </div> */}
            
            </ListItem>
          </List>
        </SwipeableListItem>
      
    )
  }

   const useForceUpdate = () => {
    const [, forceUpdate] = React.useState();
  
    return React.useCallback(() => {
      forceUpdate(s => !s);
    }, []);
  }

  const forceUpdate = useForceUpdate()

  const renderBack = true //navigator.routes.length > 2

  const count = actions.selected.size
  const viewTitle = view === 'Approvals' ? 'Approvals' : `${Employee.fullName} Shifts`
  const buttonTitle = view === 'Approvals' ? `Approve / Reject ${count} Shifts` : `Edit ${count} Selected Shifts`
  const buttonPlaceholder = view === 'Approvals' ? 'Select Shifts to Approve / Reject' : 'Select Shifts to Edit'

  const renderToolbar = () => (
    <Toolbar>
      {!!renderBack && (
        <div className='left'>
          <BackButton>Back</BackButton>
        </div>
      )}
      <div className='center'> {viewTitle} </div>
    </Toolbar>
  )

  const canEdit = ability.can('edit', an('Employee', Employee))
  //const canApprove = ability.can('approve', an('Employee', Employee))
  const canApprove = true //ability.can('approve', an('Shift', Shifts))

  const canProcess = ability.can('process')

  const renderApprovalActionButton = () => {
    const disabled = count === 0

    return (
      view !== 'EmpApprovals' ?
      <Can I='edit:shifts' an={Employee}>
        <BottomToolbar>
          <Box justify='center' align='center' fill direction='row' pad='none'>
            <Button disabled={disabled} onClick={toggleActions} label={disabled ? buttonPlaceholder : buttonTitle} primary fill />
          </Box>
        </BottomToolbar>
      </Can>
      :
      <BottomToolbar>
        <Box justify='center' align='center' fill direction='row' pad='none'>
          <Button disabled={disabled} onClick={toggleActions} label={disabled ? buttonPlaceholder : buttonTitle} primary fill />
        </Box>
      </BottomToolbar>
    )
  }

  /* useEffect(() => {
    store.set('Shifts', shifts)
  }, [shifts]) */

 

  useEffect(() => {
    //if (!isMounted){
    //  setIsMounted(true)
      //forceUpdate()
    //}
    //-maybe not herefetchRecords()

    let mounted = true

    async function xfetchRecords(signal) {

      let records = []
      if (currentView === 'Approvals') {
  
        records = await fetch(`/api/mobile/approvals/shift`,{ signal: signal })
        if (searchIds.length > 0) {
  
          records = records.filter(s => s.employee.fullName.toLowerCase().includes(searchIds[0].toLowerCase()) || s.start.includes(searchIds[0]))
  
        }
        const ordered = _.orderBy(records, 'start', 'asc')
        store.set('Shifts', ordered)
        //if (aborted === false) {
        if (mounted) {  
          setShifts(ordered)
        }
      }
  
      if (currentView === 'MyApprovals') {
        records = await fetch(`/api/mobile/my_approvals/shift`,{ signal: signal })
        const ordered = _.orderBy(records, 'start', 'asc')
        store.set('Shifts', ordered)
        if (mounted) {
          setShifts(ordered)
        }
      }
  
      if (currentView === "EmpApprovals") {
        records = await fetch(`/api/mobile/my_approvals/shift/${Employee.number}`,{ signal: signal })
        const ordered = _.orderBy(records, 'start', 'asc')
        store.set('Shifts', ordered)
        if (mounted) {
          setShifts(ordered)
        }
      
      }
    }
    
    fetchRecords()
    .then ((records) => {
      if (mounted) {     
        store.set('Shifts', records)
        setShifts(records)
      }
    })
   
    return () => {
      mounted = false
    }
   

  }, [])

  /* useEffect(() => {

    if (isMounted) {
      store.set('Shifts', shifts)
      //fetchRecords()
    } else {
      ///setIsMounted(true)
      //forceUpdate()
    }

    return () => {
      setIsMounted(false)
    }
  }, [shifts])  */
  

  useEffect(() => {
    //if (isMounted)

    let mounted = true
    if (mounted && searchIds.length > 0) {

      fetchRecords()
      .then ((records) => {
        if (mounted) {     
          store.set('Shifts', records)
          setShifts(records)
        }
      })
    } 

    return () => {
      setIsMounted(false)
      mounted = false
    }
 
  }, [searchIds]) 

  const [currentTab, setCurrentTab] = useState(1)

  const TabPage = (props) => {
    const {activeIndex, tabbar, shifts} = props

    return (
      <Page>
         <Box full title="Approvals" active={activeIndex === 0} tabbar={tabbar} >
          <List key={uid(8)}>{shifts.map((shift) => renderCard(shift))}</List> 
        </Box>
      </Page>

    )
  }

  const handleTabClick = (index) => {
    setCurrentTab(0)
  }

/*   useEffect(() =>{
    //fetchRecords()

  }, [currentTab]) */
  
  return (
    <Page id='ApprovalsPage' renderToolbar={renderToolbar} renderFixed={renderApprovalActionButton}>
      {isLoading && (
       <div className='approveDivLoading' ><ProgressCircular indeterminate className='approveProgressLoading' /></div>
      )}
      <div className='ShiftsStickyHeader'>
        <Table {...TOP} className='TableFixedTh'>
          <TableHeader className='StickyTableHeader'>
            <tr>
              <th colSpan={2}>
                {/* {actions.selected.size !== 0 && ( */}
                  <br/>
                  <Checkbox
                    style={{borderColor: "black"}}
                    id='selectAll'
                    input='selectAll'
                    //modifier='noborder'
                    inputId='selAll'
                    //modifier='material'
                    disabled={false} //{disableCheckbox}
                    checked={actions.selected.size !==0 }
                    onChange={(event) => {
                      const {checked} = event.target
                      if (!!checked) {
                        for (let S of shifts) {
                          actions.selected.add(S.id)
                        }
                      }
                      if (!checked) { 
                      for (let S of shifts) {
                          //actions.selected.delete(S.id)
                          actions.selected = new Set()
                      }
                      }
                      setActions({...actions})
                    }}
                  />
                  <label style={{alignSelf: 'center'}}>&nbsp;&nbsp;All</label>
              </th>
            </tr>
            </TableHeader>
        </Table>
 
          
      </div>
      <div style = {{background: "white"}}>
        <br style={{background: "white"}}/>
        <br style={{background: "white"}}/>
      </div>
      <PullToRefresh style={{overflow: "visible"}} onRefresh={pullRefresh}>

          <SearchInput 
             key={uid(8)}
             style={{width: '100%'}}
             value={searchIds[0]} 
             placeholder='Employee name or date YYYY-MM-DD'
             modifier='material' 
             onChange={handleSearch}
          />


          

          {/*} <Tabbar
             position='top'
             activeIndex={currentTab}
             swipeable={true}
             hideTabs={true}
             onPreChange={handleTabClick}
             onPostChange={() => console.log('postChange')}
             onReactive={handleTabClick}
             renderTabs={(activeIndex, tabbar) => [
              {
                content: <TabPage activeIndex={activeIndex} tabbar={tabbar} shifts={shifts}/>,
                tab: <Tab badge={store.get("Shifts").filter(s => s.needsApproved === true).length} label="Approvals" activeIcon="md-accounts" icon="md-accounts" />
              },
              {
                content: <Page><Box title="My Approvals" full active={activeIndex === 1} tabbar={tabbar} >
                    <List key={uid(8)}>{shifts.map((shift) => renderCard(shift))}</List> 
                </Box></Page>,
                tab: <Tab badge={Shifts.filter( s => s.needsApproved === true).length} label="My Approvals"  activeIcon="md-assignment-account" icon="md-assignment-account" />
              }
             ]}
          > */}
          <Box full >
                <List key={uid(8)}>{shifts.map((shift) => renderCard(shift))}</List> 
            </Box>
           {/* </Tabbar>  */}
        
      </PullToRefresh>
      <ActionSheet isOpen={actions.open}>
        <ActionSheetButton className='ActionSheetTitle'>
          <b>{`Bulk Edit ${actions.selected.size} Shifts`}</b>
        </ActionSheetButton>
        {!!canApprove && <ActionSheetButton onClick={() => updateShifts({approved: true})}>Approve</ActionSheetButton>}
        {!!canApprove && <ActionSheetButton onClick={() => updateShifts({rejected: true})}>Reject</ActionSheetButton>}
        {!!canProcess && <ActionSheetButton onClick={() => updateShifts({process: true})}>Process</ActionSheetButton>}
        <ActionSheetButton onClick={() => toggleActions()}>Cancel</ActionSheetButton>
      </ActionSheet>
    </Page>
  )
}

export default Approvals
