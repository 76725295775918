import React from "react";
import { Page } from "react-onsenui";
import { BottomNav } from "./components";
import * as Views from "./views";
import { store } from "./psrs";
import ErrorBoundary from "./ErrorBoundary"

export const Route = (props) => {
  const currentUser = store.get("CurrentUser");
  const pageProps = {
    renderBottomToolbar: () =>
      props.route.view !== "Login" ? <BottomNav {...props} /> : null,
    onInit: async (e) => {},
    onShow: (e) => {},
    onHide: (e) => {},
    onDeviceBackButton: () => {},
  };

  const PageContent = Views[props.route.view];

  return (
    <Page id="RoutePage" {...pageProps}>
      <ErrorBoundary>
        <PageContent {...props} id="routeView" />
      </ErrorBoundary>
    </Page>
  );
};

export default Route;
