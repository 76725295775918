import React, {useState, useEffect} from 'react'
import {BackButton, Page, Toolbar, BottomToolbar} from 'react-onsenui'
import {Button, Box, Text} from 'grommet'
import {Table, TableHeader, TableRow, TableBody, TableCell, CheckBox} from 'grommet'
import {FormNext} from 'grommet-icons'
import moment from 'moment'
import {store, fetch, Can, a, an, This} from '../psrs'
import _ from 'lodash'

export const Shifts = (props) => {
  const {navigator, route} = props
  const [shifts, setShifts] = useState([])

  const pushPage = (S) => {
    store.set('Shift', S)
    navigator.pushPage({
      view: 'Shift',
      data: {shift: S},
      id: S.id,
    })
  }

  const fetchRecords = async () => {
    if (!shifts.length) {
      const records = await fetch(`/api/shifts/available`)
      const ordered = _.orderBy(records, 'start', 'asc')
      console.log('Available fetchRecords')
      setShifts(ordered)
    }
  }

  const xxsm = {size: 'xxsmall'}

  const TimeBox = ({value, field, shift, disabled}) => {
    const P = {
      textAlign: 'center',
      hoverIndicator: true,
      round: 'small',
      pad: 'small',
      wrap: false,
    }
    const TB = {
      start: {...P, background: 'green'},
      end: {...P, background: 'yellow'},
      hrs: {...P, background: 'grey'},
      ot: {...P, background: 'red'},
    }
    const TBP = TB[field]
    if ((!disabled && field === 'start') || field === 'end') {
      TBP.onClick = () => {
        openPicker(shift, field)
      }
    }
    return (
      <Box {...TBP}>
        <Text size='xsmall' textAlign='center'>
          {value}
        </Text>
      </Box>
    )
  }

  const TOP = {
    alignSelf: 'stretch',
    margin: 'none',
  }

  const BT = {border: 'top'}
  const TD = {...BT, size: 'small', pad: 'xsmall'}

  const renderRow = (S) => {
    const SS = moment(S.start)
    const SE = moment(S.end)
    const DDDD = SS.format('dddd')
    const MO = SS.format('MMM')
    const DO = SS.format('Do')
    const ST = SS.format('HH:mm')
    const ET = SE.format('HH:mm')
    const DUR = `${S.duration}`
    const Date = () => (
      <Box pad='small'>
        <div>{DDDD}</div>
        <div>
          {MO} {DO}
        </div>
      </Box>
    )
    return (
      <React.Fragment key={`${S.id}Row`}>
        <TableRow key={`shiftRow${S.id}`} width='100vw' className='ShiftTrData'>
          <TableCell {...TD}>
            <Date />
          </TableCell>
          <TableCell {...TD}>
            <TimeBox disabled={true} value={ST} field='start' shift={S} />
          </TableCell>
          <TableCell {...TD}>
            <TimeBox disabled={true} value={DUR} field='hrs' shift={S} />
          </TableCell>
          <TableCell {...TD}>{S.supervisor1.lastName}</TableCell>
          <TableCell {...TD}>
            <div className='text-center'> {S.bids.length} </div>
          </TableCell>
          <TableCell {...TD}>
            <Button size='small' onClick={() => pushPage(S)} icon={<FormNext />} />
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  // const renderBack = navigator.routes.length > 2;
  const renderBack = true

  const renderToolbar = () => (
    <Toolbar>
      {!!renderBack && (
        <div className='left'>
          <BackButton>Back</BackButton>
        </div>
      )}
      <div className='center'> Available Shifts </div>
    </Toolbar>
  )

  // useEffect(() => {
  //   store.set("Shifts", shifts);
  // }, [shifts]);

  useEffect(() => {
    const view = store.get('View')
    console.log('Current Route', view, route.view)
    if (view === 'Available') {
      fetchRecords()
    }
    // if (navigator.route.view === "Available") {
    //   fetchRecords()
    // }
  }, [])

  return (
    <Page id='AvailableShiftsPage' renderToolbar={renderToolbar}>
      <Box full>
        <Table {...TOP} className='TableFixedTh'>
          <TableHeader className='StickyTableHeaderSpacer'>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </TableHeader>
          <TableHeader className='StickyTableHeader'>
            <tr>
              <th>Date</th>
              <th>Start</th>
              <th>Hours</th>
              <th>Supervisor</th>
              <th>Bids</th>
              <th></th>
            </tr>
          </TableHeader>
          <TableBody>{shifts.map((shift) => renderRow(shift))}</TableBody>
          {/* <List data={ shifts } children={ (S) => renderRows(S) } className="List" /> */}
        </Table>
      </Box>
    </Page>
  )
}

export default Shifts
