import React from "react"
import { Shifts } from "./Shifts"

export const EmpShifts = props => {
  // const { route } = props
  // const myShiftProps = {...props, route: { view: "MyShifts"}, shifts: route.data.shifts}
  return <Shifts { ...props } />
}
  

export default EmpShifts
