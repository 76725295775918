import React from "react"
import TimePicker from 'rc-time-picker'

export const EndTimeSelect = (props) => {
  const {shift, setShift} = props

  const selectOptions = {
    allowEmpty: false,
    showSecond: false,
    format: "HH:mm",
    defaultValue: shift.end ? shift.end : null,
    onChange: end => {
      setShift({...shift, end})
    }
  }

  return <TimePicker {...selectOptions} />
}

