import React, { useState } from "react"
import {store} from "../psrs"
import _ from "lodash"

export const Reasons = props => {
  const {shift, handleUpdate, close} = props
  const [mounted, setMounted] = useState(false)
  const value = shift.reasons.map(r => r.id)
  const items = store.get('reasons')

  const renderItem = item => {
    const itemProps = {
      onClick: () => {
        const val = value.includes(item.id) ? [] : [item.id]
        handleUpdate({ field: "reasonIds", value: val })
        close()
      },
      className: `pickerItem ${!!value.includes(item.id) && 'selected'}`,
      key: item.id
    }
    return <div {...itemProps}>{ item.description }</div>
  }

  return (
    <div className="MobilePicker">
      <div className="MobileTitle" onClick={close}>
        Reasons
      </div>
      <div className="MobilePickerContainer">
        <div className="MobilePickerScroll">{ items.map(renderItem) }</div>
      </div>
      <div className="MobilePickerFooter">
        <div onClick={ close } className="cancel">Cancel</div>
        <div onClick={ close } className="save">Save</div>
      </div>
    </div>
  );
}


export default Reasons
