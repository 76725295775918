import React from 'react'
import {Select, CaretIcon, ModalCloseButton} from 'react-responsive-select'
import {Box} from 'grommet'
import _ from 'lodash'
import {store} from '../psrs'

// for default styles...
import 'react-responsive-select/dist/react-responsive-select.css'

export const SelectSickLeave = React.memo((props) => {
  const {timeEntry, handleUpdate} = props
  let { options } = props
  const Employee = store.get('CurrentUser')
  const {leave, number} = Employee
  const disabled = props.disabled


  const truncString = (str, n) => {
    return str.substr(0,n-1)+(str.length>n?'...':'');
  }


  const {payTypeCode, id} = timeEntry
  //const Records = store.get('PayTypes').filter((r) => !!r.included && r.category === 'SICK' || r.code === '380')
  //const options = _.sortBy(Records.map((r) => ({sort: parseInt(r.code), value: r.code, text: `${r.code} - ${truncString(r.rename,20)}`, markup: <small>{r.code} - {r.rename}</small>, disabled: r.code === '380' ? true : false})), ['sort'])

  return (
        <Select
          disabled={disabled || timeEntry.payTypeCode === '380'}
          selectedValue={timeEntry.payTypeCode}
          name='leaveBalanceCode'
          noSelectionLabel='Leave Balance'
          modalCloseButton={<ModalCloseButton />}
          
          options={options}
          caretIcon={<CaretIcon />}
          onChange={(newValue) => handleUpdate({field: 'payTypeCode', value: newValue.value, id: timeEntry.id})}
        />
  )
})