import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Toolbar, BackButton, Toast, Page, Icon } from "react-onsenui";
import { BottomNav } from "../components";
import uid from "uid";

const Sink = (props) => {
  const [state, setState] = useState({
    show: false,
    icon: "NONE",
    value: "",
    coppied: false,
  });
  const toast = (event) => {
    const icon = event.target.getAttribute("icon");
    setState({ ...state, icon, show: true });
  };
  const i = {
    onClick: toast,
    size: 26,
    style: { margin: "1rem", textAlign: "center" },
  };
  const renderToolbar = () => (
    <Toolbar>
      <div className="left">
        <BackButton>Back</BackButton>
      </div>
      <div className="center">ICONS</div>
    </Toolbar>
  );
  return (
    <Page id="ShiftsPage" renderToolbar={renderToolbar}>
      <Toast isOpen={state.show}>
        <div className="message">{state.icon} </div>
        <CopyToClipboard
          text={`<Icon icon="${state.icon}" />`}
          onCopy={() => setState({ ...state, show: false })}
        >
          <button>copy</button>
        </CopyToClipboard>
      </Toast>
      <Icon key={uid(4)} {...i} icon="md-3d-rotation"> <p>3d-rotation</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-500px"> <p>500px</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-8tracks"> <p>8tracks</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-account-add"> <p>account-add</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-account-box-mail"> <p>account-box-mail</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-account-box-o"> <p>account-box-o</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-account-box-phone"> <p>account-box-phone</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-account-box"> <p>account-box</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-account-calendar"> <p>account-calendar</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-account-circle"> <p>account-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-account-o"> <p>account-o</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-account"> <p>account</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-accounts-add"> <p>accounts-add</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-accounts-alt"> <p>accounts-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-accounts-list-alt"> <p>accounts-list-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-accounts-list"> <p>accounts-list</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-accounts-outline"> <p>accounts-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-accounts"> <p>accounts</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-adb"> <p>adb</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alarm-add"> <p>alarm-add</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alarm-check"> <p>alarm-check</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alarm-off"> <p>alarm-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alarm-plus"> <p>alarm-plus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alarm-snooze"> <p>alarm-snooze</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alarm"> <p>alarm</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-album"> <p>album</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alert-circle-o"> <p>alert-circle-o</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alert-circle"> <p>alert-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alert-octagon"> <p>alert-octagon</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alert-polygon"> <p>alert-polygon</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-alert-triangle"> <p>alert-triangle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-apps"> <p>apps</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-archive"> <p>archive</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-back"> <p>arrow-back</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-forward"> <p>arrow-forward</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-in"> <p>arrow-in</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-left-bottom"> <p>arrow-left-bottom</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-left"> <p>arrow-left</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-merge"> <p>arrow-merge</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-missed"> <p>arrow-missed</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-out"> <p>arrow-out</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-right-top"> <p>arrow-right-top</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-right"> <p>arrow-right</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrow-split"> <p>arrow-split</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-arrows"> <p>arrows</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-aspect-ratio-alt"> <p>aspect-ratio-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-aspect-ratio"> <p>aspect-ratio</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-assignment-account"> <p>assignment-account</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-assignment-alert"> <p>assignment-alert</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-assignment-check"> <p>assignment-check</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-assignment-o"> <p>assignment-o</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-assignment-return"> <p>assignment-return</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-assignment-returned"> <p>assignment-returned</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-assignment"> <p>assignment</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-attachment-alt"> <p>attachment-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-attachment"> <p>attachment</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-audio"> <p>audio</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-badge-check"> <p>badge-check</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-balance-wallet"> <p>balance-wallet</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-balance"> <p>balance</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-battery-alert"> <p>battery-alert</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-battery-charging"> <p>battery-charging</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-battery-flash"> <p>battery-flash</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-battery-unknown"> <p>battery-unknown</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-battery"> <p>battery</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-behance"> <p>behance</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-bike"> <p>bike</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-block-alt"> <p>block-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-block"> <p>block</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-blogger"> <p>blogger</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-bluetooth-connected"> <p>bluetooth-connected</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-bluetooth-off"> <p>bluetooth-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-bluetooth-search"> <p>bluetooth-search</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-bluetooth-setting"> <p>bluetooth-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-bluetooth"> <p>bluetooth</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-blur-circular"> <p>blur-circular</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-blur-linear"> <p>blur-linear</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-blur-off"> <p>blur-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-blur"> <p>blur</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-boat"> <p>boat</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-book-image"> <p>book-image</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-book-photo"> <p>book-photo</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-book"> <p>book</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-bookmark-outline"> <p>bookmark-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-bookmark"> <p>bookmark</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-all"> <p>border-all</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-bottom"> <p>border-bottom</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-clear"> <p>border-clear</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-color"> <p>border-color</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-horizontal"> <p>border-horizontal</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-inner"> <p>border-inner</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-left"> <p>border-left</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-outer"> <p>border-outer</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-right"> <p>border-right</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-style"> <p>border-style</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-top"> <p>border-top</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-border-vertical"> <p>border-vertical</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-2"> <p>brightness-2</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-3"> <p>brightness-3</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-4"> <p>brightness-4</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-5"> <p>brightness-5</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-6"> <p>brightness-6</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-7"> <p>brightness-7</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-auto"> <p>brightness-auto</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-high"> <p>brightness-high</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-low"> <p>brightness-low</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-medium"> <p>brightness-medium</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brightness-setting"> <p>brightness-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-broken-image"> <p>broken-image</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-brush"> <p>brush</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-bug"> <p>bug</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-bus"> <p>bus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cake"> <p>cake</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-calendar-account"> <p>calendar-account</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-calendar-alt"> <p>calendar-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-calendar-check"> <p>calendar-check</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-calendar-close"> <p>calendar-close</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-calendar-note"> <p>calendar-note</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-calendar-remove"> <p>calendar-remove</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-calendar"> <p>calendar</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera-add"> <p>camera-add</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera-alt"> <p>camera-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera-bw"> <p>camera-bw</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera-front"> <p>camera-front</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera-mic"> <p>camera-mic</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera-monochrome-photos"> <p>camera-monochrome-photos</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera-party-mode"> <p>camera-party-mode</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera-rear"> <p>camera-rear</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera-roll"> <p>camera-roll</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera-switch"> <p>camera-switch</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-camera"> <p>camera</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-car-taxi"> <p>car-taxi</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-car-wash"> <p>car-wash</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-car"> <p>car</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-card-alert"> <p>card-alert</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-card-giftcard"> <p>card-giftcard</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-card-membership"> <p>card-membership</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-card-off"> <p>card-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-card-sd"> <p>card-sd</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-card-sim"> <p>card-sim</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-card-travel"> <p>card-travel</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-card"> <p>card</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-caret-down-circle"> <p>caret-down-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-caret-down"> <p>caret-down</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-caret-left-circle"> <p>caret-left-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-caret-left"> <p>caret-left</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-caret-right-circle"> <p>caret-right-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-caret-right"> <p>caret-right</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-caret-up-circle"> <p>caret-up-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-caret-up"> <p>caret-up</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-case-check"> <p>case-check</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-case-download"> <p>case-download</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-case-play"> <p>case-play</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-case"> <p>case</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cast-connected"> <p>cast-connected</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cast"> <p>cast</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-center-focus-strong"> <p>center-focus-strong</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-center-focus-weak"> <p>center-focus-weak</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-chart-donut"> <p>chart-donut</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-chart"> <p>chart</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-check-all"> <p>check-all</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-check-circle-u"> <p>check-circle-u</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-check-circle"> <p>check-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-check-square"> <p>check-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-check"> <p>check</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-chevron-down"> <p>chevron-down</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-chevron-left"> <p>chevron-left</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-chevron-right"> <p>chevron-right</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-chevron-up"> <p>chevron-up</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-circle-o"> <p>circle-o</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-circle"> <p>circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-city-alt"> <p>city-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-city"> <p>city</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-close-circle-o"> <p>close-circle-o</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-close-circle"> <p>close-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-close"> <p>close</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-closed-caption"> <p>closed-caption</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cloud-box"> <p>cloud-box</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cloud-circle"> <p>cloud-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cloud-done"> <p>cloud-done</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cloud-download"> <p>cloud-download</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cloud-off"> <p>cloud-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cloud-outline-alt"> <p>cloud-outline-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cloud-outline"> <p>cloud-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cloud-upload"> <p>cloud-upload</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cloud"> <p>cloud</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cocktail"> <p>cocktail</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-code-setting"> <p>code-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-code-smartphone"> <p>code-smartphone</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-code"> <p>code</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-codepen"> <p>codepen</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-coffee"> <p>coffee</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-add"> <p>collection-add</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-bookmark"> <p>collection-bookmark</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-case-play"> <p>collection-case-play</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-folder-image"> <p>collection-folder-image</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-image-o"> <p>collection-image-o</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-image"> <p>collection-image</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item-1"> <p>collection-item-1</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item-2"> <p>collection-item-2</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item-3"> <p>collection-item-3</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item-4"> <p>collection-item-4</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item-5"> <p>collection-item-5</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item-6"> <p>collection-item-6</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item-7"> <p>collection-item-7</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item-8"> <p>collection-item-8</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item-9-plus"> <p>collection-item-9-plus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item-9"> <p>collection-item-9</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-item"> <p>collection-item</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-music"> <p>collection-music</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-pdf"> <p>collection-pdf</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-plus"> <p>collection-plus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-speaker"> <p>collection-speaker</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-text"> <p>collection-text</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-collection-video"> <p>collection-video</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-colorize"> <p>colorize</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-alert"> <p>comment-alert</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-alt-text"> <p>comment-alt-text</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-alt"> <p>comment-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-dots"> <p>comment-dots</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-edit"> <p>comment-edit</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-image"> <p>comment-image</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-list"> <p>comment-list</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-more"> <p>comment-more</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-outline"> <p>comment-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-sign"> <p>comment-sign</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-text-alt"> <p>comment-text-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-text"> <p>comment-text</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment-video"> <p>comment-video</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comment"> <p>comment</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-comments"> <p>comments</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-compare"> <p>compare</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-compass"> <p>compass</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-confirmation-number"> <p>confirmation-number</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-copy"> <p>copy</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop-16-9"> <p>crop-16-9</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop-3-2"> <p>crop-3-2</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop-5-4"> <p>crop-5-4</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop-7-5"> <p>crop-7-5</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop-din"> <p>crop-din</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop-free"> <p>crop-free</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop-landscape"> <p>crop-landscape</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop-original"> <p>crop-original</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop-portrait"> <p>crop-portrait</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop-square"> <p>crop-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-crop"> <p>crop</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cut"> <p>cut</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-cutlery"> <p>cutlery</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-daydream-setting"> <p>daydream-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-dehaze"> <p>dehaze</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-delete"> <p>delete</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-delicious"> <p>delicious</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-desktop-mac"> <p>desktop-mac</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-desktop-windows"> <p>desktop-windows</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-developer-board"> <p>developer-board</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-device-hub"> <p>device-hub</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-devices-off"> <p>devices-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-devices"> <p>devices</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-dialpad"> <p>dialpad</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-directions-bike"> <p>directions-bike</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-directions-boat"> <p>directions-boat</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-directions-bus"> <p>directions-bus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-directions-car"> <p>directions-car</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-directions-railway"> <p>directions-railway</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-directions-run"> <p>directions-run</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-directions-subway"> <p>directions-subway</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-directions-walk"> <p>directions-walk</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-directions"> <p>directions</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-disc-full"> <p>disc-full</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-disqus"> <p>disqus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-dns"> <p>dns</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-dock"> <p>dock</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-dot-circle-alt"> <p>dot-circle-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-dot-circle"> <p>dot-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-download"> <p>download</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-dribbble"> <p>dribbble</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-drink"> <p>drink</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-dropbox"> <p>dropbox</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-edit"> <p>edit</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-eject-alt"> <p>eject-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-eject"> <p>eject</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-email-open"> <p>email-open</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-email"> <p>email</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-equalizer"> <p>equalizer</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-evernote"> <p>evernote</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-explicit"> <p>explicit</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-exposure-alt"> <p>exposure-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-exposure"> <p>exposure</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-eye-off"> <p>eye-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-eye"> <p>eye</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-eyedropper"> <p>eyedropper</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-face"> <p>face</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-facebook-box"> <p>facebook-box</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-facebook"> <p>facebook</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-fast-forward"> <p>fast-forward</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-fast-rewind"> <p>fast-rewind</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-favorite-outline"> <p>favorite-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-favorite"> <p>favorite</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-female"> <p>female</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-file-add"> <p>file-add</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-file-plus"> <p>file-plus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-file-text"> <p>file-text</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-file"> <p>file</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-filter-b-and-w"> <p>filter-b-and-w</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-filter-center-focus"> <p>filter-center-focus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-filter-frames"> <p>filter-frames</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-filter-list"> <p>filter-list</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-filter-tilt-shift"> <p>filter-tilt-shift</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-fire"> <p>fire</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flag"> <p>flag</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flare"> <p>flare</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flash-auto"> <p>flash-auto</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flash-off"> <p>flash-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flash"> <p>flash</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flattr"> <p>flattr</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flickr"> <p>flickr</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flight-land"> <p>flight-land</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flight-takeoff"> <p>flight-takeoff</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flip-to-back"> <p>flip-to-back</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flip-to-front"> <p>flip-to-front</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flip"> <p>flip</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-floppy"> <p>floppy</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flower-alt"> <p>flower-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-flower"> <p>flower</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-folder-outline"> <p>folder-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-folder-person"> <p>folder-person</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-folder-shared"> <p>folder-shared</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-folder-special"> <p>folder-special</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-folder-star-alt"> <p>folder-star-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-folder-star"> <p>folder-star</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-folder"> <p>folder</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-font"> <p>font</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-align-center"> <p>format-align-center</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-align-justify"> <p>format-align-justify</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-align-left"> <p>format-align-left</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-align-right"> <p>format-align-right</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-bold"> <p>format-bold</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-clear-all"> <p>format-clear-all</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-clear"> <p>format-clear</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-color-fill"> <p>format-color-fill</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-color-reset"> <p>format-color-reset</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-color-text"> <p>format-color-text</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-indent-decrease"> <p>format-indent-decrease</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-indent-increase"> <p>format-indent-increase</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-italic"> <p>format-italic</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-line-spacing"> <p>format-line-spacing</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-list-bulleted"> <p>format-list-bulleted</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-list-numbered"> <p>format-list-numbered</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-ltr"> <p>format-ltr</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-paint"> <p>format-paint</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-playlist-add"> <p>format-playlist-add</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-queue-music"> <p>format-queue-music</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-quote"> <p>format-quote</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-rtl"> <p>format-rtl</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-size"> <p>format-size</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-strikethrough-s"> <p>format-strikethrough-s</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-strikethrough"> <p>format-strikethrough</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-subject"> <p>format-subject</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-textdirection-l-to-r"> <p>format-textdirection-l-to-r</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-textdirection-r-to-l"> <p>format-textdirection-r-to-l</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-underlined"> <p>format-underlined</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-valign-bottom"> <p>format-valign-bottom</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-valign-center"> <p>format-valign-center</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-format-valign-top"> <p>format-valign-top</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-forward-10"> <p>forward-10</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-forward-30"> <p>forward-30</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-forward-5"> <p>forward-5</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-forward"> <p>forward</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-fullscreen-alt"> <p>fullscreen-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-fullscreen-exit"> <p>fullscreen-exit</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-fullscreen"> <p>fullscreen</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-functions"> <p>functions</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-gamepad"> <p>gamepad</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-gas-station"> <p>gas-station</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-gesture"> <p>gesture</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-gif"> <p>gif</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-github-alt"> <p>github-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-github-box"> <p>github-box</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-github"> <p>github</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-globe-alt"> <p>globe-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-globe-lock"> <p>globe-lock</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-globe"> <p>globe</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-google-drive"> <p>google-drive</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-google-earth"> <p>google-earth</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-google-glass"> <p>google-glass</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-google-maps"> <p>google-maps</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-google-old"> <p>google-old</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-google-pages"> <p>google-pages</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-google-play"> <p>google-play</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-google-plus-box"> <p>google-plus-box</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-google-plus"> <p>google-plus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-google"> <p>google</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-gps-dot"> <p>gps-dot</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-gps-off"> <p>gps-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-gps"> <p>gps</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-gradient"> <p>gradient</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-graduation-cap"> <p>graduation-cap</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-grain"> <p>grain</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-graphic-eq"> <p>graphic-eq</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-grid-off"> <p>grid-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-grid"> <p>grid</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-group-work"> <p>group-work</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-group"> <p>group</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hd"> <p>hd</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hdr-off"> <p>hdr-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hdr-strong"> <p>hdr-strong</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hdr-weak"> <p>hdr-weak</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hdr"> <p>hdr</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-headset-mic"> <p>headset-mic</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-headset"> <p>headset</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hearing"> <p>hearing</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-help-outline"> <p>help-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-help"> <p>help</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-home"> <p>home</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hospital-alt"> <p>hospital-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hospital"> <p>hospital</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hotel"> <p>hotel</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hourglass-alt"> <p>hourglass-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hourglass-outline"> <p>hourglass-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hourglass"> <p>hourglass</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-hq"> <p>hq</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-http"> <p>http</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-image-alt"> <p>image-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-image-o"> <p>image-o</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-image"> <p>image</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-import-export"> <p>import-export</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-inbox"> <p>inbox</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-incandescent"> <p>incandescent</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-info-outline"> <p>info-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-info"> <p>info</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-input-antenna"> <p>input-antenna</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-input-composite"> <p>input-composite</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-input-hdmi"> <p>input-hdmi</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-input-power"> <p>input-power</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-input-svideo"> <p>input-svideo</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-instagram"> <p>instagram</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-invert-colors-off"> <p>invert-colors-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-invert-colors"> <p>invert-colors</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-iridescent"> <p>iridescent</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-key"> <p>key</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-keyboard-hide"> <p>keyboard-hide</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-keyboard"> <p>keyboard</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-label-alt-outline"> <p>label-alt-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-label-alt"> <p>label-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-label-heart"> <p>label-heart</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-label"> <p>label</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-labels"> <p>labels</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-lamp"> <p>lamp</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-landscape"> <p>landscape</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-language-css3"> <p>language-css3</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-language-html5"> <p>language-html5</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-language-javascript"> <p>language-javascript</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-language-python-alt"> <p>language-python-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-language-python"> <p>language-python</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-laptop-chromebook"> <p>laptop-chromebook</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-laptop-mac"> <p>laptop-mac</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-laptop"> <p>laptop</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-lastfm"> <p>lastfm</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-layers-clear"> <p>layers-clear</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-layers-off"> <p>layers-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-layers"> <p>layers</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-leak-off"> <p>leak-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-leak-remove"> <p>leak-remove</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-leak"> <p>leak</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-library"> <p>library</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-link"> <p>link</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-linkedin-box"> <p>linkedin-box</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-linkedin"> <p>linkedin</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-live-tv"> <p>live-tv</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-activity"> <p>local-activity</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-airport"> <p>local-airport</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-atm"> <p>local-atm</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-bar"> <p>local-bar</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-cafe"> <p>local-cafe</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-car-wash"> <p>local-car-wash</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-convenience-store"> <p>local-convenience-store</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-dining"> <p>local-dining</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-drink"> <p>local-drink</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-florist"> <p>local-florist</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-gas-station"> <p>local-gas-station</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-grocery-store"> <p>local-grocery-store</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-hospital"> <p>local-hospital</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-hotel"> <p>local-hotel</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-laundry-service"> <p>local-laundry-service</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-library"> <p>local-library</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-mall"> <p>local-mall</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-movies"> <p>local-movies</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-offer"> <p>local-offer</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-parking"> <p>local-parking</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-pharmacy"> <p>local-pharmacy</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-phone"> <p>local-phone</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-pizza"> <p>local-pizza</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-play"> <p>local-play</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-post-office"> <p>local-post-office</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-printshop"> <p>local-printshop</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-see"> <p>local-see</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-shipping"> <p>local-shipping</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-store"> <p>local-store</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-taxi"> <p>local-taxi</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-local-wc"> <p>local-wc</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-lock-open"> <p>lock-open</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-lock-outline"> <p>lock-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-lock"> <p>lock</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-long-arrow-down"> <p>long-arrow-down</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-long-arrow-left"> <p>long-arrow-left</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-long-arrow-return"> <p>long-arrow-return</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-long-arrow-right"> <p>long-arrow-right</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-long-arrow-tab"> <p>long-arrow-tab</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-long-arrow-up"> <p>long-arrow-up</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-looks"> <p>looks</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-loupe"> <p>loupe</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mail-reply-all"> <p>mail-reply-all</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mail-reply"> <p>mail-reply</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mail-send"> <p>mail-send</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-male-alt"> <p>male-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-male-female"> <p>male-female</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-male"> <p>male</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mall"> <p>mall</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-map"> <p>map</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-markunread-mailbox"> <p>markunread-mailbox</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-memory"> <p>memory</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-menu"> <p>menu</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mic-off"> <p>mic-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mic-outline"> <p>mic-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mic-setting"> <p>mic-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mic"> <p>mic</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-minus-circle-outline"> <p>minus-circle-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-minus-circle"> <p>minus-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-minus-square"> <p>minus-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-minus"> <p>minus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-money-box"> <p>money-box</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-money-off"> <p>money-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-money"> <p>money</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mood-bad"> <p>mood-bad</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mood"> <p>mood</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-more-horiz"> <p>more-horiz</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-more-vert"> <p>more-vert</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-more"> <p>more</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-mouse"> <p>mouse</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-movie-alt"> <p>movie-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-movie"> <p>movie</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-my-location"> <p>my-location</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-n-1-square"> <p>n-1-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-n-2-square"> <p>n-2-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-n-3-square"> <p>n-3-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-n-4-square"> <p>n-4-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-n-5-square"> <p>n-5-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-n-6-square"> <p>n-6-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-nature-people"> <p>nature-people</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-nature"> <p>nature</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-navigation"> <p>navigation</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-neg-1"> <p>neg-1</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-neg-2"> <p>neg-2</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-alert"> <p>network-alert</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-locked"> <p>network-locked</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-off"> <p>network-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-outline"> <p>network-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-setting"> <p>network-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-warning"> <p>network-warning</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-wifi-alt"> <p>network-wifi-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-wifi-info"> <p>network-wifi-info</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-wifi-lock"> <p>network-wifi-lock</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-wifi-off"> <p>network-wifi-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-wifi-outline"> <p>network-wifi-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-wifi-scan"> <p>network-wifi-scan</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network-wifi"> <p>network-wifi</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-network"> <p>network</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-nfc"> <p>nfc</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-notifications-active"> <p>notifications-active</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-notifications-add"> <p>notifications-add</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-notifications-none"> <p>notifications-none</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-notifications-off"> <p>notifications-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-notifications-paused"> <p>notifications-paused</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-notifications"> <p>notifications</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-odnoklassniki"> <p>odnoklassniki</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-open-in-browser"> <p>open-in-browser</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-open-in-new"> <p>open-in-new</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-outlook"> <p>outlook</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pages"> <p>pages</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-palette"> <p>palette</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-panorama-horizontal"> <p>panorama-horizontal</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-panorama-vertical"> <p>panorama-vertical</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-panorama-wide-angle"> <p>panorama-wide-angle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-parking"> <p>parking</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-paste"> <p>paste</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pause-circle-outline"> <p>pause-circle-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pause-circle"> <p>pause-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pause"> <p>pause</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-paypal-alt"> <p>paypal-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-paypal"> <p>paypal</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-bluetooth"> <p>phone-bluetooth</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-end"> <p>phone-end</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-forwarded"> <p>phone-forwarded</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-in-talk"> <p>phone-in-talk</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-locked"> <p>phone-locked</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-missed"> <p>phone-missed</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-msg"> <p>phone-msg</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-paused"> <p>phone-paused</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-ring"> <p>phone-ring</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-setting"> <p>phone-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone-sip"> <p>phone-sip</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-phone"> <p>phone</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-photo-size-select-large"> <p>photo-size-select-large</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-photo-size-select-small"> <p>photo-size-select-small</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-picture-in-picture"> <p>picture-in-picture</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pin-account"> <p>pin-account</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pin-assistant"> <p>pin-assistant</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pin-drop"> <p>pin-drop</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pin-help"> <p>pin-help</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pin-off"> <p>pin-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pin"> <p>pin</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pinterest-box"> <p>pinterest-box</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pinterest"> <p>pinterest</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pizza"> <p>pizza</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-plaster"> <p>plaster</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-play-circle-outline"> <p>play-circle-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-play-circle"> <p>play-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-play-for-work"> <p>play-for-work</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-play"> <p>play</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-playlist-audio"> <p>playlist-audio</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-playlist-plus"> <p>playlist-plus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-playstation"> <p>playstation</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-plus-1"> <p>plus-1</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-plus-2"> <p>plus-2</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-plus-box"> <p>plus-box</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-plus-circle-o-duplicate"> <p>plus-circle-o-duplicate</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-plus-circle-o"> <p>plus-circle-o</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-plus-circle"> <p>plus-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-plus-square"> <p>plus-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-plus"> <p>plus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-pocket"> <p>pocket</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-polymer"> <p>polymer</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-portable-wifi-changes"> <p>portable-wifi-changes</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-portable-wifi-off"> <p>portable-wifi-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-portable-wifi"> <p>portable-wifi</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-power-input"> <p>power-input</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-power-off-setting"> <p>power-off-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-power-off"> <p>power-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-power-setting"> <p>power-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-power"> <p>power</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-present-to-all"> <p>present-to-all</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-print"> <p>print</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-puzzle-piece"> <p>puzzle-piece</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-quote"> <p>quote</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-radio"> <p>radio</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-railway"> <p>railway</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-rate-review"> <p>rate-review</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-reader"> <p>reader</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-receipt"> <p>receipt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-reddit"> <p>reddit</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-redo"> <p>redo</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-refresh-alt"> <p>refresh-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-refresh-sync-alert"> <p>refresh-sync-alert</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-refresh-sync-disabled"> <p>refresh-sync-disabled</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-refresh-sync-off"> <p>refresh-sync-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-refresh-sync-problem"> <p>refresh-sync-problem</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-refresh-sync"> <p>refresh-sync</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-refresh"> <p>refresh</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-remote-control-alt"> <p>remote-control-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-remote-control"> <p>remote-control</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-reorder"> <p>reorder</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-repeat-one"> <p>repeat-one</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-repeat"> <p>repeat</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-replay-10"> <p>replay-10</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-replay-30"> <p>replay-30</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-replay-5"> <p>replay-5</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-replay"> <p>replay</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-roller"> <p>roller</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-rotate-90-degrees-ccw"> <p>rotate-90-degrees-ccw</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-rotate-ccw"> <p>rotate-ccw</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-rotate-cw"> <p>rotate-cw</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-rotate-left"> <p>rotate-left</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-rotate-right"> <p>rotate-right</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-router"> <p>router</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-rss"> <p>rss</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-ruler"> <p>ruler</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-run"> <p>run</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-satellite"> <p>satellite</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-save"> <p>save</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-scanner"> <p>scanner</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-scissors"> <p>scissors</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-screen-rotation-lock"> <p>screen-rotation-lock</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-screen-rotation"> <p>screen-rotation</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-search-for"> <p>search-for</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-search-in-file"> <p>search-in-file</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-search-in-page"> <p>search-in-page</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-search-replace"> <p>search-replace</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-search"> <p>search</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-seat"> <p>seat</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-sec-10"> <p>sec-10</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-sec-3"> <p>sec-3</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-select-all"> <p>select-all</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-settings-square"> <p>settings-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-settings"> <p>settings</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-shape"> <p>shape</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-share"> <p>share</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-shield-check"> <p>shield-check</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-shield-security"> <p>shield-security</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-shopping-basket"> <p>shopping-basket</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-shopping-cart-add"> <p>shopping-cart-add</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-shopping-cart-plus"> <p>shopping-cart-plus</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-shopping-cart"> <p>shopping-cart</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-shuffle"> <p>shuffle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-sign-in"> <p>sign-in</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-skip-next"> <p>skip-next</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-skip-previous"> <p>skip-previous</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-skype"> <p>skype</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-slideshare"> <p>slideshare</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-slideshow"> <p>slideshow</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-android"> <p>smartphone-android</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-code"> <p>smartphone-code</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-download"> <p>smartphone-download</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-erase"> <p>smartphone-erase</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-info"> <p>smartphone-info</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-iphone"> <p>smartphone-iphone</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-landscape-lock"> <p>smartphone-landscape-lock</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-landscape"> <p>smartphone-landscape</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-lock"> <p>smartphone-lock</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-portrait-lock"> <p>smartphone-portrait-lock</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-portrait"> <p>smartphone-portrait</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-ring"> <p>smartphone-ring</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-setting"> <p>smartphone-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone-setup"> <p>smartphone-setup</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-smartphone"> <p>smartphone</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-sort-amount-asc"> <p>sort-amount-asc</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-sort-amount-desc"> <p>sort-amount-desc</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-sort-asc"> <p>sort-asc</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-sort-by-alpha"> <p>sort-by-alpha</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-sort-desc"> <p>sort-desc</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-sort"> <p>sort</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-soundcloud"> <p>soundcloud</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-space-bar"> <p>space-bar</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-speaker"> <p>speaker</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-spellcheck"> <p>spellcheck</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-spinner"> <p>spinner</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-square-down"> <p>square-down</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-square-o"> <p>square-o</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-square-right"> <p>square-right</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-stack-overflow"> <p>stack-overflow</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-stackoverflow"> <p>stackoverflow</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-star-border"> <p>star-border</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-star-circle"> <p>star-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-star-half"> <p>star-half</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-star-outline"> <p>star-outline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-star"> <p>star</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-steam-square"> <p>steam-square</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-steam"> <p>steam</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-stop"> <p>stop</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-storage"> <p>storage</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-store-24"> <p>store-24</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-store"> <p>store</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-subway"> <p>subway</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-sun"> <p>sun</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-surround-sound"> <p>surround-sound</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-swap-alt"> <p>swap-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-swap-vertical-"> <p>swap-vertical-</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-swap-vertical-circle"> <p>swap-vertical-circle</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-swap-vertical"> <p>swap-vertical</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-swap"> <p>swap</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tab-unselected"> <p>tab-unselected</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tab"> <p>tab</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tablet-android"> <p>tablet-android</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tablet-mac"> <p>tablet-mac</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tablet"> <p>tablet</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tag-backspace"> <p>tag-backspace</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tag-close"> <p>tag-close</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tag-more"> <p>tag-more</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tag"> <p>tag</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tap-and-play"> <p>tap-and-play</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-text-format"> <p>text-format</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-texture"> <p>texture</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-thumb-down"> <p>thumb-down</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-thumb-up-down"> <p>thumb-up-down</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-thumb-up"> <p>thumb-up</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-ticket-star"> <p>ticket-star</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-time-countdown"> <p>time-countdown</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-time-interval"> <p>time-interval</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-time-restore-setting"> <p>time-restore-setting</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-time-restore"> <p>time-restore</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-time"> <p>time</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-timer-off"> <p>timer-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-timer"> <p>timer</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-toll"> <p>toll</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tonality"> <p>tonality</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-toys"> <p>toys</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-traffic"> <p>traffic</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-transform"> <p>transform</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-translate"> <p>translate</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-trending-down"> <p>trending-down</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-trending-flat"> <p>trending-flat</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-trending-up"> <p>trending-up</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-triangle-down"> <p>triangle-down</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-triangle-up"> <p>triangle-up</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-truck"> <p>truck</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tumblr"> <p>tumblr</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tune"> <p>tune</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-turning-sign"> <p>turning-sign</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tv-alt-play"> <p>tv-alt-play</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tv-list"> <p>tv-list</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tv-play"> <p>tv-play</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-tv"> <p>tv</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-twitch"> <p>twitch</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-twitter-box"> <p>twitter-box</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-twitter"> <p>twitter</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-undo"> <p>undo</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-unfold-less"> <p>unfold-less</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-unfold-more"> <p>unfold-more</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-ungroup"> <p>ungroup</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-upload"> <p>upload</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-usb"> <p>usb</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-vibration"> <p>vibration</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-videocam-off"> <p>videocam-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-videocam-switch"> <p>videocam-switch</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-videocam"> <p>videocam</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-agenda"> <p>view-agenda</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-array"> <p>view-array</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-carousel"> <p>view-carousel</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-column"> <p>view-column</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-comfy"> <p>view-comfy</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-compact"> <p>view-compact</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-dashboard"> <p>view-dashboard</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-day"> <p>view-day</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-headline"> <p>view-headline</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-list-alt"> <p>view-list-alt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-list"> <p>view-list</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-module"> <p>view-module</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-quilt"> <p>view-quilt</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-stream"> <p>view-stream</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-subtitles"> <p>view-subtitles</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-toc"> <p>view-toc</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-web"> <p>view-web</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-view-week"> <p>view-week</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-vignette"> <p>vignette</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-vimeo"> <p>vimeo</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-vk"> <p>vk</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-voicemail"> <p>voicemail</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-volume-down"> <p>volume-down</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-volume-mute"> <p>volume-mute</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-volume-off"> <p>volume-off</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-volume-up"> <p>volume-up</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-walk"> <p>walk</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-wallpaper"> <p>wallpaper</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-washing-machine"> <p>washing-machine</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-watch"> <p>watch</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-wb-auto"> <p>wb-auto</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-widgets"> <p>widgets</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-window-maximize"> <p>window-maximize</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-window-minimize"> <p>window-minimize</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-window-restore"> <p>window-restore</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-wrap-text"> <p>wrap-text</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-wrench"> <p>wrench</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-zero"> <p>zero</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-zoom-in"> <p>zoom-in</p> </Icon>
      <Icon key={uid(4)} {...i} icon="md-zoom-out"> <p>zoom-out</p> </Icon>
    </Page>
  );
};

const Icons = (props) => {
  return (
    <Page id="IconsPage">
      <Sink />
    </Page>
  );
};

export default Icons;
