import React, { useState, useEffect } from "react";
import {useAbilityContext} from "../useAbilityContext"
import { BottomToolbar, Icon, Fab, Toolbar } from "react-onsenui";
import { ActionSheet, ActionSheetButton } from "react-onsenui";
import { Box} from "grommet";
import { Logout, store, uid, fetchMyShifts } from "../psrs";
import _ from "lodash";
import * as Sentry from '@sentry/browser'

export const BottomNav = (props) => {
  const { navigator } = props;
  const ability = useAbilityContext();
  const [navOpen, setNavOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false)

  const DEBUG = false 

  const handleAction = async (e) => {
    const currentView = store.get("View");
    const view = e.target.getAttribute("view");

    if (currentView === view && view !== "Login") {
      setNavOpen(false);
      return false
    }

    DEBUG && console.log("handleAction navigator", navigator);
    const route = { view };
    const exclude = ['Shift', view]
    const routes = navigator.routes.filter(r => !exclude.includes(r.view));
    routes.push(route);
    store.set('RouteStack', routes)
    DEBUG && console.log("handleAction routes", routes)
    if (view === "Login") {
      //alert('action logout')
      console.log('ACTION LOGOUT ...');
      store.set("RouteStack", [{ view: "Login" }]);
      await navigator.resetPageStack([{ view: "Login" }])
      Logout()
      // yes no? store.session.clear();
      //store.clearAll()
    } else {
      navigator.resetPageStack(_.uniqBy(routes, "view"));
    }
    setNavOpen(false);
  };

  const buttonProps = {
    swipeable: true,
    onClick: handleAction,
  };

  const canVisitSettings = ability.can("visit:settings")
  const canVisitEmployees = ability.can("visit:employees")
  const canVisitLogout = ability.can("visit:logout");
  const canVisitShifts = ability.can("visit:shifts");
  const canVisitAvailable = false
  const canVisitApprovals = true
  const canVisitMyApprovals = true
  const canVisitIcons = window.location.hostname === "localhost"
  const canVisitFeedback = ability.can("visit:Feedback")
   
  useEffect(() => {
    //let isMounted = true
    if (!isMounted) setIsMounted(true)
    // return () => {
    //   //isMounted = false
    //   setIsMounted(false)
    // }
  }, [])

  return (
    <BottomToolbar>
      <Box
        justify="center"
        align="center"
        fill
        direction="row"
        onClick={() => setNavOpen(!navOpen)}
      >
        <Box>
          <Icon  icon="md-menu" size={20} />
        </Box>
      </Box>
      <ActionSheet isOpen={navOpen}>
        <ActionSheetButton className="ActionSheetTitle"> <b>Navigation</b> </ActionSheetButton>
        { !!canVisitSettings && <ActionSheetButton {...{...buttonProps, icon: 'md-card' }} action="pushPage" view="Employee"> Settings </ActionSheetButton> }
        { !!canVisitEmployees && <ActionSheetButton { ...{...buttonProps, icon: 'md-account'}} action="pushPage" view="Employees"> Employees </ActionSheetButton> }
          { !!canVisitApprovals && <ActionSheetButton { ...{...buttonProps, icon: 'md-assignment-check' }} action="pushPage" view="Approvals"> Approvals </ActionSheetButton> }
          { !!canVisitMyApprovals && <ActionSheetButton { ...{...buttonProps, icon: "md-calendar-check" }} action="pushPage" view="MyApprovals"> My Approvals </ActionSheetButton> }
          { !!canVisitShifts && <ActionSheetButton { ...{...buttonProps, icon: 'md-calendar'} } action="pushPage" view="MyShifts"> Shifts </ActionSheetButton> }
          { !!canVisitAvailable && <ActionSheetButton {...buttonProps} action="pushPage" view="Available"> Available Shifts </ActionSheetButton> }
          {/*{ !!canVisitMyApprovals && <ActionSheetButton { ...{...buttonProps, icon: 'md-alarm-add'} } action="pushPage" view="NewShift"> Add Shift </ActionSheetButton> }*/}
          { !!canVisitIcons && <ActionSheetButton { ...buttonProps } action="pushPage" view="Icons"> Icons </ActionSheetButton> }
          { !!canVisitLogout && <ActionSheetButton { ...{...buttonProps, icon: 'md-power-off' } } action="resetPageStack" view="Login" > Logout </ActionSheetButton> }
        <ActionSheetButton {...{icon: "md-help-outline" }} onClick={() => {
            setNavOpen(false)
            window.open("https://psrswiki.enterprisetechnologies.com/en/Mobile",'wikiTab')
        }} >
          Help
        </ActionSheetButton>        
        { !!canVisitFeedback && <ActionSheetButton {...{...buttonProps, icon: "md-email" }} action="pushPage" view="Feedback">Feedback</ActionSheetButton> }
        <ActionSheetButton onClick={ () => setNavOpen(false) }> Close </ActionSheetButton>
      </ActionSheet>
    </BottomToolbar>
  );
};

