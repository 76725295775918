import React from "react";
import { Select } from "react-onsenui";
import { store } from "../psrs";

export const EmployeeSelect = (props) => {
  const { shift, setShift } = props;

  const Employees = store.get("Employees");

  const renderOptions = () => {
    return Employees.map((item) => (
      <option key={`employee${item.id}`} value={item.number}>
        {item.fullName}
      </option>
    ));
  };

  const selectOptions = {
    modifier: null,
    disabled: false,
    value: shift.employeeNumber,
    onChange: (employeeNumber) => setShift({ ...shift, employeeNumber }),
    multiple: false,
    autofocus: false,
    required: false,
  };

  return <Select {...selectOptions}>{renderOptions()}</Select>;
};
