import React, {useState} from 'react'
import {Select, CaretIcon, ModalCloseButton} from 'react-responsive-select'
import {store, truncString} from '../psrs'
import _ from 'lodash'

// for default styles...
import "react-responsive-select/dist/react-responsive-select.css";

export const SelectSick = (props) => {
  const {timeEntry, update, selectType} = props
  const {payTypeCode, id} = timeEntry
  
  const category = selectType.toUpperCase()


  let Records = store.get('PayTypes').filter((r) => !!r.included )
  if ( category && category !== 'REGULAR') {
    Records = store.get('PayTypes').filter((r) => !!r.included && r.category === category || r.code === '380')
  }

  const options = _.sortBy(Records.map((r) => ({sort: parseInt(r.code), value: r.code, text: `${r.code} - ${truncString(r.rename,20)}`, markup: <small>{r.code} - {r.rename}</small>, disabled: r.code === '380' ? true : false})), ['sort'])


  //const options = _.sortBy(Records.map((r) => ({sort: parseInt(r.code), value: r.code, text: `${r.code} - ${truncString(r.rename ? r.rename : r.description,30)}`, disabled: r.code === '380' ? true : false})), ['sort'])

  //- original const Records = store.get('PayTypes').filter((r) => !!r.included)
  //- original const options = _.sortBy(Records.map((r) => ({sort: parseInt(r.code), value: r.code, text: `${r.code} - ${r.description}`, disabled: r.code === '380' ? true : false })), ['sort'])
 
  
 
  const disabled = props.disabled
  options.unshift({value: 'cancel', text: 'CANCEL'})

  const [selected, setSelected] = useState(payTypeCode || undefined)

  const handleChange = ({name, text, value, altered}) => {
    if (value === 'cancel' || value === '380') {
      setSelected(payTypeCode || undefined)
    } else if (value !== 'cancel' && !!altered) {
      setSelected(value)
      update({field: 'payTypeCode', id: timeEntry.id, value})
    } 
  }

  const handleSubmit = (value) => {
    console.log('SELECT onSubmit', value)
  }

  return (
    <Select   
      disabled={disabled || timeEntry.payTypeCode === '380'} 
      name='payTypeCode' 
      //prefix='Pay Type: ' 
      noSelectionLabel='Please select' 
      modalCloseButton={<ModalCloseButton />} 
      options={options} 
      caretIcon={<CaretIcon />} 
      selectedValue={selected} 
      onChange={handleChange} 
      //onBlur={handleChange}
      onSubmit={handleSubmit} 
    />
  )
}
