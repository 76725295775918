import React, {useState, useEffect, useRef} from 'react'
// import { Hook, Console, Decode } from "console-feed"
import {Button, Page, Card, Toolbar, Input, Toast, AlertDialog, AlertDialogButton, Modal, BackButton,ToolbarButton, Icon} from 'react-onsenui'
import {Box, Text} from 'grommet'
import PullToRefresh from 'react-simple-pull-to-refresh';
      
import {store, RequestCode, CodeLogin, Login, fetchMyShifts} from '../psrs'

import appLogo from '../img/schedulerpro_full_top_small.png'
import appLogoX from '../img/tps-reports.jpeg'
import _ from 'lodash'

const DEBUG = false

const LoginPage = (props) => {
  const {navigator, route} = props
  const currentUser = store.get('CurrentUser')
  // store.remove('TOKEN')
  // store.remove('currentUser')

  const [logs, setLogs] = useState([])
  const [toast, setToast] = useState({open: false, text: ''})

  const [state, setState] = useState({
    email: currentUser ? currentUser.email : null, // "THavellana@ci.oceanside.ca.us",
    //email: 'THavellana@ci.oceanside.ca.us',
    password: store.session.get('Password') || null, //'opd!Test1',  //'opdtest1',
    user: 'employee',
    data: [],
    error: null,
    disabled: false,
    phone: store.get('phone') || null, //'7606811607',
    disableVerify: false,
    showVerify: false,
    logginIn: false,
    loginBtnText: 'Login', 
  })

  const title = () => (
    <Toolbar>
      <div className='center'>Login</div>
    </Toolbar>
  )



  const [loginAlertOpen, setLoginAlertOpen] = useState(false)
  const handleAction = (e) => {
    setLoginAlertOpen(false)
    setState({...state, password: null, disabled: false, error: 'Not Authorized for Mobile'})
    navigator.replacePage({view: 'Login'})

  }
  

  const handleChange = (e) => {
    //const type = event.target.getAttribute('type')
    const type = e.target.getAttribute('type')
    DEBUG && console.log('TYPE', type)
    const update = {...state}
    //update[type] = event.target.value
    update[type] = e.target.value
    //(update.email && update.password) ? update['disabled'] = false : updated['disabled'] = true
    update['disabled'] = false

    setState(update)
  }


//const _handleChange = _.debounce(handleChange, 1000, {})

  // Hook(window.console, (log) => {
  //   setLogs([...logs, Decode(log)])
  // })

  const requestCode = async () => {
    DEBUG && console.log('REQUEST CODE')
    store.set('Phone', state.phone)
    const requested = await RequestCode(state.phone)
    setState({...state, disableVerify: false, showVerify: true, error: false})
    DEBUG &&  console.log('REQUESTED CODE', requested)
  }

  const whatNow = async (authenticated) => {
    const mobileEnabled = store.get("mobileEnabled")

    if (!mobileEnabled) {
      setLoginAlertOpen(true)
    } else {
      setState({...state, disableVerify: false, error: false, disabled: false})
      const empNum = store.get('CurrentUser').number
      const shifts = await fetchMyShifts(empNum)
      store.set('ViewData', shifts || [])
      navigator.replacePage({view: 'MyShifts'})
    }
    
  }

  const verifyCode = async () => {
    setState({...state, disableVerify: true, error: false})
    const authenticated = await CodeLogin(state.phone, state.code)
    if (!authenticated) {
      setToast({open: true, text: 'Invalid Code or Phone Number ... Try again'})
      setState({...state, showVerify: false, disableVerify: false, error: 'Invalid Code or Phone Number ... Try again'})
      setTimeout(() => setToast({...toast, open: false}), 6000)
    } else whatNow(authenticated)
  }

  const login = async () => {
    setState({...state, disabled: true, loginBtnText: 'Logging In ...'})
    const email = state.email
    const password = state.password
    //store.set('Email', email)
    //store.session.set('Password', password)

    let authenticated = false
    if (password !== null) {
      authenticated = await Login(email, password)
      store.set('Email', email)
      store.session.set('Password', password)
    } 
   
    if (!authenticated) {
      setToast({open: true, text: 'Login Error wrong password or email'})
      setState({...state, password: null, disabled: false, error: 'Login Error wrong password or email'})
      setTimeout(() => setToast({...toast, open: false}), 6000)
    } else {
       whatNow(authenticated)
    }
  }

  const Environment = process.env.NODE_ENV || 'Production'
  //console.log('***blw -- ***  --> NODE_ENV: ', process.env.NODE_ENV )
  //console.log('***blw -- ***  --> REACT_APP_ENV: ', process.env.REACT_APP_ENV )

  let envFontColor = 'black'
  let envLabel = 'Production'

  if (Environment.toLowerCase() === 'local') {
    envFontColor = 'lightseagreen'
    envLabel = 'Local'
  }

  if (Environment.toLowerCase() === 'dv' || Environment.toLocaleLowerCase() === 'development') {
    envFontColor = 'blue'
    envLabel = 'Development'
  } 

  if (Environment.toLowerCase() === 'qa') { 
    envFontColor = 'red'
    envLabel = 'QA'
  }

  const loginStyle = {
    p: {
      overflow: "hidden",
      display: "flex"
    },
    hover: {
      span: {
        opacity: 1
      }
    },
    span:  {
      transition: 'all 0.3s ease-out',
      width: 0,
      opacity: 0,
      display: 'inline-flex',
      overflow: 'hidden'
    }
  }

  const ref = useRef(null);
  const onDivClick = (event) => {
    if (event.target.closest('p')) {
      event.target.closest('p').querySelectorAll('span').forEach(el => {
        if (el.style.width !== '0px') {
          el.style.width = 0;
          el.style.opacity = 0;
        } else {
          el.style.width = el.scrollWidth + 'px';
          el.style.opacity = 1;
        }
      })
    }
  }

  useEffect(() => {
    if (ref.current && ref.current.click) {
      setTimeout(() => {
        ref && ref.current && ref.current.click ? ref.current.click() : null //<-- thowing error reading "click" 
      }, 2000); //
    }
  }, [])


  //              {(state.email && state.password) ? 'Login' : !!state.disabled ? 'Logging in ...' : 'Login'}

  return (
    <Page id='LoginPage' renderToolbar={title}>
      <PullToRefresh style={{overflow: "visible"}} onRefresh={handleAction}>
        <Toast isOpen={toast.open} animation="fall">
          <div style={{textAlign: 'center'}}>{toast.text}</div>
        </Toast>
        <Card className='loginCard'>
          <div style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center", display: "table-cell", verticalAlign: "middle", textAlign: "center", width: "200px", height: "200px"}}>
           <p ref={ref} onClick={onDivClick}>
              <strong><label style={{fontSize: "30px", color: "#38B6FF"}}>Oceanside</label></strong><br />
              <label style={{fontSize: "20px"}}>T</label>
              <span style={loginStyle.span}><label style={{color: "#C0C0C0", fontSize: "18px"}}>ime</label></span>
              <span style={loginStyle.span}><label style={{color: "#C0C0C0", fontSize: "18px"}}>&nbsp;&&nbsp;</label></span>
              <label style={{fontSize: "20px"}}>P</label>
              <span style={loginStyle.span}><label style={{color: "#C0C0C0", fontSize: "18px"}}>ay&nbsp;</label></span>
              <label style={{fontSize: "20px"}}>S</label>
              <span style={loginStyle.span}><label style={{color: "#C0C0C0", fontSize: "18px"}}>ystem</label></span>
              <br /> 
            </p>
            {/* <p>
              <strong><label style={{fontSize: "30px", color: "#38B6FF"}}>Oceanside</label></strong><br />
              <label ref={ref} onClick={onDivClick} style={{fontSize: "20px"}}>T</label>
              <label label style={{color: "#C0C0C0", fontSize: "18px"}}>ime</label>
              {' '}<label style={{color: "#C0C0C0", fontSize: "18px"}}>&</label> {' '}
              <label style={{fontSize: "20px"}}>P</label>
              <label style={{color: "#C0C0C0", fontSize: "18px"}}>ay</label>{' '}
              <label style={{fontSize: "20px"}}>S</label>
              <label style={{color: "#C0C0C0", fontSize: "18px"}}>ystem</label>
              <br /> 
            </p> */}
            <img src={appLogo} alt='SchedulerPro Logo' style={{ width: "150px", height: "150px"}} />
          </div>
          <div>
            <Input type='email' value={state.email} className='loginInput' modifier='underbar' onChange={handleChange} placeholder='somebody@somewhere.ca.us' />
          </div>
          <div>
            <Input type='password' autocomplete='current-password' value={state.password} className='loginInput' modifier='underbar' onChange={handleChange} placeholder='password' />
          </div>
          <Box>
            {!!state.error && (
              <Box pad='large'>
                <Text textAlign='center'>{state.error}</Text>
              </Box>
            )}
            <Button modifier='large' onClick={login} disabled={ state.disabled }>
              {state.loginBtnText}
            </Button>
          </Box>
        </Card>
        <Card>
          <Box gap='medium'>
            <Box>
              <Input 
                //type='phone'
                type='text'
                pattern='\d*'
                modifier='underbar' 
                placeholder='Phone Number' 
                defaultValue={state.phone} 
                onChange={(e) => setState({...state, phone: e.target.value})}
              />
            </Box>
            {state.showVerify && (
              <Box>
                {/* <input type='number' name='token' id='token' /> */}
                <Input
                  // disabled={!state.code}
                  type='text'
                  pattern='[0-9]*'
                  inputmode='numeric'
                  modifier='underbar'
                  placeholder='SMS CODE'
                  autocomplete='one-time-code'
                  onChange={(e) => setState({...state, code: e.target.value})}
                />
              </Box>
            )}
            <Box>
              {!state.showVerify && (
                <Button disabled={state.disableVerify} modifier='large' onClick={() => requestCode()}>
                  Request Code
                </Button>
              )}
            </Box>
            <Box>
              {state.showVerify && (
                <Button modifier='large' onClick={() => verifyCode()} disabled={state.disableVerify}>
                  {state.disableVerify ? 'Logging In' : 'Submit Code'}
                </Button>
              )}
              {!!state.error && <span className='error'>{state.error}</span>}
            </Box>
          </Box>
        </Card>
        {/* <div style={{ backgroundColor: "#242424" }}>
          <Console logs={logs} variant="dark" />
        </div> */}
        <Card>
          <Box 
            style={{ textAlign: "center"}}
            onClick={() => window.open("https://psrswiki.enterprisetechnologies.com/en/about/MobileReleaseNotes",'relelaseTab')}
            >
              <div style={{display: "flex", justifyContent: "center"}}>
                <table style={{alignSelf: "center"}}>
                  <thead>
                    <tr>
                      <td>
                        <small><strong style={{color: envFontColor}}>{envLabel}</strong></small><br />
                        <small><strong>v1.0.2</strong></small>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </Box>
        </Card>
      
      </PullToRefresh>
        <Modal style={{background: "white"}} isOpen={loginAlertOpen} >
              <Card>
                <div><h3 style={{color: "black"}}>Not authorized to mobile</h3>Warning!</div>
              </Card>
              <Card>
                <Box>
                  <Box gap='medium'>
                    <Text style={{color: "black"}}>Mobile is in preivew.  Please contact your administrator to request access</Text>
                    <br/>
                  </Box>
                  <Button ripple={true} modifier="large" onClick={handleAction}>close</Button>
                </Box>
              </Card>
        </Modal>
      

    </Page>
  )
}

export default LoginPage
