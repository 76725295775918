import React from "react"
import TimePicker from 'rc-time-picker'
import {Input} from 'react-onsenui'
import moment from "moment"

export const StartTimeSelect = (props) => {
  const {
    disabled,
    value, 
    modifier,
    onChange, 
  } = props

  const selectOptions = {
    disabled: disabled,
    readOnly: false,
    defaultValue: moment().format('YYYY-MM-DD'),
    value: value,
    //placeHolder: 'Select Start Time',
    type: 'date',
    inputId: 'startDate',
    float: true,
    modifier: modifier || '',
    onChange: event => onChange({field: "start", value: event.target.value})
  }

  return <Input {...selectOptions} />
}

