import React from "react";
import { Select, CaretIcon, ModalCloseButton } from "react-responsive-select";
import { store } from "../psrs";
import moment from "moment"

// for default styles...
import "react-responsive-select/dist/react-responsive-select.css";

export const SelectPayPeriod = (props) => {
  const Records = store.get("PayPeriods");
  const options = Records.map((r) => ({ value: r.id, text: `${moment(r.start).format('MM-DD-YYYY')} - ${moment(r.end).format('MM-DD-YYYY')}` }));
  const handleChange = ({ name, text, value, altered }) => {
    if (!!altered) {
      console.log("SELECT onChange", value);
    }
  };
  const handleSubmit = (value) => {
    console.log("SELECT onSubmit", value);
  };

  return (
    <Select
      name="payPeriodId"
      prefix="PayPeriod: "
      noSelectionLabel="Please select"
      modalCloseButton={<ModalCloseButton />}
      options={options}
      caretIcon={<CaretIcon />}
      selectedValue={props.value || undefined}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

