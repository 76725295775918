import React from "react"
import { Approvals } from "./Approvals"

export const EmpApprovals = props => {
  // const { route } = props
  // const myShiftProps = {...props, route: { view: "MyShifts"}, shifts: route.data.shifts}
  return <Approvals { ...props } />
}

export default EmpApprovals
