import React, {useState} from 'react'
import {Select, CaretIcon, ModalCloseButton} from 'react-responsive-select'
import {store} from '../psrs'

// for default styles...
// import "react-responsive-select/dist/react-responsive-select.css";

export const SelectBusinessUnit = (props) => {
  const {timeEntry, update} = props
  const Records = store.get('BusinessUnits').filter((r) => !!r.included)
  const options = Records.map((r) => ({
    value: timeEntry.businessUnitCode,
    text: `${r.code} - ${r.name}`,
  }))
  const disabled = props.disabled
  options.unshift({value: 'cancel', text: 'CANCEL'})

  const [selected, setSelected] = useState(timeEntry.costCenterChargeOutCode || timeEntry.businessUnitCode)

  const handleChange = ({name, text, value, altered}) => {
    if (value === 'cancel') {
      setSelected(timeEntry.costCenterChargeOutCode || timeEntry.businessUnitCode)
    } else if (value !== 'cancel' && !!altered) {
      setSelected(value)
      update({field: 'costCenterChargeOutCode', id: timeEntry.id, value})
    }
  }

  const handleSubmit = (value) => {
    console.log('SELECT onSubmit', value)
  }

  return <Select disabled={disabled} name='businessUnitCode' prefix='Business Unit: ' noSelectionLabel='Please select' modalCloseButton={<ModalCloseButton />} options={options.reverse()} caretIcon={<CaretIcon />} selectedValue={selected} onChange={handleChange} onSubmit={handleSubmit} />
}
