import React, { useRef, useState } from "react"
import IdleTimer from "react-idle-timer"
import { Modal } from "onsenui"
import { store } from "../psrs"

let countdownInterval
//let timeout

export const SessionTimeout = (props) => {


  const { xisAuthenticated, logOut } = props 
  //, timeoutModalOpen, setTimeoutModalOpen, setTimeoutCountdown, timeoutCountdown, handleLogout} = props
  let {timeout} = props 

  const {timeoutModalOpen, timeoutCountdown, countdownInterval, timeoutID } = props
  const {setTimeoutModalOpen, setTimeoutCountdown, setCountDownInterval, setTimeoutID} = props
  //if (!timeout) timeout = 3600000

  
  //const [timeoutModalOpen, setTimeoutModalOpen] = useState(false)
  //const [timeoutCountdown, setTimeoutCountdown] = useState(0)

  const idleTimer = useRef(null)


  const clearSessionTimeout = () => {
      //console.log('clearSessionTimeout')
    clearTimeout(timeoutID)
  }

  const clearSessionInterval = () => {
      //console.log('clearSession')
    clearInterval(countdownInterval)
  }

  const handleLogout = async (isTimedOut = false) => {
      //console.log('handleLogout')
    try {
        setTimeoutModalOpen(false)
        clearSessionInterval()
        clearSessionTimeout()

        //alert('session timeout  logout')
        console.log('session time - logout')
        logOut()
     } catch (err) {
        console.error(err)
      }
  }

  const handleContinue = () => {
      //console.log('handleContinue')
    setTimeoutModalOpen(false)
    clearSessionInterval()
    clearSessionTimeout()
  }

  const onActive = () => {
      //console.log('onActive')
    if (!timeoutModalOpen) {
      clearSessionInterval()
      clearSessionTimeout()
    }
  }

  const onIdle = () => {
      //console.log('onIdle')
    const delay = 1000 * 1

    let isAuthenticated = store.get("loginConfirmed")

    const currentPage = store.get("RouteStack")
   // if (currentPage && currentPage.some(i => i.view.includes('Login'))) isAuthenticated = false

   if (currentPage && currentPage.length && currentPage.length === 1 && currentPage[0].view === 'Login') isAuthenticated = false

    if (isAuthenticated && !timeoutModalOpen) {
      //timeout = 
       setTimeoutID(setTimeout(() => {
        let countDown = 45
        setTimeoutModalOpen(true)
        setTimeoutCountdown(countDown)
        //countdownInterval = 
        setCountDownInterval(setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown)
          } else {
            handleLogout(true)
          }
        }, 1000) )
      }, delay) )

      //setCountDownInterval(countdownInterval)
    }
  }

  //if (isAuthenticated && timeout) {
    return (
        <IdleTimer
          ref={idleTimer}
          onActive={onActive}
          onIdle={onIdle}
          debounce={250}
          //timeout={timeout}
          timeout={timeout ? timeout : 50400000 }
          //timeout={50400000}
          //timeout={30000}
        />
    )
  //} 
  //else return false
}

