import React, {useRef, useState, useEffect} from 'react'
import Div100vh from 'react-div-100vh'
import {AbilityContext} from './Can'
import ability from './ability'
import {Navigator, Modal} from 'react-onsenui'
import {Grommet} from 'grommet'
import {uid, store, Routes, currentUser, Logout, omit} from './psrs'
import {Route} from './Route'
import {theme} from './styles/theme'
import 'react-responsive-select/dist/react-responsive-select.css'
import _ from 'lodash'

import {SessionTimeout} from './components/SessionTimeout'
import { session } from 'store2'

const DEBUG=false

export const App = () => {
  const [user, setUser] = useState(currentUser)
  const storedStack = store.get('RouteStack')
  const loginConfirmed = store.get("loginConfirmed")

  const routeStack = storedStack ? _.uniqBy(storedStack, 'view') : [Routes.login]
  DEBUG && console.log('App routeStack', routeStack)
  store.set('RouteStack', routeStack)
  const navigatorProps = {
    initialRouteStack: routeStack || Routes.login,
    onPrePush: (event) => {
      const view = event.routes.pushingRoute.view
      store.set('View', view)
      if (!user) setUser(store.get('CurrentUser'))
    },
    onPostPush: (event) => {
      DEBUG && console.log('onPostPush', event.routes.routes)
      let unique = _.uniqBy(event.routes.routes, 'view')

      omit(unique, ['markup'])


      store.set('RouteStack', unique)
    },
    onPrePop: (event) => {
      DEBUG && console.log('onPrePop routes', event)
      const view = event.routes.poppingRoute.view
      const routes = event.routes.routes
      store.set('View', routes[routes.length - 2].view)
    },
    onPostPop: (event) => {
      DEBUG && console.log('onPostPop', event)
      const unique = _.uniqBy(event.routes.routes, 'view')
      store.set('RouteStack', unique)
    },
    renderPage: (route, navigator) => {
      const props = route.props || {}
      props.navigator = navigator
      props.route = route
      return <Route key={uid(8)} {...props} />
    },
    onDeviceBackButton: (e) => {
      DEBUG && console.log('onDeviceBackButton', e)
      return false
    },
  }

  const handleClick = () => {
    //Logout()
    store.set("RouteStack", [{ view: "Login" }]);
    //Logout()
    window.location.reload(false)
  }


  const REACT_VERSION = React.version;
  console.log('-- React Version --> ', REACT_VERSION)
   //const scrollInto = useRef(null)
  //useEffect(() =>{
  //  scrollInto.current.scrollIntoView()
  //},[]) 

  useEffect(() => {
    setUser(currentUser)
  }, [currentUser])


  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false)
  const [timeoutCountdown, setTimeoutCountdown] = useState(0)
  const [countdownInterval, setCountDownInterval] = useState(0)
  const [timeoutID, setTimeoutID] = useState(0)

  const clearSessionTimeout = () => {
    //console.log('clearSessionTimeout')
    clearTimeout(timeoutID)
  }
  const clearSessionInterval = () => {
      //console.log('clearSession')
    clearInterval(countdownInterval)
  }
  const handleLogout = async (isTimedOut = false) => {
    //console.log('handleLogout')
  try {
      setTimeoutModalOpen(false)
      clearSessionInterval()
      clearSessionTimeout()

      console.log('logOut....')
      Logout()
   } catch (err) {
      console.error(err)
    }
}

const handleContinue = () => {
    //console.log('handleContinue')
  setTimeoutModalOpen(false)
  clearSessionInterval()
  clearSessionTimeout()
}

  const x = session.get("sessionTimeOut")


  //START: online offline
  /* let [online, isOnline] = useState(navigator.onLine);

  const setOnline = () => {
    console.log('We are online!');
    isOnline(true);
  };
  const setOffline = () => {
    console.log('We are offline!');
    isOnline(false);
  };

  // Register the event listeners
  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    }
  }, []); */

  //END: online offline

  return (
    <Div100vh>
      {<SessionTimeout 
         isAuthenticated={loginConfirmed} 
         logOut={handleClick} 
         timeout={session.get('mobileSessionTimeOut')} 
         timeoutModalOpen={timeoutModalOpen}
         timeoutCountdown={timeoutCountdown}
         countdownInterval={countdownInterval}
         timeoutID={timeoutID}
         setTimeoutModalOpen={setTimeoutModalOpen}
         setTimeoutCountdown={setTimeoutCountdown}
         setCountDownInterval={setCountDownInterval}
         setTimeoutID={setTimeoutID}
        />}
       <Modal
        isOpen={timeoutModalOpen}
        >
          <p>
              The current session is about to expire in{" "}
              <span>{timeoutCountdown}</span> seconds.
          </p>
          <button onClick={() => {
            handleContinue()
          }} > 
          Stay
          </button>
          <button onClick={() => {
            handleLogout()
          }}> Logout </button>

      </Modal>
      <Grommet theme={theme}>
        <AbilityContext.Provider value={ability(user)}>
          <Navigator {...navigatorProps} />
        </AbilityContext.Provider>
      </Grommet>
    </Div100vh>
  )
}

export default App
