import React from "react"
import { Select } from "react-onsenui"
import { store } from "../psrs"

export const ScheduleSelect = (props) => {
  const {shift, setShift} = props

  const Tags = store.get('tags')

  const renderOptions = () => {
    return Tags.map(item => (
      <option key={`schedule${item.id}`} value={item.id}>{item.name}</option>
    ))
  }

  const selectOptions = {
    modifier: null,
    disabled: false,
    value: shift.tags.map(r => r.id).join(' '),
    onChange: tagIds => {
      console.log('tag change', tagIds)
      // setShift({...shift, tagIds})
    },
    multiple: false,
    autofocus: false,
    required: false,
    size: "1"
  }

  return <Select {...selectOptions}>{renderOptions()}</Select>
}

