import React, {useState, useRef, useEffect} from 'react'
import {Select, CaretIcon, ModalCloseButton} from 'react-responsive-select'
import MobileSelect from "mobile-select";
import {Box, Button} from 'grommet'
import _ from 'lodash'

import {store, truncString} from '../psrs'

// for default styles...
import 'react-responsive-select/dist/react-responsive-select.css'
import { Close } from 'grommet-icons';


const DEBUG = false

export const SelectReason = (props) => {
  const {shift, update, setToast} = props
  const Records = store.get('Reasons')
  const options = Records.map((r) => ({value: r.id, text: r.description}))
  
  let wheelData =_.sortBy(Records.map((r) => ({sort: r.description, id: r.id, value: r.description, category: r.category, code: r.payTypeCode})), ['sort'])
  wheelData = wheelData.filter(w => w.category.toUpperCase() === 'OVERTIME')
  wheelData.unshift({id: 'clear', value: 'CLEAR ENTRY'})
  
  wheelData = wheelData.filter((v,i,a)=>a.findIndex(v2=>['value','code','category'].every(k=>v2[k] ===v[k]))===i)
  //wheelData = wheelData.filter((v,i,a)=>a.findIndex(v2=>(v2.sort===v.sort))===i)
  //wheelData = wheelData.filter((v,i,a)=>a.findLastIndex(v2=>(v2.sort === v.sort))===i)


  const disabled = props.disabled
  //const disabled = props.disabled || (shift.shiftTemplateId !== null && !shift.shiftTemplate.canEdit)
  options.unshift({value: 'cancel', text: 'CANCEL'})

  const [selected, setSelected] = useState(shift.reasonId || undefined)

  const xhandleChange = ({name, text, value, altered}) => {
    if (value === 'cancel') {
      setSelected(shift.reasonId || undefined)
    } else if (value !== 'cancel' && !!altered) {
      setSelected(value)
      update({field: 'reasonId', id: shift.id, value})
    }
  }

  const handleClear = () => {
    const clearData = [{
      id: 'clear',
      value: 'CLEAR ENTRY'
    }]

    setSelectedVal(clearData[0]?.value)
    handleChange(clearData)
    msInstance?.locatePosition(0,wheelData.findIndex( i => i.id === shift.reasonId))
    
  }
  
  const handleChange = (data) => {  

    const name = ''
    const text = ''
    const value = data[0]?.id
    const altered = true


    if (value === 'cancel') {
      setSelected(shift.assignmentId || undefined)
    } else if (value === 'clear') {
      setSelected(undefined)
      update({field: 'reasonId', id: shift.id, value: null})
      setSelectedVal('Please select')
      let x = msInstance?.getValue()
      msInstance?.locatePosition(0,-1)
      //msInstance?.updateWheel(0,wheelData)	
      msInstance?.updateWheels(wheelData)	
      x = msInstance?.getValue()

      console.log('xx')
    } else if (value !== 'cancel' && value !== 'clear' && !!altered) {
      setSelected(value)
      update({field: 'reasonId', id: shift.id, value})
    }

    if (setToast) {
      setToast({open: true, text: 'Reason Code updated'})
      setTimeout(() => setToast({open: false, text: ''}), 2000)

    }
  }
  const handleSubmit = (value) => {
    console.log('SELECT onSubmit', value)
  }


  /** test */
  const tirggerRef = useRef(null);

  //const initval = []
  const wheelInit = wheelData.filter( i =>  i.id === shift.reasonId)
  
  //initval.push(tePT[0].value)

  //DEBUG && console.log('tePT => ', tePT[0])
  DEBUG && console.log('shift.reasonId => ', shift.reasonId)
  DEBUG && console.log('options => ', options)

  const [selectedVal, setSelectedVal] = useState(wheelInit[0]?.value || 'Please select' )
  
  let msInstance = null;
  DEBUG && console.log('selectedVal => ', selectedVal)

  useEffect(() => {
    if(!msInstance){
      msInstance = new MobileSelect({
          wheels: [
            { 
              data: wheelData
            }, 
            
        ],
          title:  "Overtime Reason",
          //position: [10],
          textColor: "#000000",
          bgColor: "#FFFFFF",
          ensureBtnText: "Select",
          cancelBtnText: "Cancel",
          trigger: tirggerRef.current,
          triggerDisplayValue: false, // If you don't want to overwrite the HTML inside the trigger, you need to set this to false
          onChange: (data) => {
            DEBUG && console.log(data);
            setSelectedVal(data[0]?.value)
            handleChange(data)

          },
        });

        const ii = wheelData.findIndex( i => i.id === shift.reasonId)
        msInstance?.locatePosition(0,wheelData.findIndex( i => i.id === shift.reasonId))
    }
    
    return () => {
      msInstance?.destroy();  // Destroying instance
    };
  }, []);


  const boxStyle = {
    color: disabled ?'grey':'black',
    background: disabled ? "#F5F5F5" : "white",
    pointerEvents: disabled ? "none" : "",
  }

  const buttonInStyle =  {
    //width: '400px',
    width: '100%',
    position: 'relative',
    //outline: '1px solid red',
  }

  const inputStyle = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    outline: 'none',
    height: '30px',
    borderRadius: '5px',
  }

  const clearBtnStyle =  {
    position: 'absolute',
    top: 0,
    borderRadius: '5px',
    right: '0px',
    zIndex: 2,
    border: 'none',
    top: '0px',
    height: '30px',
    cursor: 'pointer',
    //color: disabled ? 'grey' : 'black',
    //background: disabled ? '#f5f5f5': 'white',
    color: disabled ? 'grey' : 'white',
    backgroundColor: disabled ? '#d1d1d1': '#0393c6',
    transform: 'translateX(2px)',
}


  return (
    <Box pad='small'>
      {/*<div className="ms-default-trigger" ref={ tirggerRef }>*/}
      <Box  
         //border={{size: ''}}
         style={ boxStyle } 
         //ref={ tirggerRef }
         direction='row' 
         fill={true} pad="small" 
         //background = {{color: "white"}}   
      >
        <div style={buttonInStyle }>
          <div
              style={inputStyle} type="text" id="enter"  
              ref={ tirggerRef }
          >
            { selectedVal === 'CLEAR ENTRY'? 'Please select' : truncString(selectedVal, 50) || 'Please select' }
          </div>
          <Button
            plain
            icon={<Close />}
            //label="Clear"
            alignSelf='center'
            focusIndicator={true}
            //hoverIndicator="background"
            primary={true}
            size="small"
            tip="Clear Reason"
            a11yTitle="Clear Reason"
            //x 
              style={clearBtnStyle} 
              id="clear"
              onClick={handleClear}
          /> 
          {/* <button
              style={clearBtnStyle} 
              id="clear"
              onClick={handleClear}
          >x</button> */}
            
              
        </div>
      </Box>
    </Box>
  );
 
   /* end */

  return <Select disabled={disabled} name='reasonId' prefix='Overtime Reason: ' noSelectionLabel='Please select' modalCloseButton={<ModalCloseButton />} options={options} caretIcon={<CaretIcon />} selectedValue={selected} onChange={handleChange} onSubmit={handleSubmit} />
}
