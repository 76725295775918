import React from 'react'
import {subject as A} from '@casl/ability'
import storage from 'store2'
import _ from 'lodash'
import uuid from 'uid'
import auth0 from 'auth0-js'
import TimeFormat from 'hh-mm-ss'


const DEBUG = false
const DDEBUG = false

window.store = storage
//const CLIENTID = 'f8BmF8ipublfwBxx2fGH6N6jj2G7J1tD'
//const DOMAIN = 'dev-4zomju7t.auth0.com'

//?? need to handle this
//const CLIENTID = 'f1PUdzz39mL8OTL6VhtZPAjIjRFIS24qi'
//const DOMAIN = 'opd-pd.us.auth0.com'

//const webAuth = new auth0.WebAuth({
//  domain: DOMAIN,
//  clientID: CLIENTID,
//})


export {Can} from './Can'
export const a = A
export const an = A
export const This = A
export const store = window.store
export const session = store.session
export const uid = uuid
export const AppDataKeys = ['Assignments', 'BusinessUnits', 'Employees', 'Gizmos', 'PayPeriod', 'PayPeriods', 'PayPeriodToday', 'PayPeriodNext', 'PayPeriodPrev', 'PayTypes', 'Reasons', 'Specialties', 'SubLedgers', 'Supervisors', 'Tags']
export const ObjectKeys = ['Auth0', 'CurrentUser', 'PayPeriod', 'PayPeriodToday', 'PayPeriodNext', 'PayPeriodPrev']
export const SessionKeys = ['CurrentUser', 'role']
export const StringKeys = ['api', 'role', 'TOKEN']
export const storageKeys = [...AppDataKeys, ...ObjectKeys, ...StringKeys]
export const currentUser =  store.get('CurrentUser')
//export const getCurrentUser = () => {
//  return store.get('CurrentUser')
//}

export const reqOptions = {
  headers: {'Content-Type': 'application/json'},
  credentials: 'include',
  method: 'GET',
  mode: 'cors',
}
export const Routes = {
  login: {view: 'Login', title: 'Logout', key: uid(8)},
  shift: {
    view: 'Shift',
    key: uid(8),
    data: store.get('Shifts', '914vfJJdeWo'),
  },
  shifts: {view: 'Shifts', title: 'Shifts', key: uid(8)},
  employees: {view: 'Employees', title: 'Employees', key: uid(8)},
  icons: {view: 'Icons', title: 'Icons', key: uid(8)},
  timeEntry: {view: 'TimeEntry', title: 'Time Entry', key: uid(8)},
}
export const Host = (() => {
  const LOCATIONS = {
    DEVELOPMENT: '===========================================',
    'localhost:5000': 'https://localhost:5001',
    '127.0.0.1:5000': 'https://localhost:5001',
    '0.0.0.0:5000': 'https://localhost:5001',
     //'localhost:5001': 'https://localhost:5001',
     'ltsprod-tunnel.takingnames.live': 'https://lts2-client.takingnames.live',
     'myclient.crabdance.com': 'https://mylocalapi.crabdance.com',
     'localdvclient.takingnames.live': 'https://localdvapi.takingnames.live',
     'orchestrator.letz.dev': 'https://jasdv.letz.dev',
     'barrys-mbp-3.localhost:5000': 'https://barrys-mbp-3.localhost:5001',
     'spmobile.enterprisetechnologies.com': 'https://spapi.enterprisetechnologies.com',
     //'spmobile.enterprisetechnologies.com': 'https://localhost:5001',
     'localhost:5002': 'https://localhost:5001',
     "mobiledv.schedulerpro.local": "https://opd-dv.schedulerpro.local",

    'AZURE PRODUCTION': '======================================',
    'opd.enterprisetechnologies.com': 'https://opd-pd.enterprisetechnologies.com',
    'mobile.opd.enterprisetechnologies.com': 'https://opd-pd.enterprisetechnologies.com',

    'AZURE QA': '==============================================',
    'opd1.z22.web.core.windows.net': 'https://opd-qa.enterprisetechnologies.com',
    'qa.opd.enterprisetechnologies.com': 'https://opd-qa.enterprisetechnologies.com',
    'qa.mobile.opd.enterprisetechnologies.com': 'https://opd-qa.enterprisetechnologies.com',
  }
  const APIHOST = LOCATIONS[window.location.host]
  store.set('api', APIHOST)
  DEBUG && console.log('HOST', APIHOST)
  return APIHOST
})()
export const isLegit = (x) => {
  if (!x) return false
  if (_.isArray(x)) return !!x.length
  if (_.isObject(x)) return !!_.keys(x).length
  if (_.isString(x)) return !!x.length
}
export const ValidAppData = () => {
  const Store = store.getAll()
  for (let key of AppDataKeys) {
    if (!isLegit(Store[key])) {
      DEBUG && console.log('NOT LEGIT STORE', key)
      return false
    }
  }
  return true
}
export const ValidSession = () => {
  const Session = session.getAll()
  for (let key of SessionKeys) {
    if (!isLegit(Session[key])) {
      console.log('NOT LEGIT SESSION', key)
      return false
    }
  }
  return true
}
export const ValidAppState = () => {
  return !!ValidSession() && !!ValidAppData()
}


export const fetchX = async () => {

  const SHARED_DATA_ENDPOINT = '/token'

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
  }

  await window.fetch(SHARED_DATA_ENDPOINT, { method: "POST", body: JSON.stringify({ token: 'sampletoken' })}).then(() => {
    console.log('saved to cache')
  });

}
export const fetch = async (path, options) => {
  const params = {...reqOptions, ...options}
  const url = `${Host}${path}`
  DEBUG && console.log('fetch url', url)
  DEBUG && console.log('fetch params', params)
  const fetched = await window.fetch(url, params).catch((err) => {
    DEBUG && console.log('FETCH error', err)
  })
  DEBUG && console.log('FETCH raw', fetched)
  if (fetched && fetched.status === 401) {
    // Logout()
    DEBUG && console.log('ERROR 401')
    return {error: 'error 401'}
  }

  const res = fetched ? await fetched?.json() : {}
  DEBUG && console.log('JSON', res)
  return res
}
export const fetchMyShifts = async (employeeNumber) => {
  const payPeriodId = store.get('PayPeriod').id
  const shifts = await fetch(`/api/employee/${employeeNumber}/shifts/${payPeriodId}`)
  DEBUG && console.log('fetchMyShifts', shifts)
  store.set('Shifts', shifts)
  return shifts
}
export const createSession = async (id_token) => {
  DDEBUG && console.log('CREATE SESSION')
  const URL = `/api/session`
  const params = {...reqOptions, method: 'POST', body: JSON.stringify({token: id_token})}
  DDEBUG && console.log('CREATE SESSION FROM id_token', URL, params)
  const data = await fetch(URL, params)
  DDEBUG && console.log('SESSION DATA', data)

  // check if mobile flag is true for user
  if (data && data.session && data.session.CurrentUser) {
    if (!data.session.CurrentUser.settings.mobileEnabled) {
      store.clearAll()
      store.set("mobileEnabled", false)
      return true
    }
  } else {
    return false
  }
  


  /* await fetchX().then(() => {
    console.log('saved to cache')
  }); */


  const storeData = data.store
  const sessionData = data.session
  // DEBUG && console.log('SESSION DATA', sessionData)
  const allData = {...storeData, ...sessionData}
  DDEBUG && console.log('ALL SESSION DATA', allData)
  //-yes not? store.clearAll()
  store.setAll({...allData})
  session.setAll({...sessionData})
  store.set("mobileEnabled", true)
  // session.setAll({...sessionData})
  // store.set('CurrentUser', sessionData.CurrentUser)
  const CurrentPeriod = store.get('PayPeriodToday')
  const {PayPeriods} = storeData
  
  store.set('PayPeriod', CurrentPeriod)
  store.set(
    'PayPeriodNext',
    _.find(PayPeriods, (p) => p.id === CurrentPeriod.nextId),
  )
  store.set(
    'PayPeriodPrev',
    _.find(PayPeriods, (p) => p.id === CurrentPeriod.prevId),
  )
  DEBUG && console.log('ValidAppState', ValidAppState())
  if (ValidAppState()) {
    // =====================================
    // HANDLE THE REDIRECT
    // =====================================
    return true
  }
}
export const Login = async (email, password) => {
  
  DEBUG && console.log('PASSWORD LOGIN')
  const options = {...reqOptions, method: 'POST', body: JSON.stringify({email, password})}
  const res = await fetch(`/api/auth0/get_token`, options)
  DEBUG && console.log('Login id_token', res.id_token)
  if (res.id_token !== 'Wrong email or password.') {
    const session = await createSession(res.id_token)
    return session
  } else {
    return false
  }
  
}
export const RequestCode = async (phone) => {
  DEBUG && console.log('REQUEST CODE', phone)
  const res = await fetch(`/api/auth0/get_code/${phone}`)
  return res
}
export const CodeLogin = async (phone, code) => {
  DEBUG && console.log('CODE LOGIN')
  const options = {...reqOptions, method: 'POST', body: JSON.stringify({phone, code})}
  const res = await fetch(`/api/auth0/verify_code`, options)
  DEBUG && console.log('CODE LOGIN RES', res)
  if (res.id_token) {
    const session = await createSession(res.id_token)
    return session
  } else {
    return false
  }
  
}
export const Logout = async () => {
  //store.clearAll()

  session.set("loginConfirmed", false)
  store.set("loginConfirmed", false)

  store.set("RouteStack", [{ view: "Login" }]);
  window.location.reload(false)

  //session.clearAll()
}

// Variation on omit to recurse through objects and arrays
export const omit = (obj, arr) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (arr.includes(key)) {
        delete obj[key];
      } else if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          omit(obj[key][i], arr);
        }
      } else if (typeof obj[key] === 'object') {
        omit(obj[key], arr);
      }
    }
  }
  return obj;
}

export const truncString = (str, n) => {
  return str.substr(0,n-1)+(str.length>n?'...':'');
}

export const convertDuration = (hours, minutes) => {
  const hm = `${hours}:${minutes}`
  const secs = TimeFormat.toS(hm, 'hh:mm')
  const durationHours = secs / 60 / 60
  DEBUG && console.log('updateDuration', durationHours)
  return durationHours.toPrecision(4)
}

export const ConvertHMToDuration = (hours, minutes) => {
  const hm = `${hours}:${minutes}`
  const secs = TimeFormat.toS(hm, 'hh:mm')
  const durationHours = secs / 60 / 60
  DEBUG && console.log('updateDuration', durationHours)
  return durationHours.toPrecision(4)
}

export const ConvertDurationToHM = (duration) => {

  // back to time
  const seconds = parseFloat(duration * 3600)
  const hm = TimeFormat.fromS(seconds, 'hh:mm')
  DEBUG && console.log('Duration to HM', hm)
  const returnHM = `${hm.split(':')[0]}:${hm.split(':')[1]}`
  return returnHM
}
