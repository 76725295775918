import React from "react";
import { Select } from "react-onsenui";
import { store } from "../psrs";
// import {Tag} from "./Tag"

export const PayTypeSelect = (props) => {
  const { payType, setOverlayed } = props;

  const PayTypes = store.get("PayTypes");

  const renderValueOptions = () => {
    return PayTypes.map((item) => (
      <option key={`value${item.id}`} value={item.code}>
        {item.code}
      </option>
    ));
  };

  const renderLabelOptions = () => {
    return PayTypes.map((item) => (
      <option key={`label${item.id}`} value={item.code}>
        {item.description}
      </option>
    ));
  };

  const selectOptions = {
    replace: false,
    modifier: null,
    disabled: false,
    value: payType.code,
    onChange: (payTypeCode) => {
      console.log("Make an updater for payType values", payTypeCode);
    },
    onClose: () => {
      setOverlayed(false);
    },
    multiple: false,
    autofocus: false,
    required: false,
  };

  return (
    <div>
      <Select {...selectOptions}>{renderValueOptions()}</Select>
      <Select {...selectOptions}>{renderLabelOptions()}</Select>
    </div>
  );
};
