import React, { useState, useEffect } from "react";
import DatePicker from "react-mobile-datepicker";
import { Icon } from "react-onsenui";
import { Box, Text } from "grommet";
import moment from "moment";
// import "moment-round";

export const TimePicker = (props) => {
  const { open, shift, field, closePickerAndSave, closePicker } = props;
  const mo = moment(shift[field]);
  const [isOpen, setIsOpen] = useState(open);
  const [value, setValue] = useState(mo.toDate());
  const [disabled, setDisabled] = useState(false)
  const dateConfig = {
    hour: { format: "hh", caption: "Hour", step: 1 },
    minute: { format: "mm", caption: "Min", step: 1 },
  };

  const btn = {
    fill: false,
    pad: "small",
    background: "backgroundBack",
  };

  const Confirm = () => {
    return <Box {...btn}>Save</Box>;
  };

  const Cancel = () => {
    return Cancel;
  };

  const Header = () => `${field} Time`;

  const picker = {
    isOpen,
    value,
    theme: "ios",
    dateConfig,
    headerFormat: "YYYY/MM/DD",
    showHeader: true,
    showFooter: true,
    customHeader: <Header />,
    confirmText: "Save",
    cancelText: "Cancel",
    onCancel: () => closePicker(),
    onSelect: (sel) => closePickerAndSave(moment(sel), field, shift.id),
  };

  useEffect(() => {
    console.log("TimePicker value", value);
  }, [value]);

  return <DatePicker {...picker} />;
};
